// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.canjes {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 2%;
  margin-bottom: 2%;
  font-size: 0.8rem;
}

.divider {
  height: 1px;
  width: 100%;
}

span {
  margin-left: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h6,
h4,
h5,
p,
span {
  margin-bottom: 5px;
}

.title-item {
  display: flex;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/pointz/canjes/canjes.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,8BAAA;EACA,4CAAA;EACA,kBAAA;EACA,iBAAA;EACA,iBAAA;AACF;;AACA;EACE,WAAA;EACA,WAAA;AAEF;;AAAA;EACE,iBAAA;AAGF;;AAAA;;;;;;EAME,SAAA;EACA,UAAA;AAGF;;AADA;;;;;EAKE,kBAAA;AAIF;;AADA;EACE,aAAA;EAEA,mBAAA;AAGF","sourcesContent":[".canjes {\n  display: flex;\n  justify-content: space-between;\n  border-bottom: 1px solid rgba(0, 0, 0, 0.12);\n  padding-bottom: 2%;\n  margin-bottom: 2%;\n  font-size: 0.8rem;\n}\n.divider {\n  height: 1px;\n  width: 100%;\n}\nspan {\n  margin-left: 10px;\n}\n\nh1,\nh2,\nh3,\nh4,\nh5,\nh6 {\n  margin: 0;\n  padding: 0;\n}\nh6,\nh4,\nh5,\np,\nspan {\n  margin-bottom: 5px;\n}\n\n.title-item {\n  display: flex;\n  //justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
