import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppUsersService } from '../../../users-api';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-reset-user-dialog',
  templateUrl: './reset-user-dialog.component.html',
  styleUrls: ['./reset-user-dialog.component.scss']
})
export class ResetUserDialogComponent {
  resetForm: FormGroup;
  passwordVisible: boolean = false;
  isLoading: boolean = false;

  constructor(
    private fb: FormBuilder,
    private usersApi: AppUsersService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ResetUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.resetForm = this.fb.group({
      newRegisterPassword: [''],
      erasePersonalData: [false]
    });
  }

  onConfirm(): void {
    this.isLoading = true;
    
    const { newRegisterPassword, erasePersonalData } = this.resetForm.value;
    const payload: any = { erasePersonalData };

    if (newRegisterPassword) {
      payload.newRegisterPassword = newRegisterPassword;
    }

    this.usersApi.resetUser(this.data.user.id, payload).subscribe(
      () => {
        this.isLoading = false;
        this.snackBar.open('Usuario reseteado exitosamente.', 'OK', { duration: 3000 });
        this.dialogRef.close();
      },
      (error) => {
        this.isLoading = false;
        const errorMessage = error?.error?.message || 'Error al resetear usuario.';
        console.error('Error al resetear usuario:', errorMessage);
        this.snackBar.open(errorMessage, 'OK', { duration: 3000 });
      }
    );
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  togglePasswordVisibility() {
    this.passwordVisible = !this.passwordVisible;
  }
}
