import { PERMISSIONS, PermissionsService } from './../../../../services/permissions/permissions.service';
import { Component, OnInit } from '@angular/core';
import {
  AccountTypesList,
  AccountTypeOutput,
} from '../../../../models/points/models';
import { MatTableDataSource } from '@angular/material/table';
import { PointsService } from '../../../../services/points.service';
import { AppsUtilService } from '../../../../services/apps-util.service';
@Component({
  selector: 'app-type-accounts',
  templateUrl: './type-accounts.component.html',
  styleUrls: ['./type-accounts.component.scss'],
})
export class TypeAccountsComponent implements OnInit {
  PERMISSIONS = PERMISSIONS;
  accounts: AccountTypeOutput[] = [];
  loader = false;
  dataSource: MatTableDataSource<AccountTypeOutput>;
  displayedColumns = ['Id', 'name', 'pointName', 'edit'];
  constructor(
    private pointsService: PointsService,
    private permissionsService : PermissionsService,
  ) {}
  async ngOnInit() {
    this.loader = true;
    this.getTypeAccounts();
  }
   getTypeAccounts() {
    this.pointsService.listAccountTypesByAppId().subscribe(async(res: AccountTypesList) => {
      /* let data = [] 
       if ((await this.permissionsService.getPermissions()).includes(PERMISSIONS.ACCESS_ALL)){
        this.accounts.push(...res.data);
      } else {
       // data = res.data.filter((item) => item. !== 1);
        this.accounts.push(...data);

      }
 */
      this.accounts.push(...res.data);
      this.dataSource = new MatTableDataSource(this.accounts);
      this.loader = false;
    });
  }

  searchAccount(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLocaleLowerCase();
  }
}
