import { StorageService } from './../../../../services/storage.service';
import { AppOutput } from './../../../../users-api/model/appOutput';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ITablesExhibitions } from '../tables-exhibitions.model';
import { ActivatedRoute, Router } from '@angular/router';
import { TablesExhibitionsService } from '../../services/tables-exhibitions.service';
import { HttpErrorResponse } from '@angular/common/http';
import Swal from 'sweetalert2'

const ErrorResponseTextOrders  = (codes?) =>  {
  const codeAsComplement = (code) => {
    const complements =  {
      CAN_NOT_READ_FILE : 'reader_sa',
      COLUMNS_FORMAT_ERROR : 'format_errors'
    }
    const include = Object.keys(complements).includes(code);
    const info = codes[complements[code]];
    
    if(include){
      const type = typeof info;
      if (type === 'string')  return info;
      if (Array.isArray(info)) {
        let text = ''
        for (let index = 0; index < info.length; index++) {
          const element = info[index];
          text = index == 0 ? `・ ${element}` : ` ${text} ・ ${element}`
        }
        return text
      }

    }
    return ''

  }
  
  return {
  // 400: {
    CAN_NOT_READ_FILE : `Este archivo no es accesible por favor dar permisos a la cuenta ${codeAsComplement(codes?.code)}`,
    UNAUTHORIZED : 'Usuario no autorizado',
    EMPTY_FILE_SHEET : 'La hoja está vacía',
    WRONG_NAME_FORMAT : `El id de la tabla no cumple con el formato `,
    COLUMNS_FORMAT_ERROR : `Error en nombre de columnas: ${codeAsComplement(codes?.code)}`,
    WRONG_TYPE : `El archivo no es un documento de google`,
    DATA_MISSING : `Falta información de la tabla`,
  // }
}
  
}

@Component({
  selector: 'app-form-tables-exhibitions',
  templateUrl: './form-tables-exhibitions.component.html',
  styleUrl: './form-tables-exhibitions.component.scss'
})
export class FormTablesExhibitionsComponent implements OnInit {
  mode: string = "create";
  TableForm : FormGroup;
  currentAppId;
  allowedAppIds: string[];
  allowAllAppIds = false;
  applicationsOptions: string[] = [];
  filteredAppsOptions: string[];
  objectsAppsData: AppOutput[];
  appsNameMap: Map<string, AppOutput> = new Map();
  appsIdMap: Map<string, AppOutput> = new Map();
  appId = "";
  currentId
  data
  constructor(private formBuilder: FormBuilder,private route: ActivatedRoute, private tablesService :TablesExhibitionsService, private storageService: StorageService, private router : Router){
    this.createForm();
  }
  ngOnInit(): void {
    this.initAllowedIds()
    const id = this.route.snapshot.paramMap.get('id');
    this.currentId = id;
    if (id) {
      this.mode = 'edit';
      this.TableForm.controls.id.setValue(id);
      this.TableForm.controls.id.disable();
      this.appId = this.route.snapshot.paramMap.get('appId');
      this.TableForm.controls.appNameInputControl.disable();

      this.getTable(id);
    }
  }

  initAllowedIds() {
    // Do not allow any app by default
    this.allowedAppIds = [];
    this.allowAllAppIds = false;
    const account = this.storageService.get('account');
    if (!account) {
      return;
    }
    if (account.roles.includes('super_admin')) {
      // Allow all ids
      this.allowedAppIds = null;
      this.allowAllAppIds = true;
      return;
    }
    const profile = this.storageService.get('profile');
    if (!profile) {
      return;
    }
    this.allowedAppIds = profile.authorized_apps || [];
  }

  createForm() {
    this.TableForm = this.formBuilder.group({
      id: ["",[Validators.required, Validators.pattern("^\[a-z][a-z0-9_]*[a-z0-9]$")],],
      sheet: ["", [Validators.required]],
      appNameInputControl: ["", [Validators.required]],
      url: ["", [Validators.required]],
    });
  }

  async getTable(id){
    try {
      const data:any =  await this.tablesService.getTable(this.appId,id).toPromise()
      this.data = data;
      this.mode = 'edit';
      this.TableForm.controls.sheet.setValue(data.sheet);
      this.TableForm.controls.url.setValue(data.url);
      this.TableForm.controls.appNameInputControl.setValue(this.appId);
      this.currentAppId = this.appId;
    } catch (error) {
      this.HandlerError(error)
    }
  }

  goToList(){
    
    this.router.navigate(['pages', 'exhibition','tables']);
  }

  async OKClicked() {
    if (this.TableForm.valid) {
      const dataForm:ITablesExhibitions = {...this.TableForm.value, id:this.currentId, app_id:this.currentAppId};
      try {
        if (this.mode == 'edit') {
          const update = await this.tablesService.putTable(dataForm).toPromise();
          if (update) this.goToList();;
          
        }
        else if (this.mode == 'create') { 
          const create = await this.tablesService.putTable({...this.TableForm.value,app_id:this.currentAppId}).toPromise();
          if (create) this.goToList();;
        }
        const text = this.mode == 'create' ? 'Creado correctamente' : 'Editado Correctamente'
        Swal.fire('', text, 'success');
       
        
      } catch (error) {
        if (error instanceof HttpErrorResponse) {
          this.HandlerError(error)
        }
      }
    }
  }

  onAppSelected(appId: string) {
    this.currentAppId = appId
    this.TableForm.controls.appNameInputControl.setValue(appId)
  }

  HandlerError(error:HttpErrorResponse){
    if (error instanceof HttpErrorResponse) {
     // if (Object.keys(ErrorResponseTextOrders()).includes(error.status.toString())) {
        if (error.error?.code) {
          const IncludeText = ErrorResponseTextOrders(error.error)[error.error.code];// [error.status]
            const text = IncludeText  ? IncludeText : `Error ${error.error.code}`
           Swal.fire('Error', text || error.error.message, 'error');
          
        } else {
          console.error('Error en la solicitud code: ', error.status.toString(), 'error : ', error.error)
        }

     // }
    }
  
  }

}
