import { AutoIdService } from './../../../../auto-id.service';
import { ranking, inputs_config } from './../indicator.model';
import { StorageService } from './../../../../services/storage.service';
import { category_indicator } from './../../categories/categories.model';
import { CategoriesService } from './../../categories.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { IndicatorsService } from '../../indicators.service';
import { AppOutput } from '../../../../users-api';
import { indicator } from '../indicator.model';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';

enum typesIndicators {
  value,
  value_percentage,
  accumulated,
  accumulated_percentage
}

interface opcionTypesI {
  label: string;
  value: typesIndicators;
  enable: boolean;
  lenght: number;
  automatic: boolean;
}
@Component({
  selector: 'app-indicator-add',
  templateUrl: './indicator-add.component.html',
  styleUrls: ['./indicator-add.component.scss']
})
export class IndicatorAddComponent implements OnInit {
  mode: 'create' | 'edit' = 'create';
  name = 'Nuevo Indicador';

  indicatorForm: FormGroup;

  applicationsOptions: string[] = [];
  filteredAppsOptions: string[];
  objectsAppsData: AppOutput[];
  appsNameMap: Map<string, AppOutput> = new Map();
  appsIdMap: Map<string, AppOutput> = new Map();
  appId = '';
  dataCKDitor = '';
  data: indicator;
  editedIcon = false;
  options_types = typesIndicators;
  loading = false;
  currentAppId: string;
  allowedAppIds: string[];
  allowAllAppIds = false;
  isSending = false;
  categories: category_indicator[];
  categorySelected: category_indicator;

  inputs = [];
  labels = [];

  value_options_types: opcionTypesI[] = [
    { label: 'Valor', value: typesIndicators.value, enable: true, lenght: 10, automatic: false },
    { label: 'Valor porcentual', value: typesIndicators.value_percentage, enable: true, lenght: 10, automatic: true },
    { label: 'Acumulado', value: typesIndicators.accumulated, enable: true, lenght: 10, automatic: false },
    { label: 'Acumulado Porcentual', value: typesIndicators.accumulated_percentage, enable: true, lenght: 10, automatic: false },
  ];

  histogram_options = [
    { label: 'Valor', value: 'value'},
    { label: 'Valor porcentual', value: 'value_percentage'},
    { label: 'Acumulado', value: 'accumulated'},
    { label: 'Acumulado Porcentual', value: 'accumulated_percentage'},
    { label: 'Desactivado - No Mostrar', value: 'off' },
  ];

  isIdValid = true;
  idValidated = false;
  loadingValidateId = false;

  nameChanges$: Subscription;
  constructor(

    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private indicatorsService: IndicatorsService,
    private router: Router,
    private route: ActivatedRoute,
    private categoriesService: CategoriesService,
    private autoId: AutoIdService
  ) {
    this.initConstructor();
  }

  async ngOnInit() {
    await this.initAllowedIds();
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.mode = 'edit';
      this.name = 'Editar Indicador';
      this.getIndicator(id);
      this.indicatorForm.controls.appNameInputControl.disable();
     // this.indicatorForm.controls.category_id.disable();
      this.idValidated = true;
      if(this.nameChanges$){
        this.nameChanges$.unsubscribe();
      }
    }
    this.indicatorForm.controls.appNameInputControl.valueChanges
      .subscribe((val: string) => {
        if (val) {
          this.indicatorForm.controls.category_id.reset();
          this.getCategories(val);
        }

        this.filteredAppsOptions = val
          ? this.filtersAutoComplete(val, 'applicationsOptions')
          : this.applicationsOptions.slice();
      });

  }
  initConstructor() {
    const dataRanking = {};
    this.value_options_types.forEach((value, index) => {

      dataRanking['ranking_' + typesIndicators[value.value] + '_enable'] = [''];
      dataRanking['ranking_' + typesIndicators[value.value] + '_lenght'] = [''];
      dataRanking['ranking_' + typesIndicators[value.value] + '_automatic'] = [''];


    });

    this.indicatorForm = this.formBuilder.group({
      ...dataRanking,
      name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
        ]),
      ],
      id: [
        { value: '', disabled: true },
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
        ]),
      ],
      appNameInputControl: [
        '',
        Validators.compose([
          Validators.required,
          Validators.maxLength(140)
        ]),
      ],
      appNameInputEditor: ['', Validators.required],
      category_id: ['', Validators.required],
      value_label: ['', Validators.required],
      accumulated_label: ['', Validators.required],
      main_value: ['', Validators.required],
      histogram_value: ['', Validators.required],
      enable: [true, Validators.required],
    });

    this.nameChanges$ = this.indicatorForm.controls.name.valueChanges.subscribe((value) => {
      this.indicatorForm.controls.id.setValue(this.autoId.buildAutoId(value));
    });

    this.value_options_types.forEach((value, index) => {
      this.indicatorForm.get('ranking_' + typesIndicators[value.value] + '_enable').valueChanges.subscribe((data) => {
        if (data) {
          this.indicatorForm.controls['ranking_' + typesIndicators[value.value] + '_lenght'].setValidators(Validators.required);
          this.indicatorForm.controls['ranking_' + typesIndicators[value.value] + '_lenght'].markAllAsTouched();
          this.indicatorForm.controls['ranking_' + typesIndicators[value.value] + '_lenght'].updateValueAndValidity();

        } else {
          this.indicatorForm.controls['ranking_' + typesIndicators[value.value] + '_lenght'].clearValidators();
          this.indicatorForm.controls['ranking_' + typesIndicators[value.value] + '_lenght'].updateValueAndValidity();
        }
      });
    });

  }

  getIndicator(id) {
    this.indicatorsService.getIndicator(id).subscribe((data: indicator) => {
      this.data = data;
      this.mode = 'edit';
      this.indicatorForm.controls.name.setValue(data.name);
      this.indicatorForm.controls.id.setValue(data.id);
      this.indicatorForm.controls.histogram_value.setValue(data.histogram_value);
      this.indicatorForm.controls.accumulated_label.setValue(data.accumulated_label);
      this.indicatorForm.controls.enable.setValue(data.visibility);
      this.indicatorForm.controls.main_value.setValue(typesIndicators[data.main_value]);
      this.indicatorForm.controls.value_label.setValue(data.value_label);
      this.indicatorForm.controls.appNameInputControl.setValue(data.app_id);
      // this.indicatorForm.controls.category_id.setValue(data.category_id);
      this.indicatorForm.controls.appNameInputEditor.setValue(data.description);
      this.getCategories(data.app_id, data.category_id);
      this.setRanking(data.ranking);
      this.addInputs(data.inputs_config);
      this.addLabels(data.labels_config);

      this.indicatorForm.controls.id.disable();
    });

  }


  initAllowedIds() {
    // Do not allow any app by default
    this.allowedAppIds = [];
    this.allowAllAppIds = false;
    const account = this.storageService.get('account');
    if (!account) {
      return;
    }
    if (account.roles.includes('super_admin')) {
      // Allow all ids
      this.allowedAppIds = null;
      this.allowAllAppIds = true;
      return;
    }
    const profile = this.storageService.get('profile');
    if (!profile) {
      return;
    }
    this.allowedAppIds = profile.authorized_apps || [];



  }

  filtersAutoComplete(value: string, type: string) {
    return this[type].filter(
      (option: string) =>
        option && option.toLowerCase().indexOf(value.toLowerCase()) >= 0
    );
  }
  getCategories(app_id, id?) {
    this.categoriesService.getCategories(app_id).subscribe((data) => {
      this.categories = data.data;
      if (id) {
        this.categorySelected = this.categories.find((data) => data.id == id);
        this.indicatorForm.controls.category_id.setValue(this.categorySelected.name);
      }

    });
  }

  validateId() {
    if (this.mode == 'create') {
      this.loadingValidateId = true;
      this.isIdValid = true;
      this.indicatorsService.getIndicator(this.indicatorForm.controls.id.value)
        .subscribe((data: indicator) => {
          this.idValidated = true;
          this.loadingValidateId = false;
          this.isIdValid = false;
        }, (err) => {
          this.idValidated = true;
          this.loadingValidateId = false;
          if (err.error.code == 'NOT_FOUND') {
            this.isIdValid = true;
          }
        });
    }
  }

  OKClicked() {
    if (this.loadingValidateId || !this.idValidated) {
      Swal.fire({
        icon: 'warning',
        title: 'Validando ID del indicador Intente nuevamente',
        confirmButtonColor: '#ff9800',
      });
      return;
    }
    if (!this.categorySelected) {
      Swal.fire({
        icon: 'warning',
        title: 'selecciona una categoria de la lista',
        confirmButtonColor: '#ff9800',
      });
      return;
    }
    const inputs = this.getInputs();
    const labels = this.getLabels();

    const validateInputs = this.validateInputs();
    const validateLlabels = this.validateLabels();
    if(!validateInputs || !validateLlabels){
      return;
    }
    const validateIdInputs = this.validateIdInputs(inputs);
    const validateIdLabels = this.validateIdLabels(labels);

    if (!validateIdInputs || !validateIdLabels) {
      let title = 'Por favor valida los nombres del listado de inputs';
      if (!validateIdLabels) {
        title = 'Por favor valida los nombres del listado de labels';
      }
      if (!validateIdInputs && !validateIdLabels) {
        title = 'Por favor valida los nombres del listado de labels y inputs';
      }

      Swal.fire({
        icon: 'warning',
        title,
        confirmButtonColor: '#ff9800',
      });
      return;
    }

    const data: indicator = {
      app_id: this.mode == 'create' ? this.currentAppId : this.data.app_id,
      name: this.indicatorForm.controls.name.value,
      id: (this.indicatorForm.controls.id.value as string).replace(/\s+/g, '_'),
      category_id: this.categorySelected.id,
      visibility: this.indicatorForm.controls.enable.value,
      description: this.indicatorForm.controls.appNameInputEditor.value,
      value_label: this.indicatorForm.controls.value_label.value,
      accumulated_label: this.indicatorForm.controls.accumulated_label.value,
      inputs_config: inputs,
      labels_config: labels,
      main_value: typesIndicators[this.indicatorForm.controls.main_value.value],
      histogram_value: this.indicatorForm.controls.histogram_value.value,
      ranking: this.getRanking(),
    };
    if (this.mode == 'create') {
      this.indicatorsService.postIndicator(data).subscribe((res: any) => {
        console.log(res);

        if (res.created) {
          Swal.fire({
            icon: 'success',
            title: 'Indicador Agregada correctamente',
            confirmButtonColor: '#ff9800',
          }).then(() => {
            this.router.navigateByUrl('/pages/indicators/list');
          });
        }
      });
    } else {
      data.id = this.data.id;
      this.indicatorsService.putIndicator(data).subscribe((res: any) => {
        if (res.updated) {
          Swal.fire({
            icon: 'success',
            title: 'Indicador Editada correctamente',
            confirmButtonColor: '#ff9800',
          }).then(() => {
            this.router.navigateByUrl('/pages/indicators/list');
          });
        }
      });
    }
  }
  cancelClicked() {
    this.router.navigateByUrl('/pages/indicators/list');
  }

  addInputs(inputs?: inputs_config[]) {
    if (inputs) {
      inputs.forEach((data,index) => {
        this.inputs.push({
          id: data.id,
          name: data.name,
          position: data.position,
          controlsName: { id: 'input_id_' + index, name: 'input_name_' + index, position: 'input_position_' + index }
        });
      });
    } else {
      const controlsInputNames = { id: 'input_id_' + this.inputs.length, name: 'input_name_' + this.inputs.length, position: 'input_position_' + this.inputs.length };
      this.inputs.push({
        id: '',
        name: '',
        position: '',
        controlsName: { ...controlsInputNames }
      });
    }
  }

  setIdInput(value, index){
    this.inputs[index].id = this.autoId.buildAutoId(value);
  }

  setIdLabel(value, index){
    this.labels[index].id = this.autoId.buildAutoId(value);
  }

  async removeInput(index) {
    this.inputs.splice(index, 1);
  }

  validateIdInputs(inputs: inputs_config[]) {
    let valid = true;
    inputs.forEach((dataFor) => {
      const ConstDataFilterLength = inputs.filter((dataFilter) => dataFilter.id == dataFor.id).length;
      if (ConstDataFilterLength > 1) {
        valid = false;
      }
    });

    return valid;
  }

  addLabels(labels?) {
    if (labels) {
      labels.forEach((data,index) => {
        this.labels.push({
          id: data.id,
          name: data.name,
          position: data.position,
          controlsName: { id: 'label_id_' + index, name: 'label_name_' + index, position: 'label_position_' + index }
        });
      });
    } else {
      const controlsLabelNames = { id: 'label_id_' + this.labels.length, name: 'label_name_' + this.labels.length, position: 'label_position_' + this.labels.length };
      this.labels.push({
        id: '',
        name: '',
        position: '',
        controlsName: { ...controlsLabelNames }
      });
    }
  }

  removeLabels(index) {
    this.labels.splice(index, 1);
  }

  validateIdLabels(labels: inputs_config[]) {
    let valid = true;
    labels.forEach((dataFor) => {
      const ConstDataFilterLength = labels.filter((dataFilter) => dataFilter.id == dataFor.id).length;
      if (ConstDataFilterLength > 1) {
        valid = false;
      }
    });

    return valid;
  }

  onIdOptionSelected($event) {
    this.categorySelected = $event.option.value;
    this.indicatorForm.controls.category_id.setValue(this.categorySelected.name);
  }

  onAppSelected(appId: string) {
    this.currentAppId = appId;
    this.indicatorForm.controls.appNameInputControl.setValue(appId);
  }

  setRanking(rankings: ranking[]) {
    rankings.forEach((value, index) => {
      this.indicatorForm.controls['ranking_' + value.variable + '_enable'].setValue(value.enabled);
      this.indicatorForm.controls['ranking_' + value.variable + '_lenght'].setValue(value.length);
      this.indicatorForm.controls['ranking_' + value.variable + '_automatic'].setValue(value.automatic);
    });
  }

  getRanking() {
    const rankings: ranking[] = [];
    this.value_options_types.forEach((value, index) => {
      const objectranking =
      {
        'enabled': this.indicatorForm.controls['ranking_' + typesIndicators[value.value] + '_enable'].value
          ? this.indicatorForm.controls['ranking_' + typesIndicators[value.value] + '_enable'].value : false,
        'automatic': this.indicatorForm.controls['ranking_' + typesIndicators[value.value] + '_automatic'].value
          ? this.indicatorForm.controls['ranking_' + typesIndicators[value.value] + '_automatic'].value : false,
        'length': this.indicatorForm.controls['ranking_' + typesIndicators[value.value] + '_lenght'].value
          ? parseInt(this.indicatorForm.controls['ranking_' + typesIndicators[value.value] + '_lenght'].value) : 1,
        'variable': typesIndicators[value.value]
      };
      rankings.push(objectranking);
    });
    return rankings;

  }

  validateInputs(){
    let isValid = true;
    this.inputs.forEach((value, index) => {
      if(!this.inputs[index].id || !this.inputs[index].name || !this.inputs[index].position){
        Swal.fire({
          icon: 'warning',
          title: 'Por favor completa todos los inputs',
          confirmButtonColor: '#ff9800',
        });
        isValid = false;
      }
    });
    return isValid;
  }

  validateLabels(){
    let isValid = true;
    this.labels.forEach((value, index) => {
      if(!this.labels[index].id || !this.labels[index].name || !this.labels[index].position){
        Swal.fire({
          icon: 'warning',
          title: 'Por favor completa todos los labels',
          confirmButtonColor: '#ff9800',
        });
        isValid = false;
      }
    });
    return isValid;
  }

  getInputs() {
    const inputs = [];
    this.inputs.forEach((value, index) => {
      const objectinput =
      {
        'id': this.inputs[index].id,
        'name': this.inputs[index].name,
        'position': parseInt(this.inputs[index].position) ,
      };
      inputs.push(objectinput);
    });
    return inputs;
  }
  getLabels() {
    const labels = [];
    this.labels.forEach((value, index) => {
      const objectinput =
      {
        'id': this.labels[index].id,
        'name': this.labels[index].name,
        'position': parseInt(this.labels[index].position),
      };
      labels.push(objectinput);
    });
    return labels;
  }


}
