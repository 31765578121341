import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Brand, BrandRoot } from "../models/models";
@Injectable({
  providedIn: "root",
})
export class BrandService {
  private uriConnection: string = environment.pointz_api.base_url_new + '/allies/brands';
  constructor(private http: HttpClient) {}

  getBrands(): Observable<BrandRoot> {
    return this.http.get<BrandRoot>(this.uriConnection);
  }

  getBrand(idBrand: string) {
    return this.http.get<Brand>(`${this.uriConnection}/${idBrand}`);
  }
  postBrand(newBrand: Brand) {
    return this.http.post<any>(`${this.uriConnection}`, newBrand);
  }
  putBrand(brand: Brand) {
    return this.http.put<any>(`${this.uriConnection}/${brand.id}`, {
      ...brand,
    });
  }
}
