// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.challenge-answer, .challenge-description {
  display: grid;
  grid-template-columns: 2fr 10fr;
  width: 100%;
  column-gap: 4px;
  row-gap: 8px;
}

.answer-field-label {
  font-weight: bold;
}

img.field-value-img {
  max-width: 320px;
  max-height: 240px;
}

.challenge-answer-field {
  grid-column-start: 1;
  grid-column-end: 3;
  display: grid;
  grid-template-columns: 2fr 10fr;
  width: 100%;
  column-gap: 4px;
  row-gap: 8px;
}

.Text-align {
  position: absolute;
}
.Text-align--left {
  left: 5px;
}
.Text-align--right {
  right: 5px;
}

::ng-deep .mat-mdc-select-panel {
  max-width: initial;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/answers-invoices/invoices-edit/invoices-edit.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,+BAAA;EACA,WAAA;EACA,eAAA;EACA,YAAA;AACJ;;AAEE;EACE,iBAAA;AACJ;;AAEE;EACE,gBAAA;EACA,iBAAA;AACJ;;AAEE;EACE,oBAAA;EACA,kBAAA;EACA,aAAA;EACA,+BAAA;EACA,WAAA;EACA,eAAA;EACA,YAAA;AACJ;;AAEA;EACE,kBAAA;AACF;AAAE;EACE,SAAA;AAEJ;AAAE;EACE,UAAA;AAEJ;;AAEA;EACE,kBAAA;AACF","sourcesContent":[".challenge-answer, .challenge-description {\n    display: grid;\n    grid-template-columns: 2fr 10fr;\n    width: 100%;\n    column-gap: 4px;\n    row-gap: 8px;\n  }\n  \n  .answer-field-label {\n    font-weight: bold;\n  }\n  \n  img.field-value-img {\n    max-width: 320px;\n    max-height: 240px;\n  }\n  \n  .challenge-answer-field {\n    grid-column-start: 1;\n    grid-column-end: 3;\n    display: grid;\n    grid-template-columns: 2fr 10fr;\n    width: 100%;\n    column-gap: 4px;\n    row-gap: 8px;\n  }\n\n.Text-align {\n  position: absolute;\n  &--left{\n    left: 5px;\n  }\n  &--right{\n    right: 5px;\n  }\n}\n\n::ng-deep .mat-mdc-select-panel {\n  max-width: initial;  \n}\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
