export class Countries {
  public static COUNTRIES = [
    {
      "name_en": "Argentina",
      "name_es": "Argentina",
      "dial_code": "+54",
      "code": "AR"
    },
    {
      "name_en": "Bolivia",
      "name_es": "Bolivia",
      "dial_code": "+591",
      "code": "BO"
    },
    {
      "name_en": "Colombia",
      "name_es": "Colombia",
      "dial_code": "+57",
      "code": "CO"
    },
    {
      "name_en": "Costa Rica",
      "name_es": "Costa Rica",
      "dial_code": "+506",
      "code": "CR"
    },
    {
      "name_en": "Ecuador",
      "name_es": "Ecuador",
      "dial_code": "+593",
      "code": "EC"
    },
    {
      "name_en": "Guatemala",
      "name_es": "Guatemala",
      "dial_code": "+502",
      "code": "GT"
    },
    {
      "name_en": "Honduras",
      "name_es": "Honduras",
      "dial_code": "+504",
      "code": "HN"
    },
    {
      "name_en": "Mexico",
      "name_es": "Mexico",
      "dial_code": "+52",
      "code": "MX"
    },
    {
      "name_en": "Nicaragua",
      "name_es": "Nicaragua",
      "dial_code": "+505",
      "code": "NI"
    },
    { "name_en": "Panama", "name_es": "Panama", "dial_code": "+507", "code": "PA" },
    {
      "name_en": "Chile",
      "name_es": "Chile",
      "dial_code": "+56",
      "code": "CL"
    },
    {
      "name_en": "Peru",
      "name_es": "Peru",
      "dial_code": "+51",
      "code": "PE"
    },
    { "name_en": "El Salvador", "name_es": "El Salvador", "dial_code": "+503", "code": "SV" },
    {
      "name_en": "Dominican Republic",
      "name_es": "República Dominicana",
      "dial_code":"+1",
      "code": "DO"
    },
    {
      "name_en": "Trinidad and Tobago",
      "name_es": "Trinidad y Tobago",
      "dial_code": "+868",
      "code": "TT"
    },
    {
      "name_en": "Jamaica",
      "name_es": "Jamaica",
      "dial_code": "+1",
      "code": "JM"
    },
    {
      "name_en": "Guyana",
      "name_es": "Guyana",
      "dial_code": "+592",
      "code": "GY"
    },
    {
      "name_en": "Aruba",
      "name_es": "Aruba",
      "dial_code": "+297",
      "code": "AW"
    },
    {
      "name_en": "Curacao",
      "name_es": "Curacao",
      "dial_code": "+599",
      "code": "CW"
    },
    {
      "name_en": "Barbados",
      "name_es": "Barbados",
      "dial_code": "+246",
      "code": "BB"
    },
    {
      "name_en": "Saint Lucia",
      "name_es": "Santa Lucia",
      "dial_code": "+758",
      "code": "LC"
    },
    {
      "name_en": "Cayman Islands",
      "name_es": "Islas Cayman",
      "dial_code": "+345",
      "code": "KY"
    },
    {
      "name_en": "The Commonwealth of Puerto Rico",
      "name_es": "Puerto Rico",
      "dial_code": "+787",
      "code": "PR"
    },
    {
      "name_en": "United States of America",
      "name_es": "Estados Unidos de America",
      "dial_code": "+1",
      "code": "US"
    },

  ];
}
