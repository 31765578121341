import { Injectable, Optional } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { RecognitionType, RecognitionTypes } from "../models/recognitions/recognitions-model";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RecognitionsService {
  private uriConnection: string = environment.recognitions_api.base_url + "/admin/recognition_type";
  private apiKey = environment.recognitions_api.apiKey;

  constructor(private http: HttpClient,
    private authService: AuthService) { }

  getRecognitionTypes(): Observable<RecognitionTypes> {
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    return this.http.get<RecognitionTypes>(`${this.uriConnection}?key=${this.apiKey}`, { headers });
  }
  getRecognitionType(id: string) {
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    const uri = `${this.uriConnection}/${id}?key=${this.apiKey}`;
    return this.http.get(uri,{ headers });
  }
  postRecognitionType(newRecognitionType: RecognitionType) {
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    return this.http.post<RecognitionType>(`${this.uriConnection}?key=${this.apiKey}`, newRecognitionType, { headers });
  }
  putRecognitionType(recognitionType: RecognitionType) {
    return this.http.put<RecognitionType>(
      `${this.uriConnection}/${recognitionType.id}?key=${this.apiKey}`,
      recognitionType
    );
  }
}
