import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { sections } from './sections/sections.model';

@Injectable({
  providedIn: 'root'
})
export class SectionsService {
  private uriConnection: string = environment.kpi_api.base_url + "/apps/gnx_mvp_improved_stg/kpi-category-sections";
  private apiKey = environment.kpi_api.apiKey;
  constructor(private http: HttpClient) {}

  getSections(appid){
    this.uriConnection = `${environment.kpi_api.base_url}/apps/${appid}/kpi-category-sections`;
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    return this.http.get<{data:sections[]}>(`${this.uriConnection}?key=${this.apiKey}`, { headers });
  }
  
  getSection(id: string) {
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    const uri = `${this.uriConnection}/${id}?key=${this.apiKey}`;
    return this.http.get(uri,{ headers });
  }
  postSection(newSections: sections, appid) {
    this.uriConnection = `${environment.kpi_api.base_url}/apps/${appid}/kpi-category-sections`;
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    return this.http.post<sections>(`${this.uriConnection}?key=${this.apiKey}`, newSections, { headers });
  }
  putSection(sections: sections) {
    this.uriConnection = `${environment.kpi_api.base_url}/apps/${sections.app_id}/kpi-category-sections`;
    return this.http.put<sections>(
      `${this.uriConnection}/${sections.id}?key=${this.apiKey}`,
      sections
    );
  }
}