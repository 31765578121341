import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ProgramsUtilService } from '../../services/programs-util.service';
import { StorageService } from '../../services/storage.service';
import { ProgramFull } from '../../users-api';

interface ProgramOption {
  programId: string;
  programLabel: string;
}

@Component({
  selector: 'app-program-selector',
  templateUrl: './program-selector.component.html',
  styleUrls: ['./program-selector.component.scss']
})
export class ProgramSelectorComponent implements OnInit {

  @Output() onProgramSelected = new EventEmitter<string>();
  @Input()
  set currentProgramId(programId: string) {
    this.programSelectControl.setValue(programId);
  }
  @Input() 
  set appId(appIdProgram: string) {
    this.loadProgramsOptions(appIdProgram);
  }

  @Input()getFullInfoProgram = false;
  @Input()disabled = false;
  @Input()isRequired = false;
  @Output() onProgramSelectedFull = new EventEmitter<ProgramFull>();

  programSelectControl = new UntypedFormControl();
  programsOptions: ProgramOption[];

  constructor(
    private storageService: StorageService,
    private programsUtilService: ProgramsUtilService,
  ) { }

  async setSelectedProgram(programId: string) {
    this.storageService.set('ProgramSelectorComponent:lastSelectedProgram', programId);
    this.onProgramSelected.emit(programId);
    if(this.getFullInfoProgram){
      const list = await this.programsUtilService.getPrograms(this.appId,true);
      this.onProgramSelectedFull.emit(list.filter((program) => program.id === programId )[0]);
    }
  }

  async loadProgramsOptions(appId?) {
    const appIdSearch = appId ? appId : this.appId;
    try {
      const programs = await this.programsUtilService.getPrograms(appIdSearch);
      this.programsOptions = programs.map((program: ProgramFull) => ({
        programId: program.id,
        programLabel: `${program.name} (${program.id})`
      }));
    } catch (error) {
      console.error('ProgramSelectorComponent: getting programs list', error);
    }
    const lastSelected = this.storageService.get('ProgramSelectorComponent:lastSelectedProgram');
    if (lastSelected) {
      this.programSelectControl.setValue(lastSelected);
      this.onProgramSelected.emit(lastSelected);
    }
  }

  ngOnInit() {
    if(this.appId){
      this.loadProgramsOptions();
    }
    this.programSelectControl.markAllAsTouched();
    this.programSelectControl.valueChanges.subscribe(
      (programId: string) => this.setSelectedProgram(programId)
    );
  }

}
