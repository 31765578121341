import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { UntypedFormControl } from '@angular/forms';
import { AppsUtilService } from '../../../services/apps-util.service';

import {
  AppUserInput,
  AppUsersService,
  AppsService,
  ProfileFieldDefinitionOutput,
  ProfileFieldsOutput,
  AppUserProfileInput,
  AppOutput,
  CompaniesService,
  ProgramsService,
  ProgramsList,
} from '../../../users-api';

export interface AppUsersEditorFieldData {
  id: string;
  definition: ProfileFieldDefinitionOutput;
}

export interface AppUsersEditorOutput {
  user: AppUserInput;
  profile: AppUserProfileInput;
}

@Component({
  selector: 'app-app-users-editor',
  templateUrl: './app-users-editor.component.html',
  styleUrls: ['./app-users-editor.component.scss'],
  providers: [AppUsersService, AppsService],
})
export class AppUsersEditorComponent implements OnInit {
  @Input() userData: AppUserInput = {
    app_id: '',
    username: '',
    email: '',
    phone: '',
    password: '',
    roles: [],
    programs: [],
    enabled: true,
    personal_data: {
      iddoc_type: '',
      iddoc: '',
      name: '',
      last_name: '',
    },
  };
  @Input() appUserProfileFields: AppUserProfileInput = {
    data: {},
  };
  @Input() mode = 'create';

  @Output() onOK = new EventEmitter<AppUsersEditorOutput>();
  @Output() onCancel = new EventEmitter<void>();

  roleInputControl = new UntypedFormControl();
  programInputControl = new UntypedFormControl();
  appNameInputControl = new UntypedFormControl();

  uriSeparatorKeyCodes = [ENTER, SPACE];
  fieldSeparatorKeyCodes = [ENTER];

  roleOptions: string[] = [];
  filteredRoleOptions: Observable<string[]>;

  applicationsOptions: string[] = [];
  filteredAppsOptions: string[];
  objectsAppsData: AppOutput[];
  appsNameMap: Map<string, AppOutput> = new Map();
  appsIdMap: Map<string, AppOutput> = new Map();

  programsOptions: string[] = [];
  filteredProgramsOptions: Observable<string[]>;

  appProfileDefinition: ProfileFieldsOutput;
  appProfileFields: Array<AppUsersEditorFieldData> = [];
  loadingAppProfileDefinition = false;
  programIdsByAppMap = new Map<string, string[]>();

  constructor(
    private snackBar: MatSnackBar,
    private usersApi: AppUsersService,
    private appsApi: AppsService,
    private companiesApi: CompaniesService,
    private programsApi: ProgramsService,
    private appsUtilService: AppsUtilService,
  ) {
    // Load roles
    const knownRolesJSON = localStorage.getItem('appUsersRoles');
    if (knownRolesJSON) {
      const knownRoles = JSON.parse(knownRolesJSON);
      this.roleOptions = knownRoles;
    }
    this.usersApi.getAppUsersRoles().subscribe((roles) => {
      this.roleOptions = roles;
      localStorage.setItem('appUsersRoles', JSON.stringify(roles));
    });
  }

  async loadPrograms() {
    if (!this.userData.app_id) {
      return;
    }
    let ids = this.programIdsByAppMap.get(this.userData.app_id);
    if (ids) {
      this.programsOptions = ids;
      this.programInputControl.setValue('', { emitEvent: true });
      return;
    }
    this.programIdsByAppMap.set(this.userData.app_id, []);
    const programsList: ProgramsList =
      await this.programsApi.findAppIdPrograms(this.userData.app_id).toPromise();
    if (!programsList.data) {
      return;
    }
    ids = programsList.data.map((program) => program.id);
    this.programsOptions = ids;
    this.programIdsByAppMap.set(this.userData.app_id, ids);
    this.programInputControl.setValue('', { emitEvent: true });
  }

  setApps(apps: AppOutput[]) {
    this.objectsAppsData = apps;
    this.applicationsOptions = apps.map(
      (app: AppOutput) => `${app.name} | ${app.id}`
    );
    this.appsNameMap = apps.reduce(
      (appsMap: Map<string, AppOutput>, app: AppOutput) => {
        appsMap.set(app.name.toLowerCase(), app);
        return appsMap;
      },
      new Map()
    );
    this.appsIdMap = apps.reduce(
      (appsMap: Map<string, AppOutput>, app: AppOutput) => {
        appsMap.set(app.id, app);
        return appsMap;
      },
      new Map()
    );
  }

  async initApps() {
    try {
      const apps = await this.appsUtilService.getApps();
      this.setApps(apps);
      if (this.mode !== 'create') {
        const userApp = this.appsIdMap.get(this.userData.app_id);
        let userNameApp = 'Desconocida';
        if (userApp) {
          userNameApp = userApp.name;
        }
        this.appNameInputControl.setValue(userNameApp);
        this.appNameInputControl.disable();
        await this.loadPrograms();
      }
    } catch (error) {
      console.error('Error getting apps', error);
    }
  }

  ngOnInit() {
    this.initApps();
    // Filter roles
    this.filteredRoleOptions = this.roleInputControl.valueChanges
      .pipe(startWith(null))
      .pipe(
        map((val) =>
          val
            ? this.filtersAutoComplete(val, 'roleOptions')
            : this.roleOptions.slice()
        )
      );
    // Filter programs
    this.filteredProgramsOptions = this.programInputControl.valueChanges
      .pipe(startWith(null))
      .pipe(
        map((val) =>
          val
            ? this.filtersAutoComplete(val, 'programsOptions')
            : this.programsOptions.slice()
        )
      );
    // Filter app names
    if (this.mode === 'create')  {
      this.appNameInputControl.valueChanges
        .pipe(startWith(null))
        .subscribe((val: string) => {
          if (val) {
            const nameParts = val.split('|').map((part: string) => part.trim());
            let app =
              nameParts.length > 1
                ? this.appsIdMap.get(nameParts[1])
                : this.appsIdMap.get(nameParts[0].toLowerCase());
            app = app ? app : this.appsNameMap.get(nameParts[0].toLowerCase());
            this.userData.app_id = app ? app.id : undefined;
            if (this.userData.app_id) {
              this.loadPrograms();
            }
          }

          this.filteredAppsOptions = val
            ? this.filtersAutoComplete(val, 'applicationsOptions')
            : this.applicationsOptions.slice();
        });
    }
  }

  okClicked() {
    const data: AppUserInput = {
    } as AppUserInput;
    for (const prop of [
      'app_id',
      'username',
      'enabled',
      'email',
      'phone',
      'password',
      'roles',
      'programs',
    ]) {
      if (!this.userData[prop]) {
        continue;
      }
      if (
        Array.isArray(this.userData[prop]) ||
        typeof this.userData[prop] === 'boolean'
      ) {
        data[prop] = this.userData[prop];
        continue;
      }
      data[prop] = this.userData[prop].toString().trim();
    }
    // Cuando el dato bool es falso se lo salta el paso anterior
    data.enabled = this.userData.enabled;
    // Email no puede ser ''
    if (!data.email) {
      delete data.email;
    }
    if (!data.password) {
      delete data.password;
    }
    const personal_data = this.userData.personal_data;
    for (const prop of [
      'iddoc_type',
      'iddoc',
      'name',
      'last_name',
    ]) {
      if (!personal_data[prop]) {
        continue;
      }
      if (!data.personal_data) {
        data.personal_data = {};
      }
      data.personal_data[prop] = personal_data[prop].toString().trim();
    }
    const profile: AppUserProfileInput = {
      data: {},
    };
    for (const field in this.appUserProfileFields.data) {
      if (
        Array.isArray(this.appUserProfileFields.data[field])
      ) {
        profile.data[field] = this.appUserProfileFields.data[field];
      }
    }
    const output: AppUsersEditorOutput = {
      user: data,
      profile: profile,
    };
    this.onOK.emit(output);
    return false;
  }

  cancelClicked() {
    this.onCancel.emit();
    return false;
  }

  addRole(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value.trim();

    if (value && input) {
      if (!/^\w.+$/.test(value)) {
        this.snackBar.open(
          'El rol solo debe llevar dígitos, letras o guión_bajo',
          'OK'
        );
        return false;
      }
      if (!this.userData.roles) {
        this.userData.roles = [];
      }
      const index = this.userData.roles.indexOf(value);
      // Only add if not found
      if (index < 0) {
        this.userData.roles.push(value);
      }
      input.value = '';
    }
  }

  removeRole(role: string) {
    const index = this.userData.roles.indexOf(role);
    if (index >= 0) {
      this.userData.roles.splice(index, 1);
    }
  }

  filtersAutoComplete(value: string, type: string) {
    return this[type].filter(
      (option: string) =>
        option && option.toLowerCase().indexOf(value.toLowerCase()) >= 0
    );
  }

  isProfileDefinitionLoaded(): boolean {
    if (
      this.appProfileDefinition &&
      this.appProfileDefinition.id.startsWith(this.userData.app_id)
    ) {
      return true;
    }
    return false;
  }

  // Lazy load profile definition
  onSelectedTabChange(event: MatTabChangeEvent) {
    if (event.tab.textLabel === 'PERFIL' && !this.isProfileDefinitionLoaded()) {
      this.loadProfileDefinition();
    }
  }

  loadProfileDefinition() {
    if (
      this.userData &&
      this.userData.app_id &&
      this.userData.app_id.trim().length
    ) {
      this.loadingAppProfileDefinition = true;
      this.appsApi
        .retrieveAppProfileFields(this.userData.app_id.trim())
        .subscribe(
          (profile) => {
            this.appProfileDefinition = profile;
            this.decodeAppProfileDefinition(profile);
            this.loadingAppProfileDefinition = false;
          },
          (error) => {
            this.loadingAppProfileDefinition = false;
            console.error(error);
          }
        );
    }
  }

  decodeAppProfileDefinition(profile: ProfileFieldsOutput) {
    this.appProfileFields = Object.entries(profile.fields)
      .map(([id, definition]) => ({ id, definition }))
      .sort((a, b) => (a.id > b.id ? 1 : -1));
  }

  getProfileFieldValues(fieldId: string) {
    if (
      this.appUserProfileFields &&
      fieldId in this.appUserProfileFields.data
    ) {
      return this.appUserProfileFields.data[fieldId];
    }
    return [];
  }

  removeProfileFieldValue(fieldId: string, value: string) {
    const index = this.appUserProfileFields.data[fieldId].indexOf(value);
    if (index >= 0) {
      this.appUserProfileFields.data[fieldId].splice(index, 1);
    }
  }

  isProfileFieldEnum(field: AppUsersEditorFieldData) {
    if ('enum' in field.definition && field.definition.enum.length) {
      return true;
    }
    return false;
  }

  ensureProfileField(fieldId: string) {
    if (!(fieldId in this.appUserProfileFields.data)) {
      this.appUserProfileFields.data[fieldId] = [];
    }
  }

  addProfileFieldValue(fieldId: string, value: string) {
    const trimmedValue = value.trim();
    if (trimmedValue) {
      this.ensureProfileField(fieldId);
      const index = this.appUserProfileFields.data[fieldId].indexOf(
        trimmedValue
      );
      // Only add if not found
      if (index < 0) {
        this.appUserProfileFields.data[fieldId].push(trimmedValue);
      }
    }
  }

  addProfileFieldChipInputValue(fieldId: string, event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value.trim();

    if (input && value) {
      this.addProfileFieldValue(fieldId, value);
      input.value = '';
    }
  }

  getFieldType(definition: ProfileFieldDefinitionOutput) {
    if (definition.format && definition.format.length) {
      return 'format ' + definition.format;
    }
    if (definition.enum && definition.enum.length) {
      return 'enum';
    }
    if (definition.pattern && definition.pattern.length) {
      return 'pattern /' + definition.pattern + '/';
    }
    return 'free';
  }

  addProgram(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value.trim();

    if (value && input) {
      // Check input program is known
      if (this.programsOptions.indexOf(value) < 0) {
        return;
      }
      // Init property if non existent
      if (!this.userData.programs) {
        this.userData.programs = [];
      }
      // Check value is not repeated
      if (this.userData.programs.indexOf(value) >= 0) {
        return;
      }
      // Add value
      this.userData.programs.push(value);
      input.value = '';
    }
  }

  removeProgram(program: string) {
    const index = this.userData.programs.indexOf(program);
    if (index >= 0) {
      this.userData.programs.splice(index, 1);
    }
  }
}
