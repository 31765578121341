import { PERMISSIONS, PermissionsService } from './../../services/permissions/permissions.service';
import { ChangeDetectorRef, Component, Inject, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest, of, from } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  switchMap,
  startWith,
  map,
  catchError,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';
import { AppUserOutput } from '../../users-api/model/appUserOutput';
import { UntypedFormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { StorageService } from '../../services/storage.service';
import {
  ExhibitionAnswer,
  INVOICES_ANSWER_STATUSES,
  INVOICES_STATUS_OPTIONS
} from '../../models/answers/answers-models';
import { AuthService } from '../../services/auth.service';
// import {formatDate} from '@angular/common';
import moment from 'moment';
import { ExhibitionsService } from '../exhibitions/services/exhibitions.service';

export const KEY_EVENT_RELOAD_EXHIBITIONS = 'exhibitions:reload'
const KEY_LAST_USER_EXHIBITION_SELECTED = 'LastUserSelectedExhibitionsList';
@Component({
  selector: 'app-answers-exhibiciones',
  templateUrl: './answers-exhibiciones.component.html',
  styleUrls: ['./answers-exhibiciones.component.scss']
})
export class AnswersExhibicionesComponent implements OnInit, OnDestroy {
  loading = false;
  displayedColumns = ['answer'];
  pageSize = 100;
  hoveredRow: any;
  dataSource: Observable<ExhibitionAnswer[]>;
  uidFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  appIdFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  idFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  idOptions$: Observable<string[]>;
  idControl = new UntypedFormControl('');
  statusControl = new UntypedFormControl('', Validators.required);
  startDateControl = new UntypedFormControl(Validators.required);
  endDateControl = new UntypedFormControl();
  currentUser: AppUserOutput;
  currentAppId: string;
  statusOptions: {
    label: string;
    value: string;
  }[];
  allowedAppIds: string[];
  allowAllAppIds = false;
  requiredFields = true;
  resultsFound = true;
  foundNumber: number;
  resultsNumberLimit = 100;
  dataArrayToExcel = [];
  dataSearch;
  dataFilter;


  lastAppId;
  lastParams;
  broadcast = new BroadcastChannel(KEY_EVENT_RELOAD_EXHIBITIONS);
  UserPanelExpanded = false;
  constructor(
    @Inject('ExhibitionsFireAuth') private exhibitionsAuth: AngularFireAuth,
    @Inject('ExhibitionsFirestore') private exhibitionFire: AngularFirestore,
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private authService: AuthService,
    private exhibitionsService: ExhibitionsService,
    private permissions: PermissionsService,
    private cdr: ChangeDetectorRef,
  ) {
    this.getUserInfo();
  }

  async ngOnDestroy() {
    this.broadcast.close();
    this.storageService.delete(KEY_LAST_USER_EXHIBITION_SELECTED);
  }

  async ngOnInit() {
    await this.initAllowedIds();
    this.statusOptions = [{
      label: 'Seleccionar',
      value: ''
    }];
    this.statusOptions.push(...INVOICES_STATUS_OPTIONS);
    this.idOptions$ = combineLatest([
      this.appIdFilter$.pipe(distinctUntilChanged()),
      this.idControl.valueChanges.pipe(
        startWith(''),
        debounceTime(500),
        distinctUntilChanged()
      )
    ]).pipe(
      switchMap(([appId, prefix]) => {
        if (!this.allowAllAppIds && (!this.allowedAppIds || this.allowedAppIds.length === 0)) {
          return of([]);
        }
        if (!prefix || !prefix.trim()) {
          this.idFilter$.next(null);
          return of([]);
        }
        return this.exhibitionFire.collection('ExhibitionsAnswers', ref => {
          const trimmedPrefix = prefix.trim();
          let query = ref.limit(10);
          query = query.where('exhibitionName', '>=', trimmedPrefix);
          query = query.where('exhibitionName', '<=', `${trimmedPrefix}~`);
          const appIds = appId ? [appId] : (this.allowAllAppIds ? null : this.allowedAppIds);
          if (appIds) {
            query = query.where('appId', 'in', appIds);
          }
          return query;
        }).valueChanges({ idField: 'id' });
      }),
      map(
        items => items
          .map(i => i.exhibitionName)
          .filter((t, i, self) => self.indexOf(t) === i)
      ),
      catchError(err => {
        console.error(err);
        Swal.fire('Error', err.message || err, 'error');
        return of([]);
      })
    );
    this.idOptions$.subscribe((data) => {
      this.dataSearch = data;
    });
    this.startDateControl.markAsTouched();
    this.statusControl.markAsTouched()


    this.broadcast.onmessage = () => {
      this.reloadPage();
    }
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.route.queryParams.subscribe((params: Params) => {

      const parameters = {}
      if (params.appId) {
        parameters['appId'] = params.appId; 
        this.currentAppId = params.appId;}
      if (params.id) {
        parameters['exhibition_name'] = params.id.trim();
        this.idControl.setValue(params.id);}
      if (params.status) {
        parameters['status'] = params.status; 
        this.statusControl.setValue(params.status);}

      if (moment(params.startDate).isValid() ) {
        parameters['createAt_start'] = moment(params.startDate).startOf('day').toDate();
        this.startDateControl.setValue(moment(params.startDate).startOf('day'))};

      if (moment(params.endDate).isValid()) {
        parameters['createAt_end'] = moment(params.endDate).endOf('day').toDate();
        this.endDateControl.setValue(moment(params.endDate).endOf('day'))};

      if (params.user) {
        this.currentUser = this.storageService.get(KEY_LAST_USER_EXHIBITION_SELECTED);
        this.uidFilter$.next(this.currentUser.id);
        this.UserPanelExpanded = true;
        parameters['uid'] = this.currentUser.id
      }

      this.lastParams = parameters;
      if (params.appId && params.status && params.startDate) {
        
        this.exhibitionsService.getExhibitions(this.currentAppId, true, this.lastParams).subscribe((data: any) => {
          this.resultsFound = data.length > 0;
          this.foundNumber = data.length;
          this.dataArrayToExcel = data;
          this.requiredFields = true;
          this.dataSource =data.map((a: ExhibitionAnswer) => {
            a.statusLabel = INVOICES_ANSWER_STATUSES[a.status];
            if (a.fields) {
              a.textFields = a.fields.filter(i => (i.type !== 'file' && i.type !== 'camera')).map(item => {
                if (item.name === 'date') {
                  item['value'] = moment(item.value).format('DD MMM YYYY HH:mm:ss');
                }
                return item;
              });
              a.imgFields = a.fields.filter(i => (i.type === 'file' || i.type === 'camera'));
            }
            return a;
          });
        });
        
      } else { this.requiredFields = false;}

      this.cdr.detectChanges();
      
    })

   combineLatest([
    this.appIdFilter$.pipe(distinctUntilChanged()),
    this.uidFilter$.pipe(distinctUntilChanged()),
    this.idFilter$.pipe(startWith(''), distinctUntilChanged()),
    this.statusControl.valueChanges.pipe(startWith(''), distinctUntilChanged()),
    this.startDateControl.valueChanges.pipe(startWith(''), distinctUntilChanged()),
    this.endDateControl.valueChanges.pipe(startWith(''), distinctUntilChanged()),
  ]).subscribe(() => {
    this.setQueryParamsURL();
  });

  }

  reloadPage(){
    window.location.reload();
  }

  setQueryParamsURL() {
    const queryParams: Params = {
      appId: this.currentAppId,
      id: this.idControl.value,
      status: this.statusControl.value,
      startDate: this.startDateControl.value.toISOString(),
      endDate: this.endDateControl.value.toISOString(),
      user: this.currentUser ? true : null
    };
    this.storageService.set(KEY_LAST_USER_EXHIBITION_SELECTED, this.currentUser);
    
    Object.keys(queryParams).forEach((key) => {
      if (!queryParams[key]) {
        delete queryParams[key];
      }
    })
    this.router.navigate(
      ['/pages/exhibitions'],
      {
        queryParams, // remove to replace all query params by provided
      });
  }

  async initAllowedIds() {
    // Do not allow any app by default
    this.allowedAppIds = [];
    this.allowAllAppIds = false;
    const account = this.storageService.get('account');
    if (!account) {
      return;
    }
    if (account.roles.includes('super_admin')) {
      // Allow all ids
      this.allowedAppIds = null;
      this.allowAllAppIds = true;
      return;
    }
    if (await this.permissions.hasPermission(PERMISSIONS.ACCESS_ALL)) {
      this.allowedAppIds = null;
      this.allowAllAppIds = true;
      return;
    }
    const profile = this.storageService.get('profile');
    if (!profile) {
      return;
    }
    this.allowedAppIds = profile.authorized_apps || [];
  }

  async getUserInfo() {
    const challengesUser = await this.authService.getRemoteFirebaseUser(
      this.exhibitionsAuth,
      'challenges'
    );
  }



  clearData(value) {
    const val = String(value.target.value).toLowerCase();
    if (!val) {
      // this.getRecognitions(this.currentAppId, this.currentProgramId, this.startDate, this.endDate);
    } else {
      console.log(this.dataSearch);

      // this.dataFilter = this.dataSearch.filter(x => String(x.translations[0].texts[0].name).toLowerCase().includes(val))
    }
  }

  onUserSelected(user: AppUserOutput) {
    this.currentUser = user;
    this.uidFilter$.next(user.id);
  }

  onAppSelected(appId: string) {
    this.appIdFilter$.next(appId);
    this.currentAppId = appId;
  }

  onIdOptionSelected() {
    this.idFilter$.next(this.idControl.value);
  }

  resetUid() {
    this.currentUser = null;
    this.uidFilter$.next(null);
  }

  resetApp() {
    this.currentAppId = null;
    this.uidFilter$.next(null);
  }

  onEdit(exhibition: ExhibitionAnswer) {
    const url = this.router.createUrlTree(['pages', 'exhibitions', this.currentAppId, exhibition.exhibition_id, exhibition.uid]);
    window.open(url.toString(), '_blank');
    return false;
  }

  getRowBackgroundColor(row) {
    return row === this.hoveredRow ? '#F5F5F5' : 'transparent';
  }

  userFilterClosed() {
    this.resetUid();
  }

}
