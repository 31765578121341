import { StorageService } from './../../../../services/storage.service';

import { category_indicator } from './../../categories/categories.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { SectionsService } from '../../sections.service';
import { AppOutput } from '../../../../users-api';
import { sections } from '../sections.model';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-sections-add',
  templateUrl: './sections-add.component.html',
  styleUrls: ['./sections-add.component.scss']
})
export class SectionsAddComponent implements OnInit {


  mode: 'create' | 'edit' = 'create'
  name = "Nueva Seccion"

  sectionForm: UntypedFormGroup;

  applicationsOptions: string[] = [];
  filteredAppsOptions: string[];
  objectsAppsData: AppOutput[];
  appsNameMap: Map<string, AppOutput> = new Map();
  appsIdMap: Map<string, AppOutput> = new Map();
  appId = "";
  data: sections;
  loading = false;
  currentAppId;
  allowedAppIds: string[];
  allowAllAppIds = false;
  categories: category_indicator[];
  categorySelected: category_indicator;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private sectionsService: SectionsService,
    private storageService: StorageService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.initConstructor();
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.mode = 'edit';
      this.name = "Editar Seccion";
      this.appId = this.route.snapshot.paramMap.get('appId');
      this.getSection(id);
      this.sectionForm.controls.appNameInputControl.disable();
    }
  }

  initConstructor() {

    this.sectionForm = this.formBuilder.group({
      name: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
        ])  
      ],
      appNameInputControl: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
        ])  
      ],
    })
  }
  initAllowedIds() {
    // Do not allow any app by default
    this.allowedAppIds = [];
    this.allowAllAppIds = false;
    const account = this.storageService.get('account');
    if (!account) {
      return;
    }
    if (account.roles.includes('super_admin')) {
      // Allow all ids
      this.allowedAppIds = null;
      this.allowAllAppIds = true;
      return;
    }
    const profile = this.storageService.get('profile');
    if (!profile) {
      return;
    }
    this.allowedAppIds = profile.authorized_apps || [];
  }

  getSection(id) {
    console.log(id);
    this.sectionsService.getSection(id).subscribe((data:sections)=>{
      console.log(data);
      this.data = data;
      this.mode = 'edit';
      this.sectionForm.controls.name.setValue(data.name);
      this.sectionForm.controls.appNameInputControl.setValue(data.app_id)
      
    })

  }
  cancelClicked() {
    this.router.navigateByUrl('/pages/indicators/list');
  }

  onAppSelected(appId: string) {
    this.currentAppId = appId
    this.sectionForm.controls.appNameInputControl.setValue(appId)
  }

  OKClicked() {
    const data: sections = {
      app_id: this.mode == 'create' ? this.currentAppId : this.data.app_id,
      name: this.sectionForm.controls.name.value,
    }
    console.log(data);
    
    if (this.mode == 'create') {
      this.sectionsService.postSection(data,data.app_id).subscribe((res: any) => {
        console.log(res);

        if (res.created) {
          Swal.fire({
            icon: 'success',
            title: 'Seccion Agregada correctamente',
            confirmButtonColor: '#ff9800',
          }).then(() => {
            this.router.navigateByUrl('/pages/sections/list');
          });
        }
      })
    } else {
      data.id = this.data.id
      this.sectionsService.putSection(data).subscribe((res: any) => {
        if (res.updated) {
          Swal.fire({
            icon: 'success',
            title: 'Seccion Editada correctamente',
            confirmButtonColor: '#ff9800',
          }).then(() => {
            this.router.navigateByUrl('/pages/sections/list');
          });
        }
      })
    }
  }


}
