import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

export interface DrupalTransferToken {
  code?: string;
  token?: string;
}

@Injectable({
  providedIn: 'root'
})
export class DrupalUserTransferService {

  constructor(
    public httpClient: HttpClient
  ) {}

  getDrupalTransferToken(): Observable<DrupalTransferToken> {
    const url = new URL(
      '/gluky-login/user-transfer-request',
      environment.drupal_cms.base_url
    );
    return this.httpClient.request<DrupalTransferToken>(
      'GET', url.href, {responseType: 'json'}
    );
  }
}
