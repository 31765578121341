import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements OnInit {
  url: any;
  stateLoader = false
  constructor(private authService: AuthService, private router: Router, ){
   
    this.url = this.router.url;
  }

  async validationAuth(){
    this.stateLoader = true;
    await this.authService.confirmSignIn(this.url);
    await this.authService.getFirebaseAuth();
    this.stateLoader = false; 
  }

  ngOnInit() {
    this.validationAuth();
  }
}
