import { category_indicator } from './categories/categories.model';
import { environment } from './../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  private uriConnection: string = environment.kpi_api.base_url + "/apps/gnx_mvp_improved_stg/kpi-categories";
  private apiKey = environment.kpi_api.apiKey;
  constructor(private http: HttpClient) { }

  getCategories(appId){
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    this.uriConnection = `${environment.kpi_api.base_url}/apps/${appId}/kpi-categories?key=${this.apiKey}`;
    const route = appId ? `${environment.kpi_api.base_url}/apps/${appId}/kpi-categories?key=${this.apiKey}`:`${this.uriConnection}?key=${this.apiKey}`
    return this.http.get<{data:category_indicator[]}>(route, { headers });
  }
  
  getCategory(id: string, appId: string) {
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    this.uriConnection = `${environment.kpi_api.base_url}/apps/${appId}/kpi-categories`;
    const uri = `${this.uriConnection}/${id}?key=${this.apiKey}`;
    return this.http.get(uri,{ headers });
  }
  postCategory(newCategory: category_indicator) {
    this.uriConnection = `${environment.kpi_api.base_url}/apps/${newCategory.app_id}/kpi-categories`;
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    return this.http.post<category_indicator>(`${this.uriConnection}?key=${this.apiKey}`, newCategory, { headers });
  }
  putCategory(category: category_indicator) {
    this.uriConnection = `${environment.kpi_api.base_url}/apps/${category.app_id}/kpi-categories`;
    return this.http.put<category_indicator>(
      `${this.uriConnection}/${category.id}?key=${this.apiKey}`,
      category
    );
  }
}
