import { PERMISSIONS } from '../../services/permissions/permissions.service';
/* eslint-disable max-len */
import { DataSource } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Courier, Couriers } from '../../models/couriers/couriers-model';
import { OrdersCentralService } from '../../services/orders-central.service';
import { Router } from '@angular/router';
import { StorageService } from '../../services/storage.service';
import {MatSnackBar} from '@angular/material/snack-bar';

export class CouriersDataSource extends DataSource<Courier> {
  observer: Observer<Courier[]>;

  refreshData(data: Courier[]) {
    if (this.observer) {
      this.observer.next(data);
    }
  }

  connect(): Observable<Courier[]> {
    return Observable.create((observer: Observer<Courier[]>) => {
      this.observer = observer;
    });
  }

  disconnect() {
    this.observer = null;
  }
}

@Component({
  selector: 'app-couriers',
  templateUrl: './couriers.component.html',
  styleUrls: ['./couriers.component.scss']
})
export class CouriersComponent implements OnInit {
  PERMISSIONS = PERMISSIONS
  dataSource = new CouriersDataSource();
  displayedColumns = ['courier_name', 'erp_code','courier_description', 'is_disabled', 'edit'];
  loading = false;
  hoveredRow: any;
  allowedCompanyIds: string[];
  allowAllCompanyIds = false;
  data;
  constructor(
    private courierService: OrdersCentralService,
    private storageService: StorageService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) { }

  async ngOnInit() {
    await this.initAllowedIds();
    this.listCouriers();
   
  }

  async listCouriers(){
    // const simulatedData = {
    //   data: [
    //     { courier_id: '1', courier_name: 'Transportadora 1', erp_code: 'ERP001', is_disabled: false },
    //     { courier_id: '2', courier_name: 'Transportadora 2', erp_code: 'ERP002', is_disabled: true },
    //     { courier_id: '3', courier_name: 'Transportadora 3', erp_code: 'ERP003', is_disabled: false },
    //     { courier_id: '4', courier_name: 'Transportadora 4', erp_code: 'ERP004', is_disabled: true }
    //   ]
    // };
    // // Set the data from the simulated response
    // this.data = simulatedData.data;
    // console.log(this.data);
    // this.dataSource.refreshData(this.data);  // Refresh data in the DataSource
    // this.loading = false;
    await this.courierService.listCouriers().subscribe((res: Couriers) => {
      this.data = res.data;
      console.log(this.data);
      this.dataSource = this.data;
      this.loading = false;
    });
  }

  getRowBackgroundColor(row) {
    return row === this.hoveredRow ? '#F5F5F5' : 'transparent';
  }

  onCreate(){
    this.router.navigate(['pages', 'couriers', 'create']);
  }

  onEdit(item){
    this.router.navigate(['pages', 'couriers', 'edit', item.courier_id]);
  }

  initAllowedIds() {
    // Do not allow any app by default
    this.allowedCompanyIds = [];
    this.allowAllCompanyIds = false;
    const account = this.storageService.get('account');
    if (!account) {
      return;
    }
    if (account.roles.includes('super_admin')) {
      // Allow all ids
      this.allowedCompanyIds = null;
      this.allowAllCompanyIds = true;
      return;
    }
    const profile = this.storageService.get('profile');
    if (!profile) {
      return;
    }
    this.allowedCompanyIds = profile.authorized_apps || [];
  }

}

