/**
 * Gluky Users API - Test
 * Gluky\'s apps and programs definitions and of the users authorized to access them, including their roles and other authorization information. 
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: sistemas@gluky.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { CompaniesList } from '../model/companiesList';
import { Company } from '../model/company';
import { CompanyOutput } from '../model/companyOutput';
import { CreatedAnswer } from '../model/createdAnswer';
import { ErrorAnswer } from '../model/errorAnswer';
import { UpdatedAnswer } from '../model/updatedAnswer';
import {CompaniesListAllOf} from '../model/companiesListAllOf';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

    protected basePath = 'https://users-174020.appspot.com/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    /**
     * Record a new company
     * @param company 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createCompany(company: Company, observe?: 'body', reportProgress?: boolean): Observable<CreatedAnswer>;
    public createCompany(company: Company, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreatedAnswer>>;
    public createCompany(company: Company, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreatedAnswer>>;
    public createCompany(company: Company, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (company === null || company === undefined) {
            throw new Error('Required parameter company was null or undefined when calling createCompany.');
        }

        let headers = this.defaultHeaders;

        // authentication (firebase) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (g2instances) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (pointz) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CreatedAnswer>(`${this.configuration.basePath}/companies`,
            company,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Find companies
     * List companies
     * @param page_size Page size for pagination. Default: 10.
     * @param page_start_cursor Pagination cursor, generated automatically by this API, given in next and previuos page links.
     * @param page_end_cursor Pagination cursor, generated automatically by this API, given in next and previuos page links.
     * @param first_page_cursor Pagination cursor, generated automatically by this API, given in next and previuos page links.
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findCompanies(page_size?: string, page_start_cursor?: string, page_end_cursor?: string, first_page_cursor?: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<CompaniesList>;
    public findCompanies(page_size?: string, page_start_cursor?: string, page_end_cursor?: string, first_page_cursor?: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompaniesList>>;
    public findCompanies(page_size?: string, page_start_cursor?: string, page_end_cursor?: string, first_page_cursor?: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompaniesList>>;
    public findCompanies(page_size?: string, page_start_cursor?: string, page_end_cursor?: string, first_page_cursor?: string, filter?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: this.encoder});
        if (page_size !== undefined && page_size !== null) {
            queryParameters = queryParameters.set('page_size', <any>page_size);
        }
        if (page_start_cursor !== undefined && page_start_cursor !== null) {
            queryParameters = queryParameters.set('page_start_cursor', <any>page_start_cursor);
        }
        if (page_end_cursor !== undefined && page_end_cursor !== null) {
            queryParameters = queryParameters.set('page_end_cursor', <any>page_end_cursor);
        }
        if (first_page_cursor !== undefined && first_page_cursor !== null) {
            queryParameters = queryParameters.set('first_page_cursor', <any>first_page_cursor);
        }
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // authentication (firebase) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (g2instances) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (pointz) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get<CompaniesList>(`${this.configuration.basePath}/companies`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get company by ID
     * @param companyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public retrieveCompany(companyId: string, observe?: 'body', reportProgress?: boolean): Observable<CompanyOutput>;
    public retrieveCompany(companyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CompanyOutput>>;
    public retrieveCompany(companyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CompanyOutput>>;
    public retrieveCompany(companyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling retrieveCompany.');
        }

        let headers = this.defaultHeaders;

        // authentication (firebase) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (g2instances) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (pointz) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get<CompanyOutput>(`${this.configuration.basePath}/companies/${encodeURIComponent(String(companyId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a Company.
     * @param companyId 
     * @param company 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateCompany(companyId: string, company: Company, observe?: 'body', reportProgress?: boolean): Observable<UpdatedAnswer>;
    public updateCompany(companyId: string, company: Company, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdatedAnswer>>;
    public updateCompany(companyId: string, company: Company, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdatedAnswer>>;
    public updateCompany(companyId: string, company: Company, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling updateCompany.');
        }
        if (company === null || company === undefined) {
            throw new Error('Required parameter company was null or undefined when calling updateCompany.');
        }

        let headers = this.defaultHeaders;

        // authentication (firebase) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (g2instances) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (pointz) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<UpdatedAnswer>(`${this.configuration.basePath}/companies/${encodeURIComponent(String(companyId))}`,
            company,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
