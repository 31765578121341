import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AppUsersEditorOutput } from '../app-users-editor/app-users-editor.component';
import { MainSidenavService } from '../../../main-sidenav.service';
import {
  AppUsersService,
  AppUserInput,
  AppUserProfileInput,
} from '../../../users-api';

@Component({
  selector: 'app-app-users-add',
  templateUrl: './app-users-add.component.html',
  styleUrls: ['./app-users-add.component.scss'],
  providers: [AppUsersService],
})
export class AppUsersAddComponent implements OnInit {
  userEditData: AppUserInput;
  userProfile: AppUserProfileInput = {
    data: {},
  };
  loading = true;

  constructor(
    private usersApi: AppUsersService,
    private snackBar: MatSnackBar,
    public mainSidenavService: MainSidenavService,
    private router: Router
  ) {}

  ngOnInit() {
    this.userEditData = {
      app_id: '',
      username: '',
      email: '',
      phone: '',
      password: '',
      roles: [],
      programs: [],
      enabled: true,
      personal_data: {
        iddoc_type: '',
        iddoc: '',
        name: '',
        last_name: '',
      },
    };
    this.userProfile = {
      data: {},
    };
    this.loading = false;
    return false;
  }

  onEditorOK(editorData: AppUsersEditorOutput) {
    this.loading = true;
    delete editorData.user.auth_ids;
    delete editorData.user.last_auth_id;
    if (editorData.profile
       && editorData.profile.data
       && Object.keys(editorData.profile.data).length) {
      editorData.user.profile = editorData.profile.data;
    }
    this.usersApi.createAppUser(editorData.user).subscribe(
      () => {
        this.loading = false;
        this.router.navigateByUrl('/pages/app-users');
      },
      (error) => {
        this.handleEditError(error, 'Creando usuario');
      }
    );
  }

  onEditorCancel() {
    this.router.navigateByUrl('/pages/app-users');
  }

  handleEditError(error: any, logmsg: string) {
    console.error(logmsg, error);
    this.loading = false;
    try {
      const data = error.error;
      this.snackBar.open(data.message, 'OK');
    } catch (ex) {
      this.snackBar.open('Se ha presentado un error', 'OK');
    }
  }
}
