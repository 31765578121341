import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateFormatPipe } from './pipes/date-format.pipe';
import { ObjectToStringPipe } from './pipes/object-to-string.pipe';

@NgModule({
  declarations: [
    DateFormatPipe,
    ObjectToStringPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DateFormatPipe,
    ObjectToStringPipe
  ]
})
export class SharedModule { }
