import { StorageService } from './../../services/storage.service';
import { PERMISSIONS } from './../../services/permissions/permissions.service';
import {Component, OnInit, OnDestroy} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CreateImportComponent} from './components/create-import/create-import.component';
import {ImportService, ImportsFilter} from './servers/import.service';
import {AppUserOutput} from '../../users-api';
import {Subscription, timer} from 'rxjs';


@Component({
  selector: 'app-imports',
  templateUrl: './imports.component.html',
  styleUrls: ['./imports.component.scss']
})
export class ImportsComponent implements OnInit, OnDestroy {
  PERMISSIONS = PERMISSIONS;
  displayedColumns = ['info'];
  dataImports: any[];
  loading: boolean;
  hoveredRow: any;
  pageSize = 20;
  filters: ImportsFilter;
  refreshSubscription: Subscription;
  appIdFilter: string | undefined;
  statusFilter = '';
  typeFilter = '';
  userFilter: AppUserOutput | undefined;
  filterByDate = false;
  allowedAppIds: string[] | null;
  allowAllAppIds = false;


  constructor(
    public dialog: MatDialog,
    private importador: ImportService,
    private storageService: StorageService,
  ) {}

  async ngOnInit() {
    await this.importador.configFirebase();
    this.initAllowedIds();
    this.loading = true;
    this.refreshSubscription = timer(0, 60000).subscribe(async () => {
      await this.refreshData();
    });
    this.loading = false;
  }
  initAllowedIds() {
    // Do not allow any app by default
    this.allowedAppIds = [];
    this.allowAllAppIds = false;
    const account = this.storageService.get('account');
    console.log('account',account);
    
    if (!account) {
      return;
    }
    if (account.roles.includes('super_admin')) {
      // Allow all ids
      this.allowedAppIds = null;
      this.allowAllAppIds = true;
      return;
    }
    const profile = this.storageService.get('profile');
    if (!profile) {
      return;
    }
    this.allowedAppIds = profile.authorized_apps || [];
  }

  ngOnDestroy() {
    this.refreshSubscription?.unsubscribe();
  }

  openCreateImports() {
    const dialogRef = this.dialog.open(CreateImportComponent);
    dialogRef.afterClosed().subscribe(async () => {
      await this.refreshData();
    });
  }

  async pageSizeCtrlChanged() {
    this.loading = true;
    await this.refreshData();
    this.loading = false;
  }

  canFilterByAppId() {
    return [
      'bank_transactions',
      'kpis',
      'users_by_appid',
      'bonos_orders_admin',
    ].includes(this.typeFilter);
  }

  async refreshData() {
    if (!this.filters) {
      this.filters = {};
    }
    if (this.canFilterByAppId() && this.appIdFilter) {
      this.filters.appId = this.appIdFilter;
    } else {
      delete this.filters.appId;
    }
    if (this.statusFilter) {
      this.filters.status = this.statusFilter;
    } else {
      delete this.filters.status;
    }
    if (this.typeFilter) {
      this.filters.type = this.typeFilter;
    } else {
      delete this.filters.type;
    }
    if (this.userFilter) {
      this.filters.uid = this.userFilter.id;
    } else {
      delete this.filters.uid;
    }
    let pageSize = this.pageSize;
    if (!this.filterByDate) {
      delete this.filters.date;
    } else {
      pageSize *= 100;
    }
    this.loading = true;
    this.dataImports = await this.importador.readAllImports(pageSize, this.filters, this.allowedAppIds);
    this.loading = false;
  }

  selectUser($event: AppUserOutput) {
    if (!this.filters) {
      this.filters = {};
    }
    this.filters.uid = $event.id;
    this.userFilter = $event;
  }

  addFilterDate(value1: any, value2: any) {
    if (!this.filters) {
      this.filters = {};
    }
    // Los values son instancias de moment.js
    this.filters.date = {
      start: value1.hour(0).minute(0).second(0).toDate(),
      end: value2.hour(23).minute(59).second(59).toDate(),
    };
  }

  appIdFilterSelected(value: string) {
    if (this.appIdFilter === value) {
      return;
    }
    this.appIdFilter = value;
    if (!this.filters) {
      this.filters = {};
    }
    this.filters.appId = value;
  }

  clearFilters() {
    this.filters = {};
    this.appIdFilter = undefined;
    this.statusFilter = '';
    this.typeFilter = '';
    this.userFilter = undefined;
    this.filterByDate = false;
    this.refreshData();
  }
}
