import { DataSource } from '@angular/cdk/collections';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Observable, BehaviorSubject, combineLatest, of, Observer } from 'rxjs';
import { StorageService } from '../../services/storage.service';
import { RecognitionType, RecognitionTypes } from '../../models/recognitions/recognitions-model';
import { RecognitionsService } from '../../services/recognitions.service';
import { Router } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import moment from 'moment';

export class RecognitionsDataSource extends DataSource<RecognitionType> {
  observer: Observer<RecognitionType[]>;

  refreshData(data: RecognitionType[]) {
    if (this.observer) {
      this.observer.next(data);
    }
  }

  connect(): Observable<RecognitionType[]> {
    return Observable.create((observer: Observer<RecognitionType[]>) => {
      this.observer = observer;
    });
  }

  disconnect() {
    this.observer = null;
  }
}

@Component({
  selector: 'app-recognitions',
  templateUrl: './recognitions.component.html',
  styleUrls: ['./recognitions.component.scss']
})
export class RecognitionsComponent implements OnInit {

  dataSource = new RecognitionsDataSource();
  displayedColumns = ['id'];
  pageSize = 100;
  loading = false;
  hoveredRow: any;
  pagerSteps = [100, 200, 500, 1000];
  prevCursor: string;
  nextCursor: string;
  firstCursor: string;
  requiredFields: boolean = false;
  resultsFound = true;
  allowedAppIds: string[];
  allowAllAppIds = false;
  foundNumber: number;

  appIdFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  programIdFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  idFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  idOptions: Observable<string[]>;
  currentAppId: string;
  currentProgramId: string;
  idControl = new UntypedFormControl('');
  startDate;
  endDate;
  resultsNumberLimit = 100;
  data;
  dataSearch;
  dataFilter;
  
  constructor(
    private recognitionsService: RecognitionsService,
    private storageService: StorageService,
    private router: Router
  ) { }

  async ngOnInit() {
    await this.initAllowedIds();
  }

  async getRecognitions(appId?, programId?, startDate?, endDate?, name?){
    if(appId){
      this.requiredFields = true;
    } else {
      this.requiredFields = false;
      this.data = [];
      this.dataSource.refreshData(this.data);
      return;
    }
    await this.recognitionsService.getRecognitionTypes().subscribe((res: RecognitionTypes) => {
      this.data = res.data;
      this.data = appId ? this.data.filter(x => x.appId == appId) : this.data;
      this.data = programId ? this.data.filter(x => x.programs.includes(programId)) : this.data;
      this.data = startDate ? this.data.filter(x => moment(x.createdAt).toDate() >= moment(startDate).toDate()) : this.data;
      this.data = endDate ? this.data.filter(x => moment(x.createdAt).toDate() <= moment(endDate).add(23,'hours').add(59,'minutes').toDate()) : this.data;
      this.data = name ? this.data.filter(x => x.id == name) : this.data;
      this.data.sort((a, b) => Number(new Date(b.createdAt)) - Number(new Date(a.createdAt)))
      this.dataSource.refreshData(this.data);
      this.resultsFound = this.data.length > 0 ? true : false;
      if(!name){
        this.dataSearch = this.data;
        this.dataFilter = this.data;
      }
    });
  }

  onAppSelected(appId: string) {
    this.appIdFilter$.next(appId);
    this.currentAppId = appId;
    this.currentProgramId = null;
    this.getRecognitions(appId, this.currentProgramId, this.startDate, this.endDate);
  }

  onProgramSelected(programId: string) {
    this.programIdFilter$.next(programId);
    this.currentProgramId = programId;
    this.getRecognitions(this.currentAppId, programId, this.startDate, this.endDate);
  }

  onIdOptionSelected(option){
    const name = option.option.value.translations[0].texts[0].name;
    const id = option.option.value.id;
    this.idControl.setValue(name);
    this.getRecognitions(this.currentAppId, this.currentProgramId, this.startDate, this.endDate, id);
  }

  resetApp() {
    this.currentAppId = null;
    this.dataSearch = [];
    this.dataFilter = [];
    this.idControl.setValue('');
    this.getRecognitions(this.currentAppId, this.currentProgramId, this.startDate, this.endDate);
  }

  resetProgram() {
    this.currentProgramId = null;
  }

  getRowBackgroundColor(row) {
    return row === this.hoveredRow ? '#F5F5F5' : 'transparent';
  }

  goToIcon(url){
    window.open(url, '_blank');
    return false;
  }

  initAllowedIds() {
    // Do not allow any app by default
    this.allowedAppIds = [];
    this.allowAllAppIds = false;
    const account = this.storageService.get('account');
    if (!account) {
      return;
    }
    if (account.roles.includes('super_admin')) {
      // Allow all ids
      this.allowedAppIds = null;
      this.allowAllAppIds = true;
      return;
    }
    const profile = this.storageService.get('profile');
    if (!profile) {
      return;
    }
    this.allowedAppIds = profile.authorized_apps || [];
  }

  addEvent(typeDate, event: MatDatepickerInputEvent<Date>) {
    if(typeDate == 'start'){
      this.startDate = event.value;
    } else {
      this.endDate = event.value;
    }
    this.getRecognitions(this.currentAppId, this.currentProgramId, this.startDate, this.endDate);
  }

  clearData(value){
    const val = String(value.target.value).toLowerCase();
    if(!val){
      this.getRecognitions(this.currentAppId, this.currentProgramId, this.startDate, this.endDate);
    } else {
      this.dataFilter = this.dataSearch.filter(x => String(x.translations[0].texts[0].name).toLowerCase().includes(val))
    }
  }

  onCreate(){
    this.router.navigate(['pages', 'recognitions', 'create']);
  }

  onEdit(item){
    this.router.navigate(['pages', 'recognitions', 'edit', item.id]);
  }

}
