// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.header mat-form-field {
  width: 50%;
}

.btn-edit {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/pointz/sales-points-list/sales-points-list.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,2BAAA;EACA,uBAAA;AACF;AACE;EACE,UAAA;AACJ;;AAEA;EACE,eAAA;AACF","sourcesContent":[".header {\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-start;\n\n  mat-form-field {\n    width: 50%;\n  }\n}\n.btn-edit {\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
