import { PERMISSIONS } from './../../../../services/permissions/permissions.service';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import {forkJoin} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { PointsService } from '../../../../services/points.service';
import { AppUsersService, AppUserOutput } from '../../../../users-api';
import {
  AccountOutput,
  PointsTransaction,
  AccountTypeOutput,
  CurrencyAmount,
} from '../../../../models/points/models';
import {HttpErrorResponse} from '@angular/common/http';

@Component({
  selector: 'app-account-detail',
  templateUrl: './account-detail.component.html',
  styleUrls: ['./account-detail.component.scss']
})
export class AccountDetailComponent implements OnInit {
  @ViewChild('revertDialog', {static: true}) revertDialogTemplate: TemplateRef<any>;
  @ViewChild('adjustDialog', {static: true}) adjustDialogTemplate: TemplateRef<any>;
  PERMISSIONS = PERMISSIONS;
  loading = false;
  accountId: string;
  pointsAccount: AccountOutput;
  transactions: PointsTransaction[];
  pointsAccountType: AccountTypeOutput;
  user: AppUserOutput;
  reverseDescription: string;
  adjustPoints: number;
  adjustDescription: string;

  constructor(
    private pointsService: PointsService,
    private usersService: AppUsersService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) { }

  ngOnInit() {
    this.loading = true;
    this.route.paramMap.pipe(
      switchMap((params: ParamMap) => {
        this.accountId = params.get('id');
        const account = this.pointsService.getAccountById(this.accountId);
        const transactionsList = this.pointsService.listTransactionsByAccountId(this.accountId);
        return forkJoin({account, transactionsList});
      }),
      switchMap((data) => {
        this.pointsAccount = data.account;
        this.transactions = data.transactionsList.data;
        const accountType = this.pointsService.getAccountTypeById(data.account.type);
        const user = this.usersService.retrieveAppUser(data.account.owner.id);
        return forkJoin({accountType, user});
      }),
    ).subscribe(
      (data) => {
        this.loading = false;
        this.pointsAccountType = data.accountType;
        this.user = data.user;
      },
      (error: HttpErrorResponse) => {
        this.loading = false;
        console.error('AccountDetailComponent: getting account data from API', error);
        Swal.fire({
          icon: 'error',
          title: 'Descargando cuenta',
          text: 'No se pudo descargar la cuenta',
        });
      }
    );
  }

  updateTransactionsList() {
    this.loading = true;
    const account = this.pointsService.getAccountById(this.accountId);
    const pointsHistory = this.pointsService.listTransactionsByAccountId(this.accountId);
    forkJoin({account, pointsHistory}).subscribe(
      (ans) => {
        this.pointsAccount = ans.account;
        this.transactions = ans.pointsHistory.data;
        this.loading = false;
      },
      (error) => {
        this.loading = false;
        const title = 'Error actualizando transacciones';
        console.log(title, error);
        Swal.fire({
          title,
          icon: 'error',
          text: error.message || JSON.stringify(error)
        });
      }
    );
  }

  getBalanceMoneyValues() {
    if (!this.pointsAccount
        || !this.pointsAccount.balance
      || !this.pointsAccount.balance.money) {
      return [];
    }
    return Object.values(this.pointsAccount.balance.money);
  }

  formatMoneyAmount(currencyAmount: CurrencyAmount) {
    const floatVal = Number(currencyAmount.amount / 100);
    const value = floatVal.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return `${currencyAmount.currencyCode} ${value}`;
  }

  transactionTypeLabel(type: string) {
    const typeLabels = {
      credit: 'Crédito',
      exchange: 'Canje',
      adjustment: 'Ajuste',
      expiry: 'Vencimiento',
    };
    return typeLabels[type] || 'Desconocido';
  }

  formatTimestamp(timestamp: string) {
    return new Date(timestamp).toLocaleString();
  }

  openReverseTxnDialog(t: PointsTransaction) {
    this.dialog.open(this.revertDialogTemplate, { data: { txn: t } });
  }

  reverseTransaction(t: PointsTransaction) {
    this.loading = true;
    this.pointsService.reverseTransaction(t.accountId, t.id, {
      description: this.reverseDescription,
    }).subscribe(
      () => {
        this.updateTransactionsList();
      },
      (error: HttpErrorResponse) => {
        this.loading = false;
        const title = 'Error revirtiendo transacción';
        console.error(title, error);
        Swal.fire({
          title,
          icon: 'error',
          text: error.message || JSON.stringify(error),
        });
      }
    );
  }

  openAdjustTransactionDialog() {
    this.dialog.open(this.adjustDialogTemplate);
  }

  createAdjustmentTransaction() {
    this.pointsService.addAdjustment(this.accountId, {
      points: this.adjustPoints,
      description: this.adjustDescription,
    }).subscribe(
      () => {
        this.updateTransactionsList();
      },
      (error: HttpErrorResponse) => {
        this.loading = false;
        const title = 'Error creando ajuste';
        console.error(title, error);
        Swal.fire({
          title,
          icon: 'error',
          text: error.message || JSON.stringify(error),
        });
      }
    );
  }

}
