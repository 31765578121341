import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {CompaniesService, CompanyOutput} from '../users-api';

@Injectable({
  providedIn: 'root'
})
export class CompaniesUtilService {
  private readonly companyIdDocKey = 'CompaniesUtilService:idDoc';

  constructor(
    private companiesService: CompaniesService,
    private storageService: StorageService,
  ) { }

  getCachedCompanies(): CompanyOutput[] {
    const cache = this.storageService.get('CompaniesUtilService:companies');
    if (cache && cache.expiryTimestamp > Date.now()) {
      return cache.data;
    }
    return null;
  }

  setCachedCompanies(companies: CompanyOutput[]): void {
    const cache = {
      expiryTimestamp: Date.now() + 5 * 60000,
      data: companies,
    };
    this.storageService.set('CompaniesUtilService:companies', cache);
  }

  async getFromCompanyIds(companyIds: string[]): Promise<CompanyOutput[]> {
    try {
      const proms = companyIds.map(id => this.companiesService.retrieveCompany(id).toPromise());
      const companies = Promise.all(proms);
      return companies;
    } catch (error) {
      const emsg = error.error ? JSON.stringify(error.error) : error.message;
      throw new Error(emsg);
    }
  }

  async getCompanies(useCache = true): Promise<CompanyOutput[]> {
    if (useCache) {
      const cachedCompanies = this.getCachedCompanies();
      if (cachedCompanies) {
        return cachedCompanies;
      }
    }
    try {
      const list = await this.companiesService.findCompanies().toPromise();
      this.setCachedCompanies(list.data);
      return list.data;
    } catch (error) {
      const emsg = error.error ? JSON.stringify(error.error) : error.message;
      throw new Error(emsg);
    }
  }

  setCompanyIdDoc(idDoc: string): void {
    this.storageService.set(this.companyIdDocKey, idDoc);
  }

  getCompanyIdDoc(): string {
    return this.storageService.get(this.companyIdDocKey);
  }

}
