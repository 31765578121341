import { Injectable, Inject } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {StorageService} from '../../services/storage.service';
import {ChallengeStatusType} from '../../models/answers/answers-models';
@Injectable({
  providedIn: 'root'
})
export class ChallengesService {

  constructor(
    @Inject('ChallengesFirestore') private challengesFire: AngularFirestore,
    private storageService: StorageService,
  ) { }

  updateChallengeStatus(id: string, newStatus: ChallengeStatusType) {
    const account = this.storageService.get('account');
    if (!account) {
      throw new Error('No se encuentran los datos del usuario actual.');
    }
    const db = this.challengesFire.firestore;
    const docRef = db.doc(`ChallengesAnswers/${id}`);
    return docRef.update({
      status: newStatus,
      updatedBy: {
        id: account.id,
        appId: account.app_id,
        username: account.username,
      },
      updatedAt: new Date(),
    });
  }

  fieldTextValue(val: any) {
    const txtVal = `${val}`;
    const maxlen = 20;
    const slicedVal = txtVal.slice(0, maxlen) + (txtVal.length > maxlen ? '...' : '');
    return slicedVal;
  }
}
