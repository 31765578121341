import { Component, OnInit } from '@angular/core';
import { PointsService } from '../../../../services/points.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import {
  Account,
  AccountType,
  CurrencyAmount,
  Grantor,
} from '../../../../models/points/models';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { currenciesForSelect } from '../../../../data/currency-codes';
import { AppsUtilService } from '../../../../services/apps-util.service'

@Component({
  selector: 'app-type-accounts-edit',
  templateUrl: './type-accounts-edit.component.html',
  styleUrls: ['./type-accounts-edit.component.scss'],
})
export class TypeAccountsEditComponent implements OnInit {
  typePage = 'create';
  loading = false;
  accountTypeForm: UntypedFormGroup;
  typeAccount: AccountType;
  account: Account;
  filteredOptions: Observable<any>;
  currencies = currenciesForSelect;
  selectedAppId = '';
  apps: any[] = [];

  constructor(
    public pointsService: PointsService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private appsUtilService: AppsUtilService,
  ) {}

  async ngOnInit() {
    this.accountTypeForm = this.formBuilder.group({
      appId: ['', [Validators.required]],
      name: ['', [Validators.required]],
      id: ['', [Validators.required]],
      pointNameSingular: ['', [Validators.required]],
      pointNamePlural: ['', [Validators.required]],
      pointValues: this.formBuilder.array([]),
      grantors: this.formBuilder.array([]),
    });
    this.checkTypePage();
    this.apps = await this.appsUtilService.getApps();
  }

  navigateToParent() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  cancel() {
    this.navigateToParent();
  }

  save() {
    const reOrderData = this.reOrderData();
    if (this.typePage === 'edit') {
      this.editAccount(reOrderData);
    }
    this.pointsService.createAccountType(reOrderData).subscribe(
      () => {
        Swal.fire({
          icon: 'success',
          title: 'Nuevo tipo de cuenta creada',
        }).then(() => {
          this.navigateToParent();
        });
      },
      (err) => {
        console.error(err);
        Swal.fire({
          icon: 'error',
          title: 'Parece que hay un error',
          text: 'Puede ser un error de red. Por favor revisa los datos e intenta de nuevo.'
        });
      }
    );
  }

  editAccount(data: AccountType) {
    this.pointsService.editAccountType(data).subscribe(
      () => {
        Swal.fire({
          icon: 'success',
          title: 'Cuenta editada',
        }).then(() => {
          Swal.close();
          this.loading = true;
          this.router.navigate(['/pages/points/accounts-type']);
        });
      },
      (err) => {
        console.error(err);
        Swal.fire({
          icon: 'error',
          title: 'Parece que hay un error',
        });
      }
    );
  }

  newPointValue() {
    return this.formBuilder.group({
      amount: ['', [Validators.required, Validators.min(0.01)]],
      currencyCode: ['', [Validators.required]],
    });
  }

  getPointValuesForms() {
    return this.accountTypeForm.get('pointValues') as UntypedFormArray;
  }

  addPointValuesForm() {
    this.getPointValuesForms().push(this.newPointValue());
  }

  deletePointValuesForm(formIndex: number) {
    this.getPointValuesForms().removeAt(formIndex);
  }

  newGrantor() {
    return this.formBuilder.group({
      taxId: ['', [Validators.required]],
      name: ['', [Validators.required]],
    });
  }

  getGrantorsForms() {
    return this.accountTypeForm.get('grantors') as UntypedFormArray;
  }

  addGrantorForm() {
    this.getGrantorsForms().push(this.newGrantor());
  }

  deleteGrantorForm(formIndex: number) {
    this.getGrantorsForms().removeAt(formIndex);
  }

  reOrderData() {
    this.accountTypeForm.controls.appId.enable();
    const pointValue = this.accountTypeForm.value.pointValues.reduce(
      (acum: { [key: string]: CurrencyAmount }, pv: CurrencyAmount) => {
        // Convert amount to cents
        const converted = { ...pv };
        converted.amount = Math.round(pv.amount * 100);
        acum[pv.currencyCode] = converted;
        return acum;
      },
      {}
    );

    const grantors = this.accountTypeForm.value.grantors.reduce(
      (acum: {[key: string]: Grantor}, grantor: Grantor) => {
        acum[grantor.taxId] = grantor;
        return acum;
      },
      {}
    );

    return {
      grantors,
      appId: this.accountTypeForm.value.appId,
      id: this.accountTypeForm.value.id,
      name: this.accountTypeForm.value.name,
      pointsConfig: {
        pointValue,
        pointName: {
          singular: this.accountTypeForm.value.pointNameSingular,
          plural: this.accountTypeForm.value.pointNamePlural,
        },
      },
    };
  }

  generateId(event: string) {
    if (this.typePage === 'create') {
      const newId: string = event.replace(/[^a-zA-Z0-9/]+/g, '_');
      this.accountTypeForm.controls.id.setValue(newId.toLowerCase());
    }
  }

  checkTypePage() {
    this.route.params.subscribe((params: any) => {
      if (params.id) {
        this.loadTypeById(params.id);
        this.loading = false;
        this.typePage = 'edit';
        return;
      }
      this.loading = false;
      this.typePage = 'create';
      this.accountTypeForm.valueChanges.subscribe((value) => {
        console.log('valor', value);
        console.log('formulario', this.accountTypeForm);
      })
      return;
    });
  }

  loadTypeById(id: string) {
    this.pointsService.getAccountTypeById(id).subscribe((res: AccountType) => {
      this.typeAccount = { ...res };
      this.accountTypeForm.controls.id.setValue(this.typeAccount.id);
      this.accountTypeForm.controls.name.setValue(this.typeAccount.name);
      console.log('appID', ...this.typeAccount.appId);
      this.accountTypeForm.controls.appId.setValue(this.typeAccount.appId);
      this.accountTypeForm.controls.appId.disable();

      this.accountTypeForm.controls.pointNameSingular.setValue(
        this.typeAccount.pointsConfig.pointName.singular
      );
      this.accountTypeForm.controls.pointNamePlural.setValue(
        this.typeAccount.pointsConfig.pointName.plural
      );
      const objPointValue = this.typeAccount.pointsConfig.pointValue;
      Object.values(objPointValue).forEach((pv) => {
        const visualizeValue = { ...pv };
        // amount comes in cents
        visualizeValue.amount /= 100;
        this.getPointValuesForms().push(
          this.formBuilder.group(visualizeValue)
        );
      });
      Object.values(res.grantors).forEach(grantor => {
        this.getGrantorsForms().push(this.formBuilder.group(grantor));
      });
    });
  }

  getName() {
    return this.accountTypeForm.value.name;
  }

  getCurrencies() {

  }

  async onAppSelected(appId: string): Promise<void> {
    this.selectedAppId = appId;
    this.accountTypeForm.controls.appId.setValue(appId)
  }
}
