import { CloudinaryService, PLUGINS_CLD } from './../../../../services/cloudinary.service';
import { CategoryService } from './../../../../services/categories.service';
import { environment } from './../../../../../environments/environment';
import { Category, CategoryRoot } from './../../../../models/models';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {
  loader = false;
  categories_filtered: Category[] = [];
  categories: Category[] = [];
  hasError = false
  publicId: string = environment.public_id_cloudinary;
  plugins = PLUGINS_CLD
  constructor(
    private categoryServices: CategoryService,
    private router: Router,
    private cloudinaryService: CloudinaryService,
  ) {}
  ngOnInit() {
    this.loader = true;
    this.getCategories();
  }

  cldImg(id, versionImg?){

    console.log('id', id , '- version' , versionImg);
    
   const  img = this.cloudinaryService.getCloudinaryImage(this.publicId,id,versionImg)
  return img
  } 

  getCategories() {
    this.loader = true;
    this.categoryServices.getCategories().subscribe((res: CategoryRoot) => {
      this.categories_filtered = res.data.map((element) => {
        element.img = this.cloudinaryService.getCloudinaryImage(this.publicId,element.imageId,element.imageVersion)
        return element
      })
      this.categories = JSON.parse(JSON.stringify(res.data)).map((element) => {
        element.img = this.cloudinaryService.getCloudinaryImage(this.publicId,element.imageId,element.imageVersion)
        return element
      })
      this.loader = false;
    },err => {
      this.loader = false;
      this.hasError = true;
      console.error('getCategories error', err)
    });
  }
  searchCategory(filterValue: string) {
    if (filterValue) {
      this.categories_filtered = this.categories.filter((element) => {
        return element.name.toLowerCase().startsWith(filterValue.toLowerCase());
      })

    } else {
      this.categories_filtered = JSON.parse(JSON.stringify(this.categories)).map((element) => {
        element.img = this.cloudinaryService.getCloudinaryImage(this.publicId,element.imageId,element.imageVersion)
        return element
      })
    }
  }

  redirect() {
    this.router.navigate(["pages/allies/category/create"]);
  }
}
