import { ProgramsList } from './../../../users-api/model/programsList';
import { ProgramsService } from './../../../users-api/api/programs.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { RedemptionRule } from './../../../models/models';
import { LockRedemptionService } from './../../../services/lock-redemption.service';
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Observable } from 'rxjs-compat';
import { UntypedFormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { map, startWith } from 'rxjs/operators';
import Swal from 'sweetalert2';

const CODES_ERROR  = {
  REDEMPTION_RULE_DOES_NOT_EXISTS: {
    code: 'REDEMPTION_RULE_DOES_NOT_EXISTS',
    message: 'La regla de redencion no existe'
  }
};

const RULE_TYPES = {
  attribute: 'attribute',
};

const RULE_TYPES_OPTIONS = [
  'attribute',
];

const RULE_TYPES_LABELS = {
  attribute: 'Candado por Atributo',
};

@Component({
  selector: 'app-lock-redemption',
  templateUrl: './lock-redemption.component.html',
  styleUrls: ['./lock-redemption.component.scss']
})
export class LockRedemptionComponent implements OnInit, OnDestroy {
  @Input('app_id') app_id;
  @Input('fields')
  set fields(value) {
    if (value) {
      this.ProfileFields = JSON.parse(JSON.stringify(value));
      this.setDataValues();
    }
  }

  ConfirmationClick$: Subject<boolean | null> = new Subject();
  isCreate = false;

  ProfileFields: any[];

  Rules: RedemptionRule = {
    enabled: true,
    rules: [],
  };
  GetRules$: Subscription;
  hasError = false;
  Error = '';

  uriSeparatorKeyCodes = [ENTER, COMMA];
  programsOptions: string[] = [];
  filteredProgramsOptions: Observable<string[]>;
  programIdsByAppMap = new Map<string, string[]>();
  programInputControl = new UntypedFormControl();
  AttributeInputControl = new UntypedFormControl();
  ruleTypesOptions = RULE_TYPES_OPTIONS;
  ruleTypesLabels = RULE_TYPES_LABELS;
  ruleTypes = RULE_TYPES;

  filteredAttributesOptions;

  valuesSelectParameter = [];

  constructor(private lockRedemptionService: LockRedemptionService, private programsApi: ProgramsService) { }

  ngOnInit() {
    this.GetRules$ = this
      .lockRedemptionService
      .getRedemptionRules(this.app_id)
      .subscribe((data) => {
        if (data) {
          this.Rules = data;
          if (!Object.keys(this.Rules).includes('rules')) {
            this.Rules.rules = [];
          }
          this.Rules.rules.forEach((rule) => {
            const oldAttrParams = (rule as any).parameter;
            if (oldAttrParams) {
              rule.attributeRuleParameters = {...oldAttrParams};
              delete (rule as any).parameter;
            }
            rule.parameter_values = [];
            rule.attribute_values = [];
          });
          this.setDataValues();
        }
        this.AttributeInputControl.setValue('', { emitEvent: true });
      }, err => {
        if (Object.keys(err.error).length) {
          this.Error = CODES_ERROR[err.error.code].code ? CODES_ERROR[err.error.code].message : `Error: ${err.error.message}`;
          if (CODES_ERROR[err.error.code].code === CODES_ERROR.REDEMPTION_RULE_DOES_NOT_EXISTS.code) {
            this.isCreate = true;
          }
        } else {
          this.Error = 'Error en la consulta';
        }
        this.hasError = true;
      });
    this.loadPrograms();
    this.filteredProgramsOptions = this.programInputControl.valueChanges
      .pipe(startWith(null))
      .pipe(
        map((val) =>
          val
            ? this.filtersAutoComplete(val, 'programsOptions')
            : this.programsOptions.slice()
        )
      );

    this.ConfirmationClick$.subscribe(async (data) => {
      if (data) {
        await this.lockRedemptionService.setRedemptionRules(this.app_id, this.Rules).toPromise();
      }
    });

  }

  ngOnDestroy() {
    this.GetRules$.unsubscribe();
  }

  setDataValues() {
    if (this.Rules) {
      if ((this.ProfileFields) && this.ProfileFields.length && (this.Rules.rules) && this.Rules.rules.length) {
        this.Rules.rules.forEach((rule, index) => {
          if (rule.attributeRuleParameters && rule.attributeRuleParameters.attribute_id) {
            this.selectParameterId({ value: rule.attributeRuleParameters.attribute_id }, index);
          }
          if (rule.abac_attributes && rule.abac_attributes.length) {
            rule.abac_attributes.forEach((attr) => {
              if (attr.attribute_id) {
                this.selectAttributeId({ value: attr.attribute_id }, index);
              }
            });
          }
        });
      }
    }

  }


  async loadPrograms() {
    if (!this.app_id) {
      return;
    }
    let ids = this.programIdsByAppMap.get(this.app_id);
    if (ids) {
      this.programsOptions = ids;
      this.programInputControl.setValue('', { emitEvent: true });
      return;
    }
    this.programIdsByAppMap.set(this.app_id, []);
    const programsList: ProgramsList =
      await this.programsApi.findAppIdPrograms(this.app_id).toPromise();
    if (!programsList.data) {
      return;
    }
    ids = programsList.data.map((program) => program.id);
    this.programsOptions = ids.map((program) => program.split('/').slice(-1).pop());
    this.programIdsByAppMap.set(this.app_id, ids);
    this.programInputControl.setValue('', { emitEvent: true });

  }

  addProgram(event: MatChipInputEvent, index?) {
    const input = event.input;
    const value = event.value.trim();

    if (value && input) {
      // Check input program is known
      if (this.programsOptions.indexOf(value) < 0) {
        return;
      }
      // Init property if non existent
      if (!this.Rules.rules[index].programs) {
        this.Rules.rules[index].programs = [];
      }
      // Check value is not repeated
      if (this.Rules.rules[index].programs.indexOf(value) >= 0) {
        return;
      }
      // Add value

      this.Rules.rules[index].programs.push(value);
      input.value = '';
    }

  }

  addValuesAttribute(event: MatChipInputEvent, index?,IndexAttributeList?) {
    const input = event.input;
    const value = event.value.trim();


    if (value && input) {
      // Check input program is known


      // Init property if non existent
      if (!this.Rules.rules[index].abac_attributes[IndexAttributeList].attribute_values) {
        this.Rules.rules[index].abac_attributes[IndexAttributeList].attribute_values = [];
      }
      // Check value is not repeated
      if (this.Rules.rules[index].abac_attributes[IndexAttributeList].attribute_values.indexOf(value) >= 0) {
        return;
      }
      // Add value

      this.Rules.rules[index].abac_attributes[IndexAttributeList].attribute_values.push(value);
      input.value = '';
      input.blur();
    }

  }

  removeProgram(index, index_program) {
    if (index >= 0) {
      this.Rules.rules[index].programs.splice(index_program, 1);
    }
  }

  removeAttribute(index, index_atribute,index_atributeValue) {
    if (index >= 0) {
      this.Rules.rules[index].abac_attributes[index_atribute].attribute_values.splice(index_atributeValue, 1);
    }
  }

  filtersAutoComplete(value: string, type: string) {
    return this[type].filter(
      (option: string) =>
        option && option.toLowerCase().indexOf(value.toLowerCase()) >= 0
    );
  }

  /* removeRule(index_field) {
    if (index_field >= 0) {
      this.Rules.rules.splice(index_field, 1);
    }
  } */

  selectAttributeId(id, indexAttribute) {
    const campo_index = this.ProfileFields.findIndex((element) => element.id === id.value);

    if ((this.ProfileFields[campo_index]) && this.ProfileFields[campo_index].validation_type === 'enum') {
      this.Rules.rules[indexAttribute].attribute_values = this.ProfileFields[campo_index].enum;
    } else {
      this.Rules.rules[indexAttribute].attribute_values = [];
    }
  }




  selectParameterId(id, indexRule) {
    this.valuesSelectParameter = [];
    const campo_index = this.ProfileFields.findIndex((element) => element.id === id.value);
    if ((this.ProfileFields[campo_index]) && this.ProfileFields[campo_index].validation_type === 'enum') {
      this.Rules.rules[indexRule].parameter_values = this.ProfileFields[campo_index].enum;
    } else {
      this.Rules.rules[indexRule].parameter_values = [];
    }
  }



  newRule() {
    if (!this.Rules) {
      this.Rules = {
        enabled: true,
        rules: []
      };
    }
    if (!Object.keys(this.Rules).includes('rules')) {
      this.Rules.rules = [];
    }
    this.Rules.rules.push({
      programs: [],
      abac_attributes: [{
        attribute_id: '',
        attribute_values: []
      }],
      attributeRuleParameters: {
        attribute_id: '',
        attribute_value: ''
      },
      type: RULE_TYPES.attribute,
      parameter_values: [],
      attribute_values: []
    });

  }

  removeRule(index_field) {
    Swal.fire({
      title: 'Eliminar Regla?',
      showCancelButton: true,
      confirmButtonText: 'ELIMINAR',
      cancelButtonText: 'CANCELAR'
    }).then(result => {
      if (!result.isConfirmed) {
        return;
      }
      if (index_field >= 0) {
        this.Rules.rules.splice(index_field, 1);
      }
    });


  }

  newAbacAttribute(indexRule){
    this.Rules.rules[indexRule].abac_attributes.push({attribute_id:'',attribute_values:[]});
  }

}

