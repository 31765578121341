import { BehaviorSubject } from 'rxjs';
import { StorageService } from './../../../../services/storage.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list-exhibitions',
  templateUrl: './list-exhibitions.component.html',
  styleUrl: './list-exhibitions.component.scss'
})
export class ListExhibitionsComponent implements OnInit {
  loading = false;
pageSize = 100;
appIdFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
currentAppId: string;
allowedAppIds: string[];
allowAllAppIds = false;
requiredFields: boolean = true;
resultsFound = true;
displayedColumns = ['id'];
hoveredRow: any;

  constructor (private storageService : StorageService, private router: Router){

  }

  

  async ngOnInit() {
    await this.initAllowedIds();
   // this.getTablesExhibitions();
  }

  initAllowedIds() {
    // Do not allow any app by default
    this.allowedAppIds = [];
    this.allowAllAppIds = false;
    const account = this.storageService.get('account');
    if (!account) {
      return;
    }
    if (account.roles.includes('super_admin')) {
      // Allow all ids
      this.allowedAppIds = null;
      this.allowAllAppIds = true;
      return;
    }
    const profile = this.storageService.get('profile');
    if (!profile) {
      return;
    }
    this.allowedAppIds = profile.authorized_apps || [];

  }

  onAppSelected(appId: string) {
    this.appIdFilter$.next(appId);
    this.currentAppId = appId;
    this.getExhibitions(this.currentAppId)
  }

  getExhibitions(appId?,name?) {
    if(appId){
      this.requiredFields = true;
    } else {
      this.requiredFields = false;
    /*   this.data = [];
      this.dataSource.refreshData(this.data); */
      return;
    }
    this.loading = true;
    this.onEdit({id:'default'})
     // this.tablesService.getTables(appId).subscribe() 
  }

  resetApp() {
    this.currentAppId = null;
  }

  onEdit(item:any) {
    this.router.navigate(['pages', 'exhibition','forms',this.currentAppId,item.id]);
  }
  onCreate(){
    this.router.navigate(['pages', 'exhibition','forms', this.currentAppId]);
  }

  getRowBackgroundColor(row) {
     return row === this.hoveredRow ? '#F5F5F5' : 'transparent'; 
  }


  clearData(value){
    const val = String(value.target.value).toLowerCase();
    if(!val){
    //  this.getTablesExhibitions(this.currentAppId);
    } else {
     // this.dataFilter = this.dataSearch.filter(x => String(x.id).toLowerCase().includes(val))
    }
  }

  onIdOptionSelected(option){
    const name = option.option.value;
    /* this.dataFilter = name ? this.data.filter(x => x.id == name) : this.data;
    this.dataSource.refreshData(this.dataFilter); */
  }

}
