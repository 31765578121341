import { Component, OnInit } from "@angular/core";
import { Allie, AllieRoot } from "../../../models/models";
import { environment } from "../../../../environments/environment";
import { AlliesService } from "../../../services/allies.service";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";

@Component({
  selector: "app-allies-list",
  templateUrl: "./allies-list.component.html",
  styleUrls: ["./allies-list.component.scss"],
})
export class AlliesListComponent implements OnInit {
  loader = false;
  allies: Allie[] = [];
  dataSource;
  displayedColumns: string[] = [
    "name",
    "id",
    "g2SKU",
    "country",
    "nit",
    "edit",
  ];
  publicId: string = environment.public_id_cloudinary;
  constructor(private alliesService: AlliesService, private router: Router) {}
  ngOnInit() {
    this.loader = true;
    this.getAllies();
  }
  getAllies() {
    this.alliesService.getAllies().subscribe((res: AllieRoot) => {
      this.allies.push(...res.data);
      this.dataSource = new MatTableDataSource(this.allies);
      this.loader = false;
    });
  }
  searchAllie(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  redirect() {
    this.router.navigate(["pages/allies/create"]);
  }
  goToEdit(id) {
    this.router.navigate(["pages/allies/edit/" + id]);
  }
}
