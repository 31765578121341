/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/quotes */
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Bag, Bags, BagTransation, BagTransactions } from "../models/bags/bags-model";
import { AuthService } from "./auth.service";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class BagsService {
  private uriConnection: string = environment.bags_api.base_url + "/admin/bag";
  private conexionBagTransation: string = environment.bags_api.base_url + "/admin/bagtransaction";
  private apiKey = environment.bags_api.apiKey;

  constructor(private http: HttpClient,
    private authService: AuthService) { }

  getBags(): Observable<Bags> {
    console.log(this.apiKey);
    console.log(this.uriConnection);
    const headers = new HttpHeaders({ 'Content-Type':'application/json'});
    const uri = `${this.uriConnection}?key=${this.apiKey}`;
    console.log(uri);
    console.log(headers);
    return this.http.get<Bags>(uri, { headers });
  }
  getBag(id: string) {
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    const uri = `${this.uriConnection}/${id}?key=${this.apiKey}`;
    return this.http.get(uri,{ headers });
  }
  postBag(newBag: Bag) {
    console.log(newBag);
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    return this.http.post(`${this.uriConnection}?key=${this.apiKey}`, newBag, { headers });
  }
  putBag(bag: Bag) {
    return this.http.put(
      `${this.uriConnection}/${bag.bag_id}?key=${this.apiKey}`,
      bag
    );
  }
  postBagTransation(newBagTransation: BagTransation, bagId: string, type: string) {
    console.log(newBagTransation);
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    return this.http.post(`${this.conexionBagTransation}/${bagId}/${type}?key=${this.apiKey}`, newBagTransation, { headers });
  }
  getBagType() {
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    return this.http.get<Bags>(`${this.conexionBagTransation}/type?key=${this.apiKey}`, { headers });
  }
  getTransaction(id: string) {
    console.log(id);
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    return this.http.get<BagTransactions>(`${this.conexionBagTransation}/${id}?key=${this.apiKey}`, { headers });
  }
}
