// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `form, div#user-data {
  display: flex;
  flex-direction: column;
}

div#user-data {
  margin-bottom: 32px;
}

div#buttons button {
  margin-right: 8px;
}

mat-form-field {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/points/components/accounts-edit/accounts-edit.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;;AAEA;EACE,mBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,WAAA;AACF","sourcesContent":["form, div#user-data {\n  display: flex;\n  flex-direction: column;\n}\n\ndiv#user-data {\n  margin-bottom: 32px;\n}\n\ndiv#buttons button {\n  margin-right: 8px;\n}\n\nmat-form-field {\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
