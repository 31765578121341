import {Injectable} from '@angular/core';
import {StorageService} from './storage.service';
import {AppsService, AppOutput} from '../users-api';

@Injectable({
  providedIn: 'root'
})
export class AppsUtilService {

  constructor(
    private appsService: AppsService,
    private storageService: StorageService,
  ) { }

  getCachedApps(): AppOutput[] {
    const cache = this.storageService.get('AppsUtilService:apps');
    if (cache && cache.expiryTimestamp > Date.now()) {
      return cache.data;
    }
    return null;
  }

  setCachedApps(apps: AppOutput[]): void {
    const cache = {
      expiryTimestamp: Date.now() + 5 * 60000,
      data: apps,
    };
    this.storageService.set('AppsUtilService:apps', cache);
  }

  async getFromAppIds(appIds: string[]): Promise<AppOutput[]> {
    try {
      const proms = appIds.map(id => this.appsService.retrieveApp(id).toPromise());
      const apps = Promise.all(proms);
      return apps;
    } catch (error) {
      const emsg = error.error ? JSON.stringify(error.error) : error.message;
      throw new Error(emsg);
    }
  }

  async getApps(useCache = true): Promise<AppOutput[]> {
    if (useCache) {
      const cachedApps = this.getCachedApps();
      if (cachedApps) {
        return cachedApps;
      }
    }
    try {
      const list = await this.appsService.findApp().toPromise();
      this.setCachedApps(list.data);
      return list.data;
    } catch (error) {
      // Si el usuario no tiene permiso de ver todas las apps le da FORBIDDEN
      // en ese caso se listan solo las aplicaciones autorizadas en el perfil
      if (error.status === 403) {
        const profile = this.storageService.get('profile');
        if (profile && profile.authorized_apps && profile.authorized_apps.length) {
          const apps = await this.getFromAppIds(profile.authorized_apps);
          this.setCachedApps(apps);
          return apps;
        }
      }
      const emsg = error.error ? JSON.stringify(error.error) : error.message;
      throw new Error(emsg);
    }
  }

}
