import { Injectable } from '@angular/core';

interface TransformationStrategy {
  transform(transaction: any): any;
}

@Injectable({
  providedIn: 'root'
})
export class TransactionTransformService {
  
  private strategies: { [key: string]: TransformationStrategy } = {};

  constructor() {
    this.strategies['creditData'] = {
      transform: (tnx: any) => ({
        'Otorgante': tnx.creditData.grantor.name,
        'Fecha de expiración': tnx.creditData.expiryDatetime ? tnx.creditData.expiryDatetime : 'No expira',
        '¿Expirada?': tnx.creditData.expired ? 'Sí' : 'No',
        '¿Agotado?': tnx.creditData.depleted ? 'Sí' : 'No',
      })
    };

    this.strategies['refundData'] = {
      transform: (tnx: any) => ({
        'Nombre de producto': tnx.refundData.productName,
        'SKU del producto': tnx.refundData.productSku,
        'ID de entrega': tnx.refundData.deliveryId,
        'ID de orden': tnx.refundData.orderId,
      })
    };

    this.strategies['exchangeData'] = {
      transform: (tnx: any) => ({
        'ID comercio': tnx.exchangeData.commerce.id,
        'Tipo comercio': tnx.exchangeData.commerce.type,
        'Precio': tnx.exchangeData.value.amount,
        'Código de moneda': tnx.exchangeData.value.currencyCode,
        'Producto': tnx.exchangeData.productName,
        'ID de entrega': tnx.exchangeData.deliveryId
      }),
    }
  }

  public addDisplayFields(transactions: Array<object>) {
    transactions.forEach((tnx: any) => {
      Object.keys(this.strategies).forEach(key => {
        if (tnx[key]) {
          tnx.displayFields = this.strategies[key].transform(tnx);
        }
      });
    });

    return transactions
  }
}
