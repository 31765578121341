import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-cms-strapi',
  templateUrl: './cms-strapi.component.html',
  styleUrls: ['./cms-strapi.component.scss']
})
export class CmsStrapiComponent implements OnInit {

  baseUrl = environment.strapi_cms.base_url

  constructor() { }

  ngOnInit() {
    this.goToUrl();
  }

  goToUrl() {
    window.location.href = this.baseUrl;
  }

}
