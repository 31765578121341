/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-unused-vars */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Bag, BagTransation, TYPES_OPTIONS } from '../../../models/bags/bags-model';
import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { BagsService } from '../../../services/bags.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import moment from 'moment';
import {StorageService} from '../../../services/storage.service';
import {switchMap} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-bag-transation-form',
  templateUrl: './bag-transation-form.component.html',
  styleUrls: ['./bag-transation-form.component.scss']
})
export class BagTransationFormComponent implements OnInit {

  bagTransationForm: FormGroup;
  mode = 'create';
  isSending = false;
  bagId = '';
  typesControl = new FormControl('', Validators.required);
  typesOptions: {
    label: string;
    value: string;
  }[];
  name = 'Crear Transaccion de Bolsa';
  endDateControl = new FormControl();
  bag: Bag;

  constructor(
    private formBuilder: FormBuilder,
    private bagsService: BagsService,
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private snackBar: MatSnackBar,
  ) {
    this.initConstructor();
  }

  ngOnInit() {
    this.typesOptions = [{
      label: 'Seleccionar',
      value: ''
    }];
    this.typesOptions.push(...TYPES_OPTIONS);
    this.typesControl.markAsTouched();
    this.route.paramMap.pipe(switchMap((params: ParamMap) =>
      this.bagsService.getBag(params.get('bagId'))
    )).subscribe((bag: Bag) => {
      this.bag = bag;
    }, error  => {
      console.error('Reading bag', error);
      this.snackBar.open('Error reading bag: ' + error);
    });

  }

  initConstructor() {
    this.bagTransationForm = this.formBuilder.group({
      typeTransBag: [
        this.typesOptions,
        Validators.compose([
          Validators.required,
        ]),
      ],
      observation: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
        ]),
      ],
      monto: [
        0,
        Validators.compose([
          Validators.required,
          Validators.min(1),
        ])
      ],
      invoiceId: '',
    });
  }

  cancelClicked() {
    this.router.navigateByUrl('/pages/bags');
  }

  async OKClicked() {
    const account = this.storageService.get('account');
    if(this.isSending) {
      return;
    }
    const type = this.bagTransationForm.controls.typeTransBag.value;
    const fecha = this.endDateControl.value? new Date(this.endDateControl.value).toISOString(): '';
    const factura = this.bagTransationForm.controls.invoiceId.value? this.bagTransationForm.controls.invoiceId.value: '';

    console.log(type);
    this.isSending = true;
    const body = {
      'observations':  this.bagTransationForm.controls.observation.value,
      'value':  this.bagTransationForm.controls.monto.value,
      'invoice_id': factura,
      'invoice_date': fecha,
      'user_process': account.username,
    };
    console.log(body);

    if(this.mode === 'create'){
      this.bagsService.postBagTransation(body,this.bag.bag_id,type).subscribe((res: any) => {
        if(res.code === 'INSERT_BAGTRANSACTION'){
          this.isSending = false;
          Swal.fire({
            icon: 'success',
            title: res.message,
            confirmButtonColor: '#ff9800',
          }).then(() => {
            Swal.close();
            this.router.navigateByUrl('/pages/bags');
          });
        } else {
          console.error('Error in createTransaccionBolsa', res);
          const data = res;
          Swal.fire({
            icon: 'warning',
            title: data.message,
            confirmButtonColor: '#ff9800',
          });
          return;
        }
      },
      (err) => {
        console.error(err);
        Swal.fire({
          icon: 'error',
          title: 'Error guardando',
          text: 'Por favor intente de nuevo'
        });
      });
    }

  }
}
