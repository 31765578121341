// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.account-field {
  margin-bottom: 16px;
}
.account-field .field-label {
  font-weight: bold;
}
.account-field .field-value {
  overflow-wrap: break-word;
  word-break: break-all;
}

.error-field .field-label {
  color: #ff0000;
}

.cursor-pointer {
  cursor: pointer;
}

.errors-display {
  width: 100%;
  max-height: 200px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/imports/imports.component.scss"],"names":[],"mappings":"AAAA;EAUE,mBAAA;AARF;AADE;EACE,iBAAA;AAGJ;AAAE;EACE,yBAAA;EACA,qBAAA;AAEJ;;AAIA;EACE,cAAA;AADF;;AAIA;EACE,eAAA;AADF;;AAIA;EACE,WAAA;EACA,iBAAA;AADF","sourcesContent":[".account-field {\n  .field-label {\n    font-weight: bold;\n  }\n\n  .field-value {\n    overflow-wrap: break-word;\n    word-break: break-all;\n  }\n\n  margin-bottom: 16px;\n}\n\n.error-field .field-label {\n  color: #ff0000;\n}\n\n.cursor-pointer{\n  cursor: pointer;\n}\n\n.errors-display {\n  width: 100%;\n  max-height: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
