import { PERMISSIONS } from './../../services/permissions/permissions.service';
/* eslint-disable max-len */
import { DataSource } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Bag, Bags } from '../../models/bags/bags-model';
import { BagsService } from '../../services/bags.service';
import { Router } from '@angular/router';
import { StorageService } from '../../services/storage.service';
import {MatSnackBar} from '@angular/material/snack-bar';

export class BagsDataSource extends DataSource<Bag> {
  observer: Observer<Bag[]>;

  refreshData(data: Bag[]) {
    if (this.observer) {
      this.observer.next(data);
    }
  }

  connect(): Observable<Bag[]> {
    return Observable.create((observer: Observer<Bag[]>) => {
      this.observer = observer;
    });
  }

  disconnect() {
    this.observer = null;
  }
}

@Component({
  selector: 'app-bags',
  templateUrl: './bags.component.html',
  styleUrls: ['./bags.component.scss']
})
export class BagsComponent implements OnInit {
  PERMISSIONS = PERMISSIONS
  dataSource = new BagsDataSource();
  displayedColumns = ['bag_name','documents','bag_cutDate','bag_state','bag_create_ts','bag_modify_ts','bag_currency','bag_balance','edit'];
  loading = false;
  hoveredRow: any;
  allowedCompanyIds: string[];
  allowAllCompanyIds = false;
  data;
  constructor(
    private bagsService: BagsService,
    private storageService: StorageService,
    private router: Router,
    private snackBar: MatSnackBar,
  ) { }

  async ngOnInit() {
    await this.initAllowedIds();
    this.getBags();
  }

  async getBags(){
    await this.bagsService.getBags().subscribe((res: Bags) => {
      this.data = res.data;
      console.log(this.data);
      this.dataSource = this.data;
      this.loading = false;
    });
  }

  getRowBackgroundColor(row) {
    return row === this.hoveredRow ? '#F5F5F5' : 'transparent';
  }

  onCreate(){
    this.router.navigate(['pages', 'bags', 'create']);
  }

  onEdit(item){
    this.router.navigate(['pages', 'bags', 'edit', item.bag_id]);
  }

  onCreateTransation(item){
    this.router.navigate(['pages', 'bagtransaction', 'addTransaction', item.bag_id]);
  }

  onListTransation(item){
    this.router.navigate(['pages', 'bagtransaction', item.bag_id]);
  }

  initAllowedIds() {
    // Do not allow any app by default
    this.allowedCompanyIds = [];
    this.allowAllCompanyIds = false;
    const account = this.storageService.get('account');
    if (!account) {
      return;
    }
    if (account.roles.includes('super_admin')) {
      // Allow all ids
      this.allowedCompanyIds = null;
      this.allowAllCompanyIds = true;
      return;
    }
    const profile = this.storageService.get('profile');
    if (!profile) {
      return;
    }
    this.allowedCompanyIds = profile.authorized_apps || [];
  }

}

