import {  PermissionsService } from './../services/permissions/permissions.service';
import { inject,  } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import Swal from 'sweetalert2';

export const permissionsGuard: CanActivateFn = async (route, state) => {

  const permissionsService = inject(PermissionsService);
  const router = inject(Router);
  
  const isSuperAdmin = await permissionsService.isSuperAdmin()
  if (isSuperAdmin) return true;
  const permissionsInRoute:string[] =  route.data.permissionValidate
  // PERMISSIONS_ROUTES[state.url];
  if (permissionsInRoute?.length) {
    const hasAnyPermission = await permissionsService.hasAnyPermission(permissionsInRoute);
    if (hasAnyPermission) return true;
    
  }
  
  Swal.fire('', 'No tienes permiso para acceder a esta página', 'error');
  router.navigate(['/pages/home'],{
    replaceUrl: true
  });
  
  
  return false;
};
