import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionsService } from '../services/permissions/permissions.service';

@Directive({
  selector: '[hasPermissions]',
  standalone: true
})
export class HasPermissionsDirective implements OnInit {
  @Input('hasPermissions')permission;
  constructor(
    private permissions: PermissionsService
    , private templateRef:  TemplateRef<any>
    , private viewContainerRef: ViewContainerRef  
  ) { }

  async ngOnInit() {
    const isSuperAdmin = await this.permissions.isSuperAdmin()
    if (isSuperAdmin) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      return
    } 
    const permissions =  await this.permissions.getPermissions();
    if (permissions.includes(this.permission)) this.viewContainerRef.createEmbeddedView(this.templateRef);
  }
}
