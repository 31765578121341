import { PERMISSIONS, PermissionsService } from './../../services/permissions/permissions.service';
import { Component, Inject, OnInit } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest, of } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import { Router } from '@angular/router';
import {
  switchMap,
  startWith,
  map,
  catchError,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';
import {AppUserOutput} from '../../users-api/model/appUserOutput';
import {UntypedFormControl, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {StorageService} from '../../services/storage.service';
import {
  ChallengeAnswer,
  CHALLENGE_STATUS_OPTIONS,
  CHALLENGE_ANSWER_STATUSES,
  ChallengeStatusType,
  CHALLENGE_TYPES_OPTIONS
} from '../../models/answers/answers-models';
import {ChallengesService} from './challenges.service';
import { AuthService } from '../../services/auth.service';
// import {formatDate} from '@angular/common';
import moment from 'moment';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-challenges',
  templateUrl: './challenges.component.html',
  styleUrls: ['./challenges.component.scss']
})
export class ChallengesComponent implements OnInit {
  loading = false;
  displayedColumns = ['answer'];
  pageSize = 100;
  hoveredRow: any;
  dataSource: Observable<ChallengeAnswer[]>;
  uidFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  appIdFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  titleFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  titleOptions$: Observable<string[]>;
  titleControl = new UntypedFormControl('');
  statusControl = new UntypedFormControl('', Validators.required);
  typesControl = new UntypedFormControl('', Validators.required);
  startDateControl = new UntypedFormControl(Validators.required);
  endDateControl = new UntypedFormControl();
  currentUser: AppUserOutput;
  currentAppId: string;
  statusOptions: {
    label: string;
    value: string;
  }[];
  typesOptions: {
    label: string;
    value: string;
  }[];
  allowedAppIds: string[];
  allowAllAppIds = false;
  requiredFields: boolean;
  resultsFound = true;
  foundNumber: number;
  resultsNumberLimit = 100;
  dataArrayToExcel = [];

  PERMISSIONS = PERMISSIONS;
  constructor(
    @Inject('ChallengesFireAuth') private challengesAuth: AngularFireAuth,
    @Inject('ChallengesFirestore') private challengesFire: AngularFirestore,
    private router: Router,
    private storageService: StorageService,
    private challengesService: ChallengesService,
    private authService: AuthService,
    private permissions : PermissionsService
  ) {
    this.getUserInfo();
    this.getChallenges();
    this.statusOptions = [{
      label: 'Seleccionar',
      value: ''
    }];
    this.typesOptions = [{
      label: 'Seleccionar',
      value: ''
    }];
    this.statusOptions.push(...CHALLENGE_STATUS_OPTIONS);
    this.typesOptions.push(...CHALLENGE_TYPES_OPTIONS);
    this.titleOptions$ = combineLatest([
      this.appIdFilter$.pipe(distinctUntilChanged()),
      this.titleControl.valueChanges.pipe(
        startWith(''),
        debounceTime(500),
        distinctUntilChanged()
      )
    ]).pipe(
      switchMap(([appId, prefix]) => {
        if (!this.allowAllAppIds && (!this.allowedAppIds || this.allowedAppIds.length === 0)) {
          return of([]);
        }
        if (!prefix || !prefix.trim()) {
          this.titleFilter$.next(null);
          return of([]);
        }
        return this.challengesFire.collection('ChallengesAnswers', ref => {
          const trimmedPrefix = prefix.trim();
          let query = ref
            .where('challenge.title', '>=', trimmedPrefix)
            .where('challenge.title', '<=', `${trimmedPrefix}~`)
            .limit(10);
          const appIds = appId ? [appId] : (this.allowAllAppIds ? null : this.allowedAppIds);
          if (appIds) {
            query = query.where('appId', 'in', appIds);
          }
          return query;
        }).valueChanges({ idField: 'id' });
      }),
      map(
        items => items
          .map(i => i.challenge.title)
          .filter((t, i, self) => self.indexOf(t) === i)
      ),
      catchError(err => {
        console.error(err);
        Swal.fire('Error', err.message || err, 'error');
        return of([]);
      })
    );
  }

  async ngOnInit() {
    await this.initAllowedIds();
    this.typesControl.markAsTouched()
    this.startDateControl.markAsTouched()
    this.statusControl.markAsTouched();
  }

  async initAllowedIds() {
    // Do not allow any app by default
    this.allowedAppIds = [];
    this.allowAllAppIds = false;
    const account = this.storageService.get('account');
    if (!account) {
      return;
    }
    if (account.roles.includes('super_admin')) {
      // Allow all ids
      this.allowedAppIds = null;
      this.allowAllAppIds = true;
      return;
    }
    if (await this.permissions.hasPermission(PERMISSIONS.ACCESS_ALL)){
      this.allowedAppIds = null;
      this.allowAllAppIds = true;
      return;
    }
    const profile = this.storageService.get('profile');
    if (!profile) {
      return;
    }
    this.allowedAppIds = profile.authorized_apps || [];
  }

  async getUserInfo() {
    const challengesUser = await this.authService.getRemoteFirebaseUser(
      this.challengesAuth,
      'challenges'
    );
  }

  getChallenges() {
    this.dataSource = combineLatest([
      this.uidFilter$.pipe(distinctUntilChanged()),
      this.appIdFilter$.pipe(distinctUntilChanged()),
      this.titleFilter$.pipe(startWith(''), distinctUntilChanged()),
      this.statusControl.valueChanges.pipe(startWith(''), distinctUntilChanged()),
      this.typesControl.valueChanges.pipe(startWith(''), distinctUntilChanged()),
      this.startDateControl.valueChanges.pipe(startWith(''), distinctUntilChanged()),
      this.endDateControl.valueChanges.pipe(startWith(''), distinctUntilChanged()),
    ]).pipe(
      map(data => {
        this.loading = true;
        return data;
      }),
      switchMap(
        ([uid, appId, title, status, type, startDate, endDate]) => {
          if (!this.allowAllAppIds
              && (!this.allowedAppIds
                || !this.allowedAppIds.length
                || (appId && !this.allowedAppIds.includes(appId)))) {
            return of([]);
          }
          this.requiredFields = appId && type && status && startDate;
          if (!this.requiredFields) {
            return of([]);
          }
          // En algunas apps (como Rotoplas MX) pusieron mal el app_id con el
          // que se envían las respuestas, así que debemos revisar varios app_id
          // por alguna razón casi siempre cambian el guión abajo (_)
          // por guión en el medio (-).
          // NOTESE que cuando corrijan el app_id en la aplicación (front)
          // esto seguirá funcionando porque tiene en cuenta ambos casos.
          const appIds: string[] = [];
          if (appId) {
            appIds.push(appId);
            const altAppId = appId.replace(/_/g, '-');
            if (altAppId !== appId) {
              appIds.push(altAppId);
            }
          }
          return this.challengesFire.collection('ChallengesAnswers', ref => {
            let query = ref.limit(this.resultsNumberLimit);
            query = uid ? query.where('uid', '==', uid) : query;
            query = appId ? query.where('appId', 'in', appIds) : query;
            query = title ? query.where('challenge.title', '==', title.trim()) : query;
            query = type ? query.where('type', '==', type.trim()) : query;
            query = status ? query.where('status', '==', status) : query;
            query = startDate ? query.where('timestamp', '>=', moment(startDate).toDate()) : query;
            query = endDate ? query.where('timestamp', '<=', moment(endDate).toDate()) : query;
            if (!appId && !this.allowAllAppIds) {
              // in operator allows at most 10 options
              query = query.where('appId', 'in', this.allowedAppIds.slice(0, 10));
            }
            return query;
          }).valueChanges({ idField: 'id' });
        }
      ),
      map(data => {
        this.loading = false;
        this.resultsFound = data.length > 0;
        this.foundNumber = data.length;
        this.dataArrayToExcel = data;
        return data.map((a: ChallengeAnswer) => {
          a.statusLabel = CHALLENGE_ANSWER_STATUSES[a.status];
          if (a.fields) {
            a.textFields = a.fields.filter(i => (i.type !== 'file' && i.type !== 'camera')).map(item => {
              if (item.name === 'date') {
                item['value'] = moment(item.value).format('DD MMM YYYY HH:mm:ss');
              }
              return item;
            });
            a.imgFields = a.fields.filter(i => (i.type === 'file' || i.type === 'camera'));
          }
          return a;
        });
      }),
      catchError(err => {
        this.loading = false;
        console.error(err);
        Swal.fire('Error', err.message || err, 'error');
        return of([]);
      })
    ) as Observable<ChallengeAnswer[]>;
  }

  onUserSelected(user: AppUserOutput) {
    this.uidFilter$.next(user.id);
    this.currentUser = user;
  }

  onAppSelected(appId: string) {
    this.appIdFilter$.next(appId);
    this.currentAppId = appId;
  }

  onTitleOptionSelected() {
    this.titleFilter$.next(this.titleControl.value);
  }

  resetUid() {
    this.currentUser = null;
    this.uidFilter$.next(null);
  }

  resetApp() {
    this.currentAppId = null;
    this.uidFilter$.next(null);
  }

  onEdit(challenge: ChallengeAnswer) {
    this.router.navigate(['pages', 'challenges', challenge.id]);
    return false;
  }

  getRowBackgroundColor(row) {
    return row === this.hoveredRow ? '#F5F5F5' : 'transparent';
  }

  userFilterClosed() {
    this.resetUid();
  }

  changeChallengeStatus(challenge: ChallengeAnswer, newStatus: ChallengeStatusType) {
    Swal.fire({
      title: `Cambiar estado de la respuesta a "${CHALLENGE_ANSWER_STATUSES[newStatus]}"?`,
      showCancelButton: true,
      confirmButtonText: 'CAMBIAR',
      cancelButtonText: 'CANCELAR'
    }).then(result => {
      if (!result.isConfirmed) {
        return;
      }
      this.challengesService.updateChallengeStatus(challenge.id, newStatus)
        .then(
          () => challenge.status = newStatus,
          err => Swal.fire('Actualizando Estado', err.message || err, 'error')
        );
    });
  }

  fieldTextValue(val: any) {
    const txtVal = `${val}`;
    const maxlen = 20;
    const slicedVal = txtVal.slice(0, maxlen) + (txtVal.length > maxlen ? '...' : '');
    return slicedVal;
  }

  toExportFileName(excelFileName: string): string {
    return `${excelFileName}_export_${new Date().getTime()}.csv`;
  }
  // public exportAsExcelFile(json: any[]): void{
  exportAsExcelFile(){
    const excelFileName = 'gluky_challenge';
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.dataArrayToExcel);
    const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
    XLSX.writeFile(workbook, this.toExportFileName(excelFileName));
  }
}
