import firebase from 'firebase';

interface AnswerField {
  name?: string;
  /**
   *
   * Type define el origen de la carga de la foto, p.ej 
   * camera: si fue tomada en la camara,
   * file: si fue cargada desde un archivo
   * @type {string}
   * @memberof AnswerField
   */
  type: string;
  value: any;
  label?: string;
}

export interface ChallengeAnswer {
  [x: string]: any;
  id: string;
  challenge: {
    id: string;
    title: string;
    subtitle: string;
  };
  uid: string;
  timestamp: firebase.firestore.Timestamp;
  status: null | undefined | 'in-progress' | 'accepted' | 'rejected' | 'retry';
  statusLabel: string;
  fields: AnswerField[];
  textFields: AnswerField[];
  imgFields: AnswerField[];
  appId: string;
  username: string;
  updatedBy?: {
    id: string;
    appId: string;
    username: string;
  };
  updatedAt?: firebase.firestore.Timestamp;
}

export interface InvoiceAnswer {
  [x: string]: any;
  answerId: string;
  id: string;
  invoice: {
    id: string;
    id_emisor: string;
  };
  uid: string;
  status: null | undefined | 'in-progress' | 'accepted' | 'rejected' | 'retry';
  statusLabel: string;
  appId: string;
  date: Date;
  image: string;
  photo: string;
  total: number;
  updatedBy?: {
    id: string;
    appId: string;
    username: string;
  };
  updatedAt?: firebase.firestore.Timestamp;
  statusHistory: ChallengeStatusType;
}

export interface ExhibitionAnswer {
  [x: string]: any;
  programId: string;
  username: string;
  fullName: string;
  date: Date;
  photos: Array<AnswerField>;
  uid: string;
  storeSection: string;
  storeName: string;
  exhibitionName?: string;
  idNumber?: string;
  city?: string;
  status: ChallengeStatusType;
  rejectionCause?: string;
  fields?: Array<AnswerField>;
  timestamp: Date;
  comment?: string;
  checker?: {
    id: string;
    username: string;
    designateBy: {
      id: string;
      username: string;
    };
  };
  statusHistory: ChallengeStatusType;
}



export type ChallengeStatusType = 'in-progress' | 'accepted' | 'rejected' | 'retry';

export const CHALLENGE_ANSWER_STATUSES = {
  'in-progress': 'En Progreso',
  'accepted': 'Aceptada',
  'rejected': 'Rechazada',
  'retry': 'Reintentar',
};
export const INVOICES_ANSWER_STATUSES = {
  'in-progress': 'En Progreso',
  'accepted': 'Aceptada',
  'rejected': 'Rechazada'
};

export const CHALLENGE_STATUS_OPTIONS = Object.entries(CHALLENGE_ANSWER_STATUSES).map(([k, v]) => ({
  label: v,
  value: k,
}));
export const INVOICES_STATUS_OPTIONS = Object.entries(INVOICES_ANSWER_STATUSES).map(([k, v]) => ({
  label: v,
  value: k,
}));

export const CHALLENGE_FILTER_TYPES = {
  'invoice': 'invoice',
  'challenge': 'challenge',
  'exhibition': 'exhibition',
};

export const CHALLENGE_TYPES_OPTIONS = Object.entries(CHALLENGE_FILTER_TYPES).map(([k, v]) => ({
  label: v,
  value: k,
}));
