/* eslint-disable @typescript-eslint/indent */
export interface Bags {
    data: Bag[];
}

export interface Bag {
    bag_id?: string;
    bag_name: string;
    bag_type?: string;
    bag_state?: boolean;
    customer_nit: string;
    bag_balance: number;
    bag_create_ts?: string;
    bag_modify_ts?: string;
    user_process?: string;
    documents?: [];
    program?: [];
}

export interface BagTransation {
    observations: string;
    value: number;
    invoice_id?: number;
    invoice_date?: string;
    user_process: string;
}

export const OPTIONS_TYPES = {
    'credit_adjustment': 'Ajuste Credito',
    'debit_adjustment': 'Ajuste Debito',
    'credit': 'Crédito',
    'debit': 'Debito',
    'fee': 'Fee mensual',
    'ppunt': 'Ppunt',
  };

export const TYPES_OPTIONS = Object.entries(OPTIONS_TYPES).map(([k, v]) => ({
    label: v,
    value: k,
  }));

  export interface Transaction {
    transaction_id: string;
    bag_id: string;
    transaction_type: string;
    observations: string;
    value_cents: number;
    value_currency_code: string;
    exchange_data_delivery_globalid: string;
    exchange_data_total_price: number;
    credit_data_invoice_id: number;
    credit_data_invoice_date: string;
    user_process: string;
    datetime: string;
}

export interface BagTransactions {
    data: Transaction[];
}

export const OPTIONS_TYPES_GENERALES = {
    'credit_adjustment': 'Ajuste Credito',
    'debit_adjustment': 'Ajuste Debito',
    'credit': 'Crédito',
    'debit': 'Debito',
    'fee': 'Fee mensual',
    'ppunt': 'Ppunt',
    'exchange': 'Descuento Pedido',
    'refund': 'Reembolso Pedido',
    'initialBalance': 'Saldo Inicial'
  };

  export const OPTIONS_TYPESMONT = {
    'credit': ' + ', // creditos suman a la bolsa
    'debit': ' - ', // debitos restan a la bolsa
  };
