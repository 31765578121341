// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  width: 100%;
  margin: 0 auto;
}

.search-container {
  margin-bottom: 3%;
}

.category-title {
  display: flex;
  justify-content: space-between;
}
.category-title span {
  max-width: 70%;
}

.categories-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.category-container {
  margin-bottom: 3%;
}

.btn-edit {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/pointz/categories/category-list/category-list.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,cAAA;AACJ;;AACE;EACE,iBAAA;AAEJ;;AAAE;EACE,aAAA;EACA,8BAAA;AAGJ;AAFI;EACE,cAAA;AAIN;;AADE;EACE,aAAA;EACA,8BAAA;EACA,eAAA;AAIJ;;AADE;EACE,iBAAA;AAIJ;;AAFE;EACE,eAAA;AAKJ","sourcesContent":[".loader {\n    width: 100%;\n    margin: 0 auto;\n  }\n  .search-container {\n    margin-bottom: 3%;\n  }\n  .category-title {\n    display: flex;\n    justify-content: space-between;\n    span {\n      max-width: 70%;\n    }\n  }\n  .categories-container {\n    display: flex;\n    justify-content: space-between;\n    flex-wrap: wrap;\n  }\n  \n  .category-container {\n    margin-bottom: 3%;\n  }\n  .btn-edit {\n    cursor: pointer;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
