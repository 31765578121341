// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#user-search-controls {
  display: grid;
}

.search-result-item {
  border-style: solid;
  border-color: lightgray;
  border-width: 1px 0 0 0;
  padding: 16px;
}

.search-result-item.last {
  border-width: 1px 0 1px 0;
}

.user-label {
  color: darkgray;
  font-size: 0.8em;
}

.mdc-button {
  color: lightgrey;
}`, "",{"version":3,"sources":["webpack://./src/app/app-common/app-user-selector/app-user-selector.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,mBAAA;EACA,uBAAA;EACA,uBAAA;EACA,aAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,eAAA;EACA,gBAAA;AACF;;AACA;EACA,gBAAA;AAEA","sourcesContent":["#user-search-controls {\n  display: grid;\n}\n\n.search-result-item {\n  border-style: solid;\n  border-color: lightgray;\n  border-width: 1px 0 0 0;\n  padding: 16px;\n}\n\n.search-result-item.last {\n  border-width: 1px 0 1px 0;\n}\n\n.user-label {\n  color: darkgray;\n  font-size: 0.8em;\n}\n.mdc-button{\ncolor: lightgrey;\n}\n\n// \n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
