import { CategoryService } from './../../../../services/categories.service';
import { CloudinaryService, PLUGINS_CLD } from './../../../../services/cloudinary.service';
import { environment } from './../../../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import Swal from "sweetalert2";
import { Cloudinary } from '@cloudinary/url-gen';

declare var cloudinary: any;
@Component({
  selector: 'app-category-edit',
  templateUrl: './category-edit.component.html',
  styleUrls: ['./category-edit.component.scss']
})
export class CategoryEditComponent implements OnInit {
  typePage: string;
  loading = true;
  titlePage: string;
  category: any;
  messageAlert: string;
  newImage: string;
  imagenDefault
  publicId = environment.public_id_cloudinary;
  categoryForm: FormGroup;
  widget;
  showHints: string;
  imageVersion: string;
  plugins = PLUGINS_CLD
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private categoryServices: CategoryService,
    private cloudinaryService: CloudinaryService,
    private router: Router,
  ) {
    this.categoryForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      id: ["", [Validators.required]],
      imageId: ["", [Validators.required]],
      imageVersion: ["", [Validators.required]],
    });
  }
  async ngOnInit() {
    this.loading = true;
    await this.checkTypePage();
    this.widget = cloudinary.createUploadWidget(
      this.selectConfigurationWidgetCloudinary(),
      this.handlerWidget
    );
  }
  openWidgetCloudinary() {
    this.widget.open();
  }

  cldImg(id, versionImg?){
    const cld = new Cloudinary({
      cloud: environment.widget_cloudinary_config
    })
   // const img: CloudinaryImage = cld.image(`${this.publicId}${id}.png`) || undefined;
   const  img = this.cloudinaryService.getCloudinaryImage(this.publicId,id,versionImg)
  return img
  } 

  checkTypePage() {
    return new Promise<void>((resolve) => {
      this.route.params.subscribe(async (params: Params) => {
        if (params.id) {
          await this.loadBrand(params.id);
          this.loading = false;
          this.typePage = "edit";
          return resolve();
        }
        this.loading = false;
        this.typePage = "create";
        return resolve();
      });
    });
  }

  loadBrand(categoryId) {
    return new Promise<void>((resolve) => {
      this.categoryServices.getCategory(categoryId).subscribe((category) => {
        this.category = { ...category };
        this.category.img = this.cloudinaryService.getCloudinaryImage(this.publicId,category.imageId,category.imageVersion)
        this.categoryForm.controls.name.setValue(this.category.name);
        this.categoryForm.controls.id.setValue(this.category.id);
        this.categoryForm.controls.imageId.setValue(this.category.imageId);
        this.categoryForm.controls.imageVersion.setValue(this.category.imageVersion);
        this.imageVersion = this.category.imageVersion;
        resolve();
      });
    });
  }

  // this function  use was arrow function to get main this
  handlerWidget = (error, result) => {
    if (error) {
      return Swal.fire({
        icon: "error",
        title: "Parece que hay un error",
      }).then(() => Swal.close());
    }
    if (!error && result && result.event === "success") {
      this.newImage = result.info.public_id;
      this.imageVersion = result.info.version;
      this.categoryForm.controls.imageId.setValue(this.getOnlyId());
      this.categoryForm.controls.imageVersion.setValue(
        result.info.version.toString()
      );
      
      if (this.category) {
        this.category.img = this.cloudinaryService.getCloudinaryImage(this.publicId,this.getOnlyId(),result.info.version.toString())
      } else {
        this.imagenDefault = this.cloudinaryService.getCloudinaryImage(this.publicId,this.getOnlyId(),result.info.version.toString())
      }

    }
  };

  getOnlyId() {
    const pathImage: any = this.newImage.split("/");
    return pathImage[2];
  }

  save() {
    // submit button
    if (this.categoryForm.status === "INVALID") {
      this.showHints = "Verifica Los campos";
      return;
    }
    // verify if make a post or put
    const methodToApi = this.typePage === "create" ? "postCategory" : "putCategory";
    // send
    this.categoryServices[methodToApi]({ ...this.categoryForm.value }).subscribe(
      (res) => {
        const title =
          this.typePage === "create"
            ? res.id + " se creo correctamente"
            : "Operacion realizada correctamente";
        if (res.id || res.updated) {
          return Swal.fire({
            icon: "success",
            title,
            confirmButtonColor: "#ff9800",
          }).then(() => this.goHome());
        }
        return Swal.fire({
          icon: "error",
          title: "Parece que hay un error",
          confirmButtonColor: "#DD6B55",
        }).then(() => Swal.close());
      }
    ),
      (err) => {
        return Swal.fire({
          icon: "error",
          title: "Parece que hay un error",
          confirmButtonColor: "#DD6B55",
        }).then(() => Swal.close());
      };
  }
  goHome() {
    // window.location.href = 'private/brands';
    this.router.navigate(["pages/allies/category/list"]);
  }

  selectConfigurationWidgetCloudinary() {
    if (this.typePage === "edit") {
      return {
        ...environment.widget_cloudinary_config,
        uploadSignature: this.cloudinaryService.generateSignature,
        publicId: this.category.imageId,
        invalidate: true,
      };
    }
    return {
      ...environment.widget_cloudinary_config,
      uploadSignature: this.cloudinaryService.generateSignature,
    };
  }

  generateId(event: string) {
    if (this.typePage === "create") {
      let newId: string = event.replace(/[^a-zA-Z0-9/]+/g, "_");
      this.categoryForm.controls.id.setValue(newId.toLowerCase());
    }
  }
}
