import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {AppOutput} from '../../users-api';
import {UntypedFormControl, Validators} from '@angular/forms';
import {StorageService} from '../../services/storage.service';
import {AppsUtilService} from '../../services/apps-util.service';

interface AppOption {
  appId: string;
  appLabel: string;
}

@Component({
  selector: 'app-app-selector',
  templateUrl: './app-selector.component.html',
  styleUrls: ['./app-selector.component.scss']
})
export class AppSelectorComponent implements OnInit {
  @Output() onAppSelected = new EventEmitter<string>();

  @Input()
  set currentAppId(appId: string) {
    this.appSelectControl.setValue(appId);
    if (appId) {
      this.setSelectedApp(appId);
    }
  }

  @Input() restrictToIds: string[] | undefined;
  @Input() disabled = false;
  @Input() NoSetLastSelected = false;
  appSelectControl = new UntypedFormControl(Validators.required);
  appsOptions: AppOption[];

  constructor(
    private storageService: StorageService,
    private appsUtilService: AppsUtilService,
  ) { }

  setSelectedApp(appId: string) {
    if (appId) {
      this.storageService.set('AppSelectorComponent:lastSelectedApp', appId);
    } else {
      this.storageService.delete('AppSelectorComponent:lastSelectedApp');
    }
    this.onAppSelected.emit(appId);
  }

  async loadAppsOptions() {
    try {
      const apps = await this.appsUtilService.getApps();
      this.appsOptions = apps.map((app: AppOutput) => ({
        appId: app.id,
        appLabel: `${app.name} (${app.id})`
      }));
    } catch (error) {
      console.error('AppSelectorComponent: getting apps list', error);
    }
    if (this.NoSetLastSelected )return;
    const lastSelected = this.storageService.get('AppSelectorComponent:lastSelectedApp');
    if (lastSelected) {
      this.appSelectControl.setValue(lastSelected);
      this.onAppSelected.emit(lastSelected);
    }
  }

  ngOnInit() {
    this.loadAppsOptions();
    this.appSelectControl.markAllAsTouched();
    this.appSelectControl.valueChanges.subscribe(
      (appId: string) => this.setSelectedApp(appId)
    );
  }

}
