import { Component, OnInit } from "@angular/core";
import { BrandService } from "../../../services/brand.service";
import { Brand, BrandRoot, Allie } from "../../../models/models";
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { AlliesService } from "../../../services/allies.service";
import { Observable } from "rxjs";
import { map, startWith } from "rxjs/operators";
import Swal from "sweetalert2";

declare var cloudinary: any;
@Component({
  selector: "app-allies-edit",
  templateUrl: "./allies-edit.component.html",
  styleUrls: ["./allies-edit.component.scss"],
})
export class AlliesEditComponent implements OnInit {
  typePage = "create";
  loading = false;
  brands: Brand[] = [];
  allieForm: UntypedFormGroup;
  allie: Allie;
  filteredOptions: Observable<any>;
  countries = [
    { name: "Colombia", value: "CO" },
    { name: "Mexico", value: "MX" },
  ];

  showHints: string;
  constructor(
    public brandServices: BrandService,
    private allieService: AlliesService,
    private route: ActivatedRoute,
    private formBuilder: UntypedFormBuilder,
    private router: Router
  ) {
    this.allieForm = this.formBuilder.group({
      id: ["", [Validators.required]],
      country: ["", [Validators.required]],

      idDocument: ["", [Validators.required]],
      type_document: ["", [Validators.required]],
      g2SKU: ["", [Validators.required]],
      taxId: ["", [Validators.required]],
      nameContact: ["", [Validators.required]],
      name: ["", [Validators.required]],
      phone: [
        "",
        [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)],
      ],
      brandId: ["", [Validators.required]],
    });
  }
  ngOnInit() {
    this.loading = true;
    this.getBrands();
    this.checkTypePage();
  }

  checkTypePage() {
    this.route.params.subscribe((params: Params) => {
      if (params.id) {
        this.loadAllie(params.id);
        this.loading = false;
        return (this.typePage = "edit");
      }
      this.loading = false;
      return (this.typePage = "create");
    });
  }

  getBrands() {
    this.loading = true;
    this.brandServices.getBrands().subscribe((res: BrandRoot) => {
      this.brands.push(...res.data);
      this.loading = false;
    });
  }

  loadAllie(AllieId) {
    this.allieService.getAllie(AllieId).subscribe((allie: Allie) => {
      this.allieForm.patchValue(allie);
      this.allie = { ...allie };
      if (this.allie.contact) {
        this.allieForm.controls.phone.setValue(allie.contact.phone);
        this.allieForm.controls.nameContact.setValue(allie.contact.name);
      }
      /*this.allieForm.controls.id.setValue(allie.id);
      this.allieForm.controls.nameContact.setValue(allie.contact.name);
      this.allieForm.controls.country.setValue(allie.country);
      this.allieForm.controls.name.setValue(allie.name);
      this.allieForm.controls.idDocument.setValue(allie.contact.idDocument);
      this.allieForm.controls.phone.setValue(allie.contact.phone);
      this.allieForm.controls.type_document.setValue(allie.type_document);
      this.allieForm.controls.g2SKU.setValue(allie.g2SKU);
      this.allieForm.controls.taxId.setValue(allie.taxId);
      this.allieForm.controls.brandId.setValue(allie.brandId);
      */
    });
  }
  // save allie
  save(event) {
    event.preventDefault();

    if (this.allieForm.status === "INVALID") {
      Swal.fire({
        icon: "error",
        title: "Verifica los campos",
        confirmButtonColor: "#ff9800",
      });
      return;
    }

    // get values and build object to send
    const newAllie = { ...this.allieForm.value };
    newAllie.contact = {
      name: newAllie.nameContact,
      phone: newAllie.phone.toString(),
      idDocument: newAllie.idDocument,
    };
    delete newAllie.nameContact;
    delete newAllie.phone;
    delete newAllie.identification;

    // verify if make a post or put
    const methodToApi = this.typePage === "create" ? "postAllie" : "putAllie";
    this.allieService[methodToApi]({ ...newAllie }).subscribe((res: any) => {
      const title =
        this.typePage === "create"
          ? res.id + " se creo correctamente"
          : "Operacion realizada correctamente";
      if (res.id || res.updated) {
        return Swal.fire({
          icon: "success",
          title,
          confirmButtonColor: "#ff9800",
        }).then(() => this.goHome());
      }
      return Swal.fire({
        icon: "error",
        title: "Parece que hay un error",
        confirmButtonColor: "#DD6B55",
      }).then(() => Swal.close());
    });
  }
  goHome() {
    this.router.navigate(["pages/allies/list"]);
  }

  generateId(event: string) {
    if (this.typePage === "create") {
      let newId: string = event.replace(/[^a-zA-Z0-9/]+/g, "_");

      this.allieForm.controls.id.setValue(newId.toLowerCase());
    }
  }
}
