/**
 * Gluky Users API - Test
 * Gluky\'s apps and programs definitions and of the users authorized to access them, including their roles and other authorization information.
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: sistemas@gluky.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Common AppUser fields.
 */
export interface AppUserPatch {
    username?: string;
    email?: string;
    phone?: string;
    password?: string;
    roles?: Array<string>;
    enabled?: boolean;
    profile?: { [key: string] : string[] };
    personal_data?: {
      iddoc_type?: string;
      iddoc?: string;
      name?: string;
      last_name?: string;
    };
}

