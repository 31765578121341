import { MaterialModule } from './../../material/material.module';
import { AppCommonModule } from './../../app-common/app-common.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListTablesExhibitionsComponent } from './tables/list-tables-exhibitions/list-tables-exhibitions.component';
import { FormTablesExhibitionsComponent } from './tables/form-tables-exhibitions/form-tables-exhibitions.component';
import { ListExhibitionsComponent } from './exhibition/list-exhibitions/list-exhibitions.component';
import { FormExhibitionsComponent } from './exhibition/form-exhibitions/form-exhibitions.component';
import { ContainerModule } from '../container/container.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { BrowserModule } from '@angular/platform-browser';
import { CdkTableModule } from '@angular/cdk/table';

const modules = [ListTablesExhibitionsComponent,FormTablesExhibitionsComponent,ListExhibitionsComponent,FormExhibitionsComponent, ]

@NgModule({
  declarations: [...modules],
  imports: [
    CommonModule,
    BrowserModule,
    AppCommonModule,
    ContainerModule,
    FormsModule,
    CdkTableModule,
    ReactiveFormsModule,
    MaterialModule
  ],
  exports:[...modules]
})
export class ExhibitionsModule { }
