import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../../environments/environment';
import { Injectable } from '@angular/core';
import { ITablesExhibitions } from '../tables/tables-exhibitions.model';

@Injectable({
  providedIn: 'root'
})
export class TablesExhibitionsService {
   header = new HttpHeaders({ 'Content-Type':'application/json'});
  constructor(private http: HttpClient) {}
  

  getTables(appid){
    const uriConnection = `${environment.exhibition_api.base_url}/${appid}/tables`;
    let headers = this.header
    return this.http.get<{data:ITablesExhibitions[]}>(`${uriConnection}`, { headers });
  }
  
  getTable(appid,id: string) {
    let headers = this.header
    const uri = `${environment.exhibition_api.base_url}/${appid}/tables/${id}`;;
    return this.http.get(uri,{ headers });
  }

  putTable(table: ITablesExhibitions) {
    const uriConnection = `${environment.exhibition_api.base_url}/${table.app_id}/tables`;
    return this.http.put<ITablesExhibitions>(
      `${uriConnection}/${table.id}`,
      table
    );
  }
}
