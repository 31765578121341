import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MainSidenavService } from '../../../main-sidenav.service';

import {
  CompaniesService,
  Company,
} from '../../../users-api';

@Component({
  selector: 'app-companies-viewer',
  templateUrl: './companies-viewer.component.html',
  styleUrls: ['./companies-viewer.component.scss'],
  providers: [ CompaniesService ],
})
export class CompaniesViewerComponent implements OnInit {
  loading = true;
  company: Company;
  originalCompany: Company;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private companiesApi: CompaniesService,
    public mainSidenavService: MainSidenavService,
    private snackBar: MatSnackBar,
  ) {

  }

  ngOnInit() {
    this.loading = true;
    this.route.paramMap.pipe(switchMap((params: ParamMap) =>
      this.companiesApi.retrieveCompany(params.get('id'))
    )).subscribe((company: Company) => {
      this.loading = false;
      this.company = company;
      this.originalCompany = { ...company };
    }, error  => {
      this.loading = false;
      console.error('Reading company', error);
      this.snackBar.open('Error reading company: ' + error);
    });
  }

  onEditorOK(company: Company) {
    if (company != this.originalCompany) {
      this.loading = true;
      this.companiesApi.updateCompany(this.originalCompany.id, company).subscribe(ans => {
        this.loading = false;
        this.router.navigateByUrl('/pages/companies');
      }, error => {
        console.error('Error calling updateCompany', error);
        this.loading = false;
        try {
          const data = error.error;
          this.snackBar.open(data.message, "OK");
        }
        catch(ex) {
          this.snackBar.open("Error llamando updateCompany: " + error, "OK");
        }
      });
    }
  }

  onEditorCancel() {
    this.router.navigateByUrl('/pages/companies');
  }

}
