// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info-user {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4px;
  min-height: 45px;
  overflow-wrap: anywhere;
}
.info-user span {
  margin-left: 2%;
}
.info-user mat-icon {
  min-width: 1em;
}

.footer {
  margin-top: 0.5em;
  position: absolute;
  right: 2%;
}

.images {
  position: absolute;
  bottom: 0;
  opacity: 0.4;
  display: flex;
  align-items: center;
}
.images img {
  width: 40%;
  height: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/home/home.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,uBAAA;AACF;AAAE;EACE,eAAA;AAEJ;AAAE;EACE,cAAA;AAEJ;;AAEA;EACE,iBAAA;EACA,kBAAA;EACA,SAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,YAAA;EACA,aAAA;EAEA,mBAAA;AAAF;AACE;EACE,UAAA;EACA,YAAA;AACJ","sourcesContent":[".info-user {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  margin-bottom: 4px;\n  min-height: 45px;\n  overflow-wrap: anywhere;\n  span {\n    margin-left: 2%;\n  }\n  mat-icon{\n    min-width: 1em;\n  }\n}\n\n.footer {\n  margin-top: 0.5em;\n  position: absolute;\n  right: 2%;\n}\n\n.images {\n  position: absolute;\n  bottom: 0;\n  opacity: 0.4;\n  display: flex;\n\n  align-items: center;\n  img {\n    width: 40%;\n    height: auto;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
