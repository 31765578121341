import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MainSidenavService } from '../../../main-sidenav.service';

import { Company, CompaniesService } from '../../../users-api';

@Component({
  selector: 'app-companies-add',
  templateUrl: './companies-add.component.html',
  styleUrls: ['./companies-add.component.scss'],
  providers: [
    CompaniesService,
  ]
})
export class CompaniesAddComponent implements OnInit {
  company: Company;
  loading = true;

  constructor(
    private router: Router,
    private companiesApi: CompaniesService,
    public mainSidenavService: MainSidenavService,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    this.company = {
      id: '',
      name: '',
      id_doc: '',
      country: '',
    };
    this.loading = false;
  }

  onEditorOK(company: Company) {
    this.loading = true;
    this.companiesApi.createCompany(company).subscribe(ans => {
      this.loading = false;
      this.router.navigateByUrl('/pages/companies');
    }, error => {
      this.loading = false;
      console.error('Error in createCompany', error);
      try {
        const data = error.error;
        this.snackBar.open(data.message, "OK");
      }
      catch(ex) {
        this.snackBar.open("Ocurrió un error", "OK");
      }
    });
  }

  onEditorCancel() {
    this.router.navigateByUrl('/pages/companies');

  }

}
