import { switchMap } from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MainSidenavService } from '../../../main-sidenav.service';

import {
  AppsService,
  AppOutput as AppModel,
} from '../../../users-api';
import { AppsEditorOutput } from '../apps-editor/apps-editor-output';
import { AppsEditorProfileField } from '../apps-editor/apps-editor-profile-field';
import { ProfileFieldDefinitionUtils } from '../profile-field-definition-utils';

@Component({
  selector: 'app-apps-viewer',
  templateUrl: './apps-viewer.component.html',
  styleUrls: ['./apps-viewer.component.scss'],
  providers: [AppsService],
})
export class AppsViewerComponent implements OnInit {
  public appData: AppModel;
  public originalAppData: AppModel;
  public profileFields: Array<AppsEditorProfileField>;
  public originalProfileFields: Array<AppsEditorProfileField>;
  public loading = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private appsApi: AppsService,
    public mainSidenavService: MainSidenavService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.loading = true;
    this.route.paramMap
      .pipe(
        switchMap((params: ParamMap) =>
          this.appsApi.retrieveApp(params.get('id'))
        )
      )
      .subscribe((app: AppModel) => {
        this.appData = app;
        if (!this.appData.auth_jwt_info) {
          this.adJwtInfoToOriginalData();
        }
        this.originalAppData = { ...app };

        this.appsApi.retrieveAppProfileFields(app.id).subscribe(
          (fieldsData) => {
            this.loading = false;
            this.profileFields = ProfileFieldDefinitionUtils.apiOutputToEditorData(
              fieldsData
            );
            this.originalProfileFields = { ...this.profileFields };
          },
          (error) => {
            this.loading = false;
            // If not found
            if (error.status === 404) {
              // There are no field definitions => empty list
              this.profileFields = [];
              this.originalProfileFields = [];
            } else {
              console.error('Reading app profile fields definition', error);
              this.snackBar.open(`Error reading fields: ${JSON.stringify(error.error)}`, 'OK');
            }
          }
        );
      });
  }

  onEditorOK(editorData: AppsEditorOutput) {
    console.log('onEditorOK', editorData);
    
    if (this.originalAppData !== this.appData) {
      this.loading = true;
      this.appsApi
        .patchApp(this.originalAppData.id, editorData.appData)
        .subscribe(
          (ans) => {
            const profileData = ProfileFieldDefinitionUtils.editorDataToApiInput(
              editorData.profileFieldsData
            );
            if (Object.keys(profileData.fields).length) {
              this.appsApi
                .setAppProfileFields(this.originalAppData.id, profileData)
                .subscribe(
                  (ansProfile) => {
                    this.loading = false;
                    this.router.navigateByUrl('/pages/apps');
                  },
                  (errorProfile) => {
                    console.error(
                      'Error calling setAppProfileFields',
                      errorProfile
                    );
                    this.loading = false;
                    try {
                      const data = errorProfile.json();
                      this.snackBar.open(data.message, 'OK');
                    } catch (ex) {
                      this.snackBar.open(
                        'Error llamando setAppProfileFields: ' + errorProfile,
                        'OK'
                      );
                    }
                  }
                );
            } else {
              this.loading = false;
              this.router.navigateByUrl('/pages/apps');
            }
          },
          (error) => {
            console.error('Error calling patchApp', error);
            this.loading = false;
            try {
              const data = error.error;
              this.snackBar.open(data.message, 'OK');
            } catch (ex) {
              this.snackBar.open('Error llamando patchApp: ' + error, 'OK');
            }
          }
        );
    }
  }

  onEditorCancel() {
    this.router.navigateByUrl('/pages/apps');
  }
  adJwtInfoToOriginalData() {
    this.appData.auth_jwt_info = {
      issuer: '',
      audiences: [],
      jwks_uri: '',
    };
  }
}
