import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectToString'
})
export class ObjectToStringPipe implements PipeTransform {
  transform(value: any): string {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      return Object.entries(value)
        .map(([key, val]) => {
          return `${key}: ${typeof val === 'object' && !Array.isArray(val) ? this.transform(val) : val}`;
        })
        .join(' | ');
    }
    return value !== undefined && value !== null ? value.toString() : '';
  }
}
