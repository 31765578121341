import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  storage = localStorage;

  constructor() {}

  set(key: string, data: any) {
    this.storage.setItem(key, JSON.stringify(data));
  }

  get(key: string) {
    const strVal = this.storage.getItem(key);
    if (!strVal || strVal === 'undefined') {
      return null;
    }
    return JSON.parse(strVal);
  }

  delete(key: string) {
    this.storage.removeItem(key);
  }

  deleteAll() {
    this.storage.clear();
  }
}
