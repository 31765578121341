import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Cloudinary, CloudinaryImage } from "@cloudinary/url-gen";
import { defaultImage } from "@cloudinary/url-gen/actions/delivery";
import { scale } from "@cloudinary/url-gen/actions/resize";
import { byRadius } from "@cloudinary/url-gen/actions/roundCorners";
import { lazyload, placeholder } from "@cloudinary/ng";

export const PLUGINS_CLD = [placeholder({mode:'blur'}), lazyload()]
@Injectable({
  providedIn: "root",
})
export class CloudinaryService {
  uriConnection: string = environment.pointz_api.uri_cloudinary + "/signature";
  constructor(private http: HttpClient) {}
  getSignature(paramsToSign) {
    return this.http.get<any>(this.uriConnection, {
      params: {
        ...paramsToSign,
      },
    });
  }

  generateSignature = (cb, paramsToSign) => {
    this.getSignature(paramsToSign).subscribe((signature) => {
      cb(signature);
    });
  };

  cldImg(publicId,id){
    const cld = new Cloudinary({
      cloud: environment.widget_cloudinary_config
    })
    const img: CloudinaryImage = cld.image(`${publicId}${id}.png`) || undefined;
  return img
  }

  
/**
 * Get img: CloudinaryImage from parameters and global config
 *
 * @param {*} publicId
 * environment.public_id_cloudinary for tags/folders
 * @param {*} imageId
 * @param {*} [imageVersion]
 * @param {*} [width]
 * @param {*} [height]
 * @param {*} [radius]
 * radius image
 * @param {*} [imageDefault]
 * default Avatar
 * @memberof CloudinaryService
 */
getCloudinaryImage(publicId, imageId, imageVersion?,width?,height?,radius?,imageDefault? ){
    return this.cldImg(publicId, imageId)
    .setVersion(imageVersion)
    .delivery(defaultImage(imageDefault|| "avatar"))
    .resize(
      scale()
        .width(width || 300)
        .height(height || 200)
    )
    .roundCorners(byRadius(radius || 10))
  }
}
