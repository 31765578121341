import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-loader-full',
    templateUrl: 'loader-full.component.html'
})

export class LoaderFullComponent implements OnInit {
    @Input() set state(state: string) {
        if (state) this.spinner.show();
        else this.spinner.hide();
    }
    @Input() set text(text: string) {
        this._text = text
    }

    @Input() set time(time: number) {
        if (time) {
            this.spinner.show();
            setTimeout(() => {
            this.spinner.hide();
            }, time);
        }
    }
    _text = 'Loading...'
    constructor(private spinner: NgxSpinnerService) { }

    ngOnInit() { }
}