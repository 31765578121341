// File downloaded from https://datahub.io/core/currency-codes
import currencyData from './Common-Currency.json';

export const currencies = Object.values(currencyData).sort((cA: any, cB: any) => {
  const a = cA.name;
  const b = cB.name;

  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
});

export const currenciesForSelect = currencies.map((c: any) => ({
  code: c.code,
  label: `${c.name} (${c.code})`,
}));
