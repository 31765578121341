// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn-container {
  margin-top: 2%;
  display: flex;
  justify-content: flex-end;
}

.mat-hint {
  color: red;
}

.category-title {
  border: 1px solid black;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/pointz/categories/category-edit/category-edit.component.scss"],"names":[],"mappings":"AAKA;EACE,cAAA;EAEA,aAAA;EACA,yBAAA;AALF;;AAOA;EACE,UAAA;AAJF;;AAMA;EACE,uBAAA;AAHF","sourcesContent":[".header-content-edit {\n}\n.header-content-create {\n}\n\n.btn-container {\n  margin-top: 2%;\n\n  display: flex;\n  justify-content: flex-end;\n}\n.mat-hint {\n  color: red;\n}\n.category-title {\n  border: 1px solid black;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
