import { Component, OnInit, Input, ViewChild } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Form,
  Validators,
  UntypedFormControl,
} from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { ServiceAccountsService } from "../../../services/service-accounts.service";
import {
  DataEntity as serviceAccountInterface,
  serviceAccountsInterface,
} from "../../../models/users/service-accounts.model";
import { MatChipInputEvent } from "@angular/material/chips";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { AppsService } from "../../../users-api/api/apps.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-service-accounts-edit",
  templateUrl: "./service-accounts-edit.component.html",
  styleUrls: ["./service-accounts-edit.component.scss"],
})
export class ServiceAccountsEditComponent implements OnInit {
  loading: boolean = false;
  serviceTypeForm: UntypedFormGroup;
  typePage: string = "create";
  serviceAccount: serviceAccountInterface;
  title: string = "Cuentas de servicio";
  removable = true;
  visble = true;
  apps: any;
  filteredOptions: any;
  showAllControls: boolean = false;
  inputApps: UntypedFormControl;
  validForm: boolean = true;
  idService: string;
  loadingFetch: boolean = false;
  @ViewChild("matAutocomplete", { static: false }) matAutocomplete;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  constructor(
    public formBuilder: UntypedFormBuilder,
    public router: ActivatedRoute,
    public route: Router,
    public appsService: AppsService,
    public serviceAccountService: ServiceAccountsService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.loading = true;
    this.checkTypePage();
    this.createForms();
    this.getApps();
  }

  checkTypePage(): void {
    this.router.params.subscribe((params: any) => {
      if (params.id) {
        this.setEditPage(params.id);
      } else {
        this.setCreatePage();
        this.isGlukyService(false);
      }
    });
  }

  createForms(): void {
    this.serviceTypeForm = this.formBuilder.group({
      issuer: [""],
      audiences: [[]],
      jwks_uri: [""],
      is_gluky_service: [false],
      enabled: [true],
      apps: [[]],
      services: [[]],
    });
    this.inputApps = new UntypedFormControl();
  }
  setEditPage(id) {
    this.idService = id;
    this.loadServiceAccount(id);
    return (this.typePage = "edit");
  }
  setCreatePage() {
    return (this.typePage = "create");
  }

  loadServiceAccount(id) {
    this.serviceAccountService
      .getServiceAccountById(id)
      .subscribe((res: serviceAccountInterface) => {
        this.serviceAccount = { ...res };
        this.fillData();
      });
  }

  fillData() {
    this.serviceTypeForm.controls.apps.setValue(this.serviceAccount.apps);
    this.serviceTypeForm.controls.is_gluky_service.setValue(
      this.serviceAccount.is_gluky_service
    );
    this.isGlukyService(this.serviceAccount.is_gluky_service);
    this.serviceTypeForm.controls.enabled.setValue(this.serviceAccount.enabled);

    this.serviceTypeForm.controls.services.setValue(
      this.serviceAccount.services || []
    );

    this.serviceTypeForm.controls.issuer.setValue(
      this.serviceAccount.jwt_info.issuer
    );
    this.serviceTypeForm.controls.audiences.setValue(
      this.serviceAccount.jwt_info.audiences
    );
    this.serviceTypeForm.controls.jwks_uri.setValue(
      this.serviceAccount.jwt_info.jwks_uri
    );
  }
  getApps() {
    this.appsService.findApp().subscribe((res) => {
      this.apps = this.convertToArrayNames(res.data);
      this.loading = false;
    });
  }
  convertToArrayNames(data) {
    const onArray = [];
    data.map((res) => {
      onArray.push(res.id);
    });
    return onArray;
  }

  addAudience(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || "").trim()) {
      const cleanValue = value.trim();
      this.serviceTypeForm.controls.audiences.value.push(cleanValue);
    }
    if (input) {
      input.value = "";
    }
  }
  removeAudience(audience: any): void {
    const index = this.serviceTypeForm.controls.audiences.value.indexOf(
      audience
    );
    if (index >= 0) {
      this.serviceTypeForm.value.audiences.splice(index, 1);
    }
  }

  addApp(event: any): void {
    const value = event;
    if ((value || "").trim()) {
      const cleanValue = value.trim();
      this.serviceTypeForm.controls.apps.value.push(cleanValue);
      this.inputApps.setValue("");
    }
    if (value) {
    }
  }

  removeApp(app: any): void {
    const index = this.serviceTypeForm.controls.apps.value.indexOf(app);
    if (index >= 0) {
      this.serviceTypeForm.value.apps.splice(index, 1);
    }
  }

  selected(event) {
    this.serviceTypeForm.controls.apps.value.push(event.option.value);
  }
  addService(event: any): void {
    const input = event.input;
    const value = event.value;
    if ((value || "").trim()) {
      const cleanValue = value.trim();
      this.serviceTypeForm.controls.services.value.push(cleanValue);
    }
    if (input) {
      input.value = "";
    }
  }

  removeService(service: any): void {
    const index = this.serviceTypeForm.controls.services.value.indexOf(service);
    if (index >= 0) {
      this.serviceTypeForm.value.services.splice(index, 1);
    }
  }

  isGlukyService(event) {
    this.showAllControls = event;
  }

  save() {
    // save account
    this.checkValidForm();
    if (this.validForm) {
      this.loadingFetch = true;
      this.parseData();
    } else {
      this.openMessageError("Verifica los campos ");
    }
  }
  checkValidForm() {
    this.validForm = true;
    Object.keys(this.serviceTypeForm.controls).map((controlName) => {
      const controlProperties = this.serviceTypeForm.get(controlName);
      this.checkcontrols(controlProperties, controlName);
    });
  }

  checkcontrols(controlProperties, controlName) {
    if (controlName === "enabled" && controlName === "is_gluky_service") {
      return false;
    }

    if (controlName === "apps" || controlName === "services") {
      const is_gluky_service = this.serviceTypeForm.get("is_gluky_service");
      if (!is_gluky_service.value) {
        return (this.validForm = controlProperties.value.length > 0);
      }
      return false;
    }
    if (controlProperties.value.length === 0) {
      return (this.validForm = false);
    }
  }
  parseData() {
    const {
      apps,
      issuer,
      audiences,
      is_gluky_service,
      enabled,
      jwks_uri,
      services,
    } = this.serviceTypeForm.controls;

    const data = {
      jwt_info: {
        issuer: issuer.value,
        audiences: audiences.value,
        jwks_uri: jwks_uri.value,
      },
      is_gluky_service: is_gluky_service.value,
      enabled: enabled.value,
      apps: apps.value,
      services: services.value,
    };

    if (this.typePage === "create") {
      return this.createServiceAccount(data);
    }
    return this.editServiceAccount(data);
  }
  createServiceAccount(data) {
    this.serviceAccountService.postServiceAccount(data).subscribe(
      () => {
        this.openMessageSuccess("Cuenta de servicio creada correctamente");
      },
      () => {
        this.openMessageError("Ocurrio un error");
      }
    );
  }
  editServiceAccount(data) {
    this.serviceAccountService
      .putServiceAccount(data, this.idService)
      .subscribe(
        () => this.openMessageSuccess("cuenta de servicio editada"),

        () => {
          this.openMessageError("Ocurrio un error");
        }
      );
  }

  openMessageError(message) {
    this.snackBar(message);
  }
  openMessageSuccess(message) {
    this.snackBar(message);
    this.navigateHome();
  }
  snackBar(message) {
    this.loadingFetch = false;
    this._snackBar.open(message, "ok", {
      duration: 2000,
    });
  }
  navigateHome() {
    setTimeout(() => {
      this.route.navigate(["/pages/service-accounts"]);
    }, 2000);
  }
  cancel() {
    this.route.navigate(["/pages/service-accounts"]);
  }
}
