import { TablesExhibitionsService } from './../../services/tables-exhibitions.service';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { StorageService } from './../../../../services/storage.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataSource } from '@angular/cdk/collections';
import { ITablesExhibitions } from '../tables-exhibitions.model';
import { UntypedFormControl } from '@angular/forms';

export class TablesExhibitionsDataSource extends DataSource<ITablesExhibitions> {
  observer: Observer<ITablesExhibitions[]>;

  refreshData(data: ITablesExhibitions[]) {
    if (this.observer) {
      this.observer.next(data);
    }
  }

  connect(): Observable<ITablesExhibitions[]> {
    return new Observable((observer: Observer<ITablesExhibitions[]>) => {
      this.observer = observer;
    });
  }

  disconnect() {
    this.observer = null;
  }
}

@Component({
  selector: 'app-list-tables-exhibitions',
  templateUrl: './list-tables-exhibitions.component.html',
  styleUrl: './list-tables-exhibitions.component.scss'
})
export class ListTablesExhibitionsComponent {

loading = false;
pageSize = 100;
dataSource = new TablesExhibitionsDataSource();
appIdFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
nameCategory = new UntypedFormControl('');
currentAppId: string;
allowedAppIds: string[];
allowAllAppIds = false;
requiredFields: boolean = true;
data: ITablesExhibitions[];
resultsFound = true;
displayedColumns = ['id'];
hoveredRow: any;
dataSearch:ITablesExhibitions[];
dataFilter:ITablesExhibitions[];
constructor(private storageService : StorageService, private router: Router, private tablesService :TablesExhibitionsService ){

}
  async ngOnInit() {
    await this.initAllowedIds();
   // this.getTablesExhibitions();
  }

  initAllowedIds() {
    // Do not allow any app by default
    this.allowedAppIds = [];
    this.allowAllAppIds = false;
    const account = this.storageService.get('account');
    if (!account) {
      return;
    }
    if (account.roles.includes('super_admin')) {
      // Allow all ids
      this.allowedAppIds = null;
      this.allowAllAppIds = true;
      return;
    }
    const profile = this.storageService.get('profile');
    if (!profile) {
      return;
    }
    this.allowedAppIds = profile.authorized_apps || [];

  }

  onAppSelected(appId: string) {
    this.appIdFilter$.next(appId);
    this.currentAppId = appId;
   this.getTablesExhibitions(this.currentAppId)
  }


  getTablesExhibitions(appId?,name?) {
    if(appId){
      this.requiredFields = true;
    } else {
      this.requiredFields = false;
      this.data = [];
      this.dataSource.refreshData(this.data);
      return;
    }
    this.loading = true;
     this.tablesService.getTables(appId).subscribe((data)=>{

      this.data = data.data;
      this.data = appId ? this.data.filter(x => x.app_id == appId) : this.data;
      this.data = name ? this.data.filter(x => x.id == name) : this.data;
      
      // this.data = this.data.sort(function(a,b){
      //   return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()})

      this.dataSource.refreshData(this.data);
      this.loading= false
      this.resultsFound = this.data.length > 0 ? true : false;

      if(!name){
        this.dataSearch = this.data;
        this.dataFilter = this.data;
      }
    }) 
  }
 

  resetApp() {
    this.currentAppId = null;
  }

  onEdit(item:any) {
    this.router.navigate(['pages', 'exhibition','tables',this.currentAppId,item.id]);
  }
  onCreate(){
    this.router.navigate(['pages', 'exhibition','tables', this.currentAppId]);
  }

  getRowBackgroundColor(row) {
     return row === this.hoveredRow ? '#F5F5F5' : 'transparent'; 
  }


  clearData(value){
    const val = String(value.target.value).toLowerCase();
    if(!val){
      this.getTablesExhibitions(this.currentAppId);
    } else {
      this.dataFilter = this.dataSearch.filter(x => String(x.id).toLowerCase().includes(val))
    }
  }

  onIdOptionSelected(option){
    const name = option.option.value;
    this.dataFilter = name ? this.data.filter(x => x.id == name) : this.data;
    this.dataSource.refreshData(this.dataFilter);
  }
}
