import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AllieRoot, Allie } from "../models/models";
@Injectable({
  providedIn: "root",
})
export class AlliesService {
  private uriConnection: string = environment.pointz_api.uri_allies;
  constructor(private http: HttpClient) {}

  getAllies(): Observable<AllieRoot> {
    return this.http.get<AllieRoot>(this.uriConnection);
  }

  getAllie(idAllie: string) {
    return this.http.get<Allie>(`${this.uriConnection}/${idAllie}`);
  }
  postAllie(newAllie: Allie) {
    return this.http.post<Allie>(`${this.uriConnection}`, newAllie);
  }
  putAllie(allie: Allie) {
    return this.http.put<Allie>(`${this.uriConnection}/${allie.id}`, allie);
  }
}
