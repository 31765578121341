/**
 * Gluky Users API - Test
 * Gluky\'s apps and programs definitions and of the users authorized to access them, including their roles and other authorization information.
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: sistemas@gluky.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent,
  HttpParameterCodec,
} from "@angular/common/http";
import { CustomHttpParameterCodec } from "../encoder";
import { Observable } from "rxjs";

import { AppUserCreatedAnswer } from "../model/appUserCreatedAnswer";
import { AppUserInput } from "../model/appUserInput";
import { AppUserOutput } from "../model/appUserOutput";
import { AppUserPatch } from "../model/appUserPatch";
import { AppUserProfileInput } from "../model/appUserProfileInput";
import { AppUserProfileOutput } from "../model/appUserProfileOutput";
import { AppUsersList } from "../model/appUsersList";
import { CheckAppUserPasswordInput } from "../model/checkAppUserPasswordInput";
import { CheckAppUserPasswordOutput } from "../model/checkAppUserPasswordOutput";
import { ErrorAnswer } from "../model/errorAnswer";
import { UpdatedAnswer } from "../model/updatedAnswer";

import { BASE_PATH, COLLECTION_FORMATS } from "../variables";
import { Configuration } from "../configuration";

@Injectable({
  providedIn: "root",
})
export class AppUsersService {
  protected basePath = "https://users-174020.appspot.com/v1";
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  public encoder: HttpParameterCodec;

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (configuration) {
      this.configuration = configuration;
      this.configuration.basePath =
        configuration.basePath || basePath || this.basePath;
    } else {
      this.configuration.basePath = basePath || this.basePath;
    }
    this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
  }

  /**
   * Check user password.
   * @param appUserId ID of User to check.
   * @param check_user_pass_input
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public checkAppUserPassword(
    appUserId: string,
    check_user_pass_input: CheckAppUserPasswordInput,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<CheckAppUserPasswordOutput>;
  public checkAppUserPassword(
    appUserId: string,
    check_user_pass_input: CheckAppUserPasswordInput,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<CheckAppUserPasswordOutput>>;
  public checkAppUserPassword(
    appUserId: string,
    check_user_pass_input: CheckAppUserPasswordInput,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<CheckAppUserPasswordOutput>>;
  public checkAppUserPassword(
    appUserId: string,
    check_user_pass_input: CheckAppUserPasswordInput,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (appUserId === null || appUserId === undefined) {
      throw new Error(
        "Required parameter appUserId was null or undefined when calling checkAppUserPassword."
      );
    }
    if (check_user_pass_input === null || check_user_pass_input === undefined) {
      throw new Error(
        "Required parameter check_user_pass_input was null or undefined when calling checkAppUserPassword."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (firebase) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // authentication (g2instances) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // authentication (pointz) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<CheckAppUserPasswordOutput>(
      `${this.configuration.basePath}/app_users/${encodeURIComponent(
        String(appUserId)
      )}/check-password`,
      check_user_pass_input,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Create App User.
   * @param user App User Object
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createAppUser(
    user: AppUserInput,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<AppUserCreatedAnswer>;
  public createAppUser(
    user: AppUserInput,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<AppUserCreatedAnswer>>;
  public createAppUser(
    user: AppUserInput,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<AppUserCreatedAnswer>>;
  public createAppUser(
    user: AppUserInput,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (user === null || user === undefined) {
      throw new Error(
        "Required parameter user was null or undefined when calling createAppUser."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (firebase) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.post<AppUserCreatedAnswer>(
      `${this.configuration.basePath}/app_users`,
      user,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * List users.
   * @param page_size Page size for pagination. Default: 10.
   * @param page_start_cursor Pagination cursor, generated automatically by this API, given in next and previuos page links.
   * @param page_end_cursor Pagination cursor, generated automatically by this API, given in next and previuos page links.
   * @param first_page_cursor Pagination cursor, generated automatically by this API, given in next and previuos page links.
   * @param filter
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public findAppUsers(
    page_size?: string,
    page_start_cursor?: string,
    page_end_cursor?: string,
    first_page_cursor?: string,
    filter?: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<AppUsersList>;
  public findAppUsers(
    page_size?: string,
    page_start_cursor?: string,
    page_end_cursor?: string,
    first_page_cursor?: string,
    filter?: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<AppUsersList>>;
  public findAppUsers(
    page_size?: string,
    page_start_cursor?: string,
    page_end_cursor?: string,
    first_page_cursor?: string,
    filter?: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<AppUsersList>>;
  public findAppUsers(
    page_size?: string,
    page_start_cursor?: string,
    page_end_cursor?: string,
    first_page_cursor?: string,
    filter?: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({ encoder: this.encoder });
    if (page_size !== undefined && page_size !== null) {
      queryParameters = queryParameters.set("page_size", <any>page_size);
    }
    if (page_start_cursor !== undefined && page_start_cursor !== null) {
      queryParameters = queryParameters.set(
        "page_start_cursor",
        <any>page_start_cursor
      );
    }
    if (page_end_cursor !== undefined && page_end_cursor !== null) {
      queryParameters = queryParameters.set(
        "page_end_cursor",
        <any>page_end_cursor
      );
    }
    if (first_page_cursor !== undefined && first_page_cursor !== null) {
      queryParameters = queryParameters.set(
        "first_page_cursor",
        <any>first_page_cursor
      );
    }
    if (filter !== undefined && filter !== null) {
      queryParameters = queryParameters.set("filter", <any>filter);
    }

    let headers = this.defaultHeaders;

    // authentication (firebase) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // authentication (g2instances) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // authentication (pointz) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    return this.httpClient.get<AppUsersList>(
      `${this.configuration.basePath}/app_users`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get user roles
   * Returns a list of known user roles.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppUsersRoles(
    observe?: "body",
    reportProgress?: boolean
  ): Observable<Array<string>>;
  public getAppUsersRoles(
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<string>>>;
  public getAppUsersRoles(
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<string>>>;
  public getAppUsersRoles(
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (firebase) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // authentication (g2instances) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // authentication (pointz) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    return this.httpClient.get<Array<string>>(
      `${this.configuration.basePath}/app_users_roles`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update some fields of App User object.
   * Only change the properties in the given object.
   * @param appUserId ID of User to return.
   * @param body Properties to update.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchAppUser(
    appUserId: string,
    body: AppUserPatch,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<UpdatedAnswer>;
  public patchAppUser(
    appUserId: string,
    body: AppUserPatch,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<UpdatedAnswer>>;
  public patchAppUser(
    appUserId: string,
    body: AppUserPatch,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<UpdatedAnswer>>;
  public patchAppUser(
    appUserId: string,
    body: AppUserPatch,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (appUserId === null || appUserId === undefined) {
      throw new Error(
        "Required parameter appUserId was null or undefined when calling patchAppUser."
      );
    }
    if (body === null || body === undefined) {
      throw new Error(
        "Required parameter body was null or undefined when calling patchAppUser."
      );
    }

    let headers = this.defaultHeaders;

    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.patch<UpdatedAnswer>(
      `${this.configuration.basePath}/app_users/${encodeURIComponent(
        String(appUserId)
      )}`,
      body,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get App User by ID.
   * Returns an App User object.
   * @param appUserId ID of User to return.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public retrieveAppUser(
    appUserId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<AppUserOutput>;
  public retrieveAppUser(
    appUserId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<AppUserOutput>>;
  public retrieveAppUser(
    appUserId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<AppUserOutput>>;
  public retrieveAppUser(
    appUserId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (appUserId === null || appUserId === undefined) {
      throw new Error(
        "Required parameter appUserId was null or undefined when calling retrieveAppUser."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (firebase) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // authentication (g2instances) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // authentication (pointz) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    return this.httpClient.get<AppUserOutput>(
      `${this.configuration.basePath}/app_users/${encodeURIComponent(
        String(appUserId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Get App User profile.
   * @param appUserId User ID.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public retrieveAppUserProfile(
    appUserId: string,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<AppUserProfileOutput>;
  public retrieveAppUserProfile(
    appUserId: string,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<AppUserProfileOutput>>;
  public retrieveAppUserProfile(
    appUserId: string,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<AppUserProfileOutput>>;
  public retrieveAppUserProfile(
    appUserId: string,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (appUserId === null || appUserId === undefined) {
      throw new Error(
        "Required parameter appUserId was null or undefined when calling retrieveAppUserProfile."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (firebase) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // authentication (g2instances) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // authentication (pointz) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    return this.httpClient.get<AppUserProfileOutput>(
      `${this.configuration.basePath}/app_users/${encodeURIComponent(
        String(appUserId)
      )}/profile`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update App User object.
   * Change all App User information.
   * @param appUserId ID of User to return.
   * @param user App User Object
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateAppUser(
    appUserId: string,
    user: AppUserInput,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<UpdatedAnswer>;
  public updateAppUser(
    appUserId: string,
    user: AppUserInput,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<UpdatedAnswer>>;
  public updateAppUser(
    appUserId: string,
    user: AppUserInput,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<UpdatedAnswer>>;
  public updateAppUser(
    appUserId: string,
    user: AppUserInput,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (appUserId === null || appUserId === undefined) {
      throw new Error(
        "Required parameter appUserId was null or undefined when calling updateAppUser."
      );
    }
    if (user === null || user === undefined) {
      throw new Error(
        "Required parameter user was null or undefined when calling updateAppUser."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (firebase) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // authentication (g2instances) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // authentication (pointz) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<UpdatedAnswer>(
      `${this.configuration.basePath}/app_users/${encodeURIComponent(
        String(appUserId)
      )}`,
      user,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Update App User profile.
   * Change App User profile.
   * @param appUserId User ID.
   * @param user_profile
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateAppUserProfile(
    appUserId: string,
    user_profile?: AppUserProfileInput,
    observe?: "body",
    reportProgress?: boolean
  ): Observable<UpdatedAnswer>;
  public updateAppUserProfile(
    appUserId: string,
    user_profile?: AppUserProfileInput,
    observe?: "response",
    reportProgress?: boolean
  ): Observable<HttpResponse<UpdatedAnswer>>;
  public updateAppUserProfile(
    appUserId: string,
    user_profile?: AppUserProfileInput,
    observe?: "events",
    reportProgress?: boolean
  ): Observable<HttpEvent<UpdatedAnswer>>;
  public updateAppUserProfile(
    appUserId: string,
    user_profile?: AppUserProfileInput,
    observe: any = "body",
    reportProgress: boolean = false
  ): Observable<any> {
    if (appUserId === null || appUserId === undefined) {
      throw new Error(
        "Required parameter appUserId was null or undefined when calling updateAppUserProfile."
      );
    }

    let headers = this.defaultHeaders;

    // authentication (firebase) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // authentication (g2instances) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // authentication (pointz) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === "function"
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set("Authorization", "Bearer " + accessToken);
    }

    // to determine the Accept header
    const httpHeaderAccepts: string[] = ["application/json"];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set("Accept", httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ["application/json"];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set("Content-Type", httpContentTypeSelected);
    }

    return this.httpClient.put<UpdatedAnswer>(
      `${this.configuration.basePath}/app_users/${encodeURIComponent(
        String(appUserId)
      )}/profile`,
      user_profile,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  public resetUser(appUserId: string, resetData: { newRegisterPassword?: string; erasePersonalData?: boolean }) {
    return this.httpClient.post<any>(
      `${this.configuration.basePath}/app_users/${encodeURIComponent(String(appUserId))}/reset-register`,
      resetData,
      {
        withCredentials: this.configuration.withCredentials,
        observe: 'response',
        reportProgress: false,
      }
    );
  }
}
