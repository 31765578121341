// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#user-filter mat-expansion-panel {
  background-color: #d3dfef;
}

.complex-filter {
  display: grid;
  grid-template-columns: 7fr 1fr;
}

.answer-card {
  display: grid;
  grid-template-columns: 15fr 2fr;
  padding: 16px 0 16px 0;
}

.answer-actions {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  color: lightgray;
}

.answer-actions.hovered {
  color: orange;
}

.answer-field {
  display: grid;
  grid-template-columns: 1fr;
}
.answer-field .field-label {
  color: gray;
}
.answer-field .field-value img {
  max-width: 80px;
  max-height: 60px;
}

.challenge-title {
  grid-column-start: 1;
  grid-column-end: 3;
}

.answer-other-fields-title {
  grid-column-start: 1;
  grid-column-end: 5;
  padding: 8px;
}

.answer-img-fields-title {
  grid-column-start: 1;
  grid-column-end: 5;
}

.date-field {
  width: 13.7875em;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/recognitions/recognitions.component.scss"],"names":[],"mappings":"AAEE;EACE,yBAAA;AADJ;;AAIE;EACE,aAAA;EACA,8BAAA;AADJ;;AAIE;EACE,aAAA;EACA,+BAAA;EACA,sBAAA;AADJ;;AAIE;EACE,aAAA;EACA,qCAAA;EACA,gBAAA;AADJ;;AAIE;EACE,aAAA;AADJ;;AAKE;EACE,aAAA;EACA,0BAAA;AAFJ;AAII;EACE,WAAA;AAFN;AAMM;EACE,eAAA;EACA,gBAAA;AAJR;;AASE;EACE,oBAAA;EACA,kBAAA;AANJ;;AASE;EACE,oBAAA;EACA,kBAAA;EACA,YAAA;AANJ;;AASE;EACE,oBAAA;EACA,kBAAA;AANJ;;AASE;EACE,gBAAA;AANJ","sourcesContent":["\n  \n  #user-filter mat-expansion-panel {\n    background-color: #d3dfef;\n  }\n  \n  .complex-filter {\n    display: grid;\n    grid-template-columns: 7fr 1fr;\n  }\n  \n  .answer-card {\n    display: grid;\n    grid-template-columns: 15fr 2fr;\n    padding: 16px 0 16px 0;\n  }\n  \n  .answer-actions {\n    display: grid;\n    grid-template-columns: repeat(4, 1fr);\n    color: lightgray;\n  }\n  \n  .answer-actions.hovered {\n    color: orange;\n  }\n  \n \n  .answer-field {\n    display: grid;\n    grid-template-columns: 1fr;\n  \n    .field-label {\n      color: gray;\n    }\n  \n    .field-value {\n      img {\n        max-width: 80px;\n        max-height: 60px;\n      }\n    }\n  }\n  \n  .challenge-title {\n    grid-column-start: 1;\n    grid-column-end: 3;\n  }\n  \n  .answer-other-fields-title {\n    grid-column-start: 1;\n    grid-column-end: 5;\n    padding: 8px;\n  }\n  \n  .answer-img-fields-title {\n    grid-column-start: 1;\n    grid-column-end: 5;\n  }\n  \n  .date-field {\n    width: 13.7875em; //15.78125em;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
