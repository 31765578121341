import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  serviceAccountsInterface,
  DataEntity as serviceAccountInterface,
} from "../models/users/service-accounts.model";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";


const hola ='hola'

@Injectable({
  providedIn: "root",
})
export class ServiceAccountsService {
  constructor(public http: HttpClient) {}

  getServiceAccounts(): Observable<serviceAccountsInterface> {
    return this.http.get<serviceAccountsInterface>(
      `${environment.usersApiBasePath}/service-accounts`
    );
  }

  getServiceAccountById(id): Observable<serviceAccountInterface> {
    return this.http.get<serviceAccountInterface>(
      `${environment.usersApiBasePath}/service-accounts/${id}`
    );
  }
  postServiceAccount(data) {
    return this.http.post(
      `${environment.usersApiBasePath}/service-accounts`,
      data
    );
  }
  putServiceAccount(data, id) {
    return this.http.put(
      `${environment.usersApiBasePath}/service-accounts/${id}`,
      data
    );
  }
}
