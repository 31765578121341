import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';
import {CompanyOutput} from '../../users-api';
import {FormControl, Validators} from '@angular/forms';
import {StorageService} from '../../services/storage.service';
import {CompaniesUtilService} from '../../services/companies-util.service';

interface CompanyOption {
  companyId: string;
  companyLabel: string;
}

@Component({
  selector: 'app-company-selector',
  templateUrl: './company-selector.component.html',
  styleUrls: ['./company-selector.component.scss']
})
export class CompanySelectorComponent implements OnInit {
  @Output() onCompanySelected = new EventEmitter<string>();

  @Input()
  set currentCompanyId(companyId: any) {
    this.companySelectControl.setValue(companyId);
    if (companyId) {
      this.setSelectedCompany(companyId);
    }
  }

  @Input() restrictToIds: string[] | undefined;
  @Input() disabled = false;
  companySelectControl = new FormControl(Validators.required);
  companiesOptions: CompanyOption[];

  constructor(
    private storageService: StorageService,
    private companiesUtilService: CompaniesUtilService,
  ) { }

  setSelectedCompany(companyId: string) {
    if (companyId) {
      this.storageService.set('CompanySelectorComponent:lastSelectedCompany', companyId);
    } else {
      this.storageService.delete('CompanySelectorComponent:lastSelectedCompany');
    }
    this.onCompanySelected.emit(companyId);
  }

  async loadCompaniesOptions() {
    try {
      const companies = await this.companiesUtilService.getCompanies();
      this.companiesOptions = companies.map((company: CompanyOutput) => ({
        companyId: company.id,
        companyLabel: `${company.name} - ${company.id_doc} (${company.id}) `
      }));
    } catch (error) {
      console.error('CompanySelectorComponent: getting companies list', error);
    }
    const lastSelected = this.storageService.get('CompanySelectorComponent:lastSelectedCompany');
    if (lastSelected) {
      this.companySelectControl.setValue(lastSelected);
      this.onCompanySelected.emit(lastSelected);
    }
  }

  ngOnInit() {
    this.loadCompaniesOptions();
    this.companySelectControl.markAllAsTouched();
    this.companySelectControl.valueChanges.subscribe(
      (companyId: any) => {
        return this.setSelectedCompany(companyId);
      }
    );
  }

}
