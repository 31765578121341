import { Component, OnInit } from "@angular/core";
import { environment } from "../../../../environments/environment";

import { AuthService } from "../../../services/auth.service";
import { CanjesService } from "../../../services/canjes.service";
import { UntypedFormBuilder, Validators } from "@angular/forms";
import { AlliesService } from "../../../services/allies.service";
import moment from "moment";
import { SalesPointsService } from "../../../services/sales-points.service";
@Component({
  selector: "app-canjes",
  templateUrl: "./canjes.component.html",
  styleUrls: ["./canjes.component.scss"],
})
export class CanjesComponent implements OnInit {
  baseUrl = environment.pointz_api.uri_exchanges;
  canjes;
  loader = false;
  errorMessage;
  exchangeForm;
  showInitialMessage;
  message;
  hasResults = false;
  allies;
  pointSales;
  constructor(
    private authService: AuthService,
    private canjesService: CanjesService,
    private formBuilder: UntypedFormBuilder,
    private alliesService: AlliesService,
    private pointSalesService: SalesPointsService
  ) {
    this.exchangeForm = this.formBuilder.group({
      doc: [""],
      allie: [""],
      pointSale: [""],
      email: [""],
      exchangeId: [""],
    });
    this.alliesService.getAllies().subscribe((res: any) => {
      this.allies = res.data;
    });
    this.pointSalesService.getSalesPoints().subscribe((res: any) => {
      this.pointSales = res.data;
    });
  }
  getCredentials = async () => {
    const credentials = await this.authService.getCredentials(this.baseUrl);
    return credentials;
  };
  clearValues() {
    this.exchangeForm.controls.doc.setValue("");
    this.exchangeForm.controls.allie.setValue("");
    this.exchangeForm.controls.pointSale.setValue("");
    this.exchangeForm.controls.email.setValue("");
    this.exchangeForm.controls.exchangeId.setValue("");
    this.canjes = [];
    this.hasResults = false;
    this.message = "";
    this.showInitialMessage = false;
  }

  getExchanges = async () => {
    const {
      doc,
      allie,
      pointSale,
      email,
      exchangeId,
    } = this.exchangeForm.value;
    if (
      !doc.length &&
      !allie.length &&
      !pointSale.length &&
      !email.lenght &&
      !exchangeId.length
    ) {
      this.showInitialMessage = true;
      this.message = "Ingresa un valor para inicia la búsqueda";
      return (this.loader = false);
    }
    this.hasResults = false;
    this.loader = true;
    this.canjes = [];
    this.message = "";
    this.showInitialMessage = false;
    this.canjesService
      .getExchanges(doc, allie, pointSale, email, exchangeId)
      .subscribe((res: any) => {
        if (res.data.length == 0) {
          this.showInitialMessage = true;
          this.message = "No se encontraron resultados para su búsqueda";
          return (this.loader = false);
        } else {
          this.hasResults = true;

          this.canjes = this.sortByDate(res.data);

          this.loader = false;
        }
      });
  };
  ngOnInit() {}

  convertDate(date) {
    return moment(date).format("YYYY MM DD");
  }

  removeLastDigits(value, digit, mount) {
    let stringValue = value.toString();
    stringValue = stringValue.slice(0, stringValue.length - mount);

    return stringValue;
  }

  sortByDate(data) {
    const dataSorted = data.sort((a, b) => {
      return a.datetime < b.datetime ? 1 : b.datetime < a.datetime ? -1 : 0;
    });
    return dataSorted;
  }
}
