import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

import {
  GetAllProgramsOut,
  Program,
  Response as OrdersResponse,
  ProgramOutput,
} from '../models/orders/models';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  baseUrl = environment.orders_api.base_url;

  constructor(private httpClient: HttpClient) { }

  listPrograms(filterParams: any): Observable<GetAllProgramsOut> {
    const params = new HttpParams({ fromObject: filterParams });
    return this.httpClient.get<GetAllProgramsOut>(`${this.baseUrl}/programs`, { params });
  }

  createProgram(data: Program): Observable<OrdersResponse> {
    return this.httpClient.post<OrdersResponse>(`${this.baseUrl}/programs`, { ...data });
  }

  getProgram(id: string): Observable<ProgramOutput> {
    return this.httpClient.get<ProgramOutput>(`${this.baseUrl}/programs/${id}`);
  }

  putProgram(data: ProgramOutput): Observable<ProgramOutput> {
    return this.httpClient.put<ProgramOutput>(
      `${this.baseUrl}/programs/${data.orderProgramId}`, {
        ...data,
      }
    );
  }

  getOrdersByUserId(userId: string) {
    return this.httpClient.get(
      `${this.baseUrl}/user-orders/${userId}?desc=createdAt`
    );
  }

  cancelOrderByOrderAndDeliveryId(orderId: string, body: any) {
    return this.httpClient.post(
      `${this.baseUrl}/deliveries/${orderId}/soft-cancel`,
      body
    );    
  }
  
}
