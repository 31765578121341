import { Component, NgZone, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from '../services/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StorageService } from '../services/storage.service';
const helperjwt = new JwtHelperService();
import 'firebase';
import 'firebase/auth';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [],
})
export class LoginComponent implements OnInit {
  loader: Boolean = false;
  showSelectorUsers = false;
  usersOnSelector = [];
  user = '';
  myForm: FormGroup;
  constructor(
    private auth: AuthService,
    private snackBar: MatSnackBar,
    private storageService: StorageService,
    private router: Router,
    private formBuilder: FormBuilder
  ) {}

  async ngOnInit() {
    this.myForm = this.formBuilder.group({
      email: new FormControl('',[
        Validators.required,
        Validators.pattern("^[a-zA-Z0-9#$%&'*/=?^_+-`{|}~]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")])
      });
  }

  async processUserCredential(userState: firebase.auth.UserCredential) {
    this.loader = true;
    if (userState.user) {
      // Check if email changed
      if (userState.additionalUserInfo
         && userState.additionalUserInfo.profile
         && userState.user.email) {
        const profile = userState.additionalUserInfo.profile as any;
        if (profile.email && profile.email !== userState.user.email) {
          try {
            userState.user.updateEmail(profile.email);
          } catch (err) {
            console.error('While trying to update Firebase account email' + err);
          }
        }
      }
      try {
        const responseFromUsers = await this.auth.getGNAccessTokenNoCache();
        if (Array.isArray(responseFromUsers)) {
          return this.enableSelectorForUsers(responseFromUsers);
        }
        this.auth.getIdToken(true); // Reload Firebase claims
        this.loader = false;
        this.router.navigate(['home']);
      } catch (err) {
        console.log(err);
        const msg = 'Acceso denegado';
        this.snackBar.open(msg, 'ok');
        this.loader = false;
      }
    } else {
      const user = this.auth.getUser();
      user.subscribe((result)=>{
        if (result?.uid) {
          this.router.navigate(['home']);
        }
        console.log('User else : ', result);
      })
    }
    this.loader = false;
  }

  async googleLogin() {
    this.loader = true;
    try {
      const userCred = await this.auth.googleLogin();
      this.processUserCredential(userCred);
    } catch (err) {
      this.loader = false;
      console.error('LoginComponent: googleLogin', err);
      const msg = 'Acceso denegado';
      this.snackBar.open(msg, 'ok');
    }
  }

  enableSelectorForUsers(users: string[]) {
    this.loader = false;
    this.showSelectorUsers = true;
    return (this.usersOnSelector = users);
  }

  async loginWithUser() {
    if (this.user.length === 0) {
      this.snackBar.open('selecciona un usuario para continuar', 'ok');
      return;
    }
    try {
      this.loader = true;
      this.auth.setUsername(this.user);
      const tokenUsers = await this.auth.getGNAccessTokenNoCache();
      this.loader = false;
      if (typeof tokenUsers === 'string') {
        this.auth.getIdToken(true); // Reload Firebase claims
        this.router.navigate(['home']);
        return;
      }
      console.error('LoginComponent: loginWithUser: Unexpected token value', tokenUsers);
      this.snackBar.open('Por favor intente de nuevo', 'ok');
    } catch (err) {
      console.error(err);
      this.snackBar.open('Error en la comunicacion', 'ok');
      this.loader = false;
    }
  }



  async onSubmit(form: FormGroup) {
    try {
      console.log('Valid?', form.valid); // true or false
      console.log('values', form.value);
      if (form.valid) {
        await this.auth.SignInEmailAndPassword(form.value);
      }
    } catch (error) {
      console.log("error login with user and password", error);
    }
  }
}
