import { Component, OnInit, Input } from '@angular/core';
import { MainSidenavService } from '../../main-sidenav.service';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import firebase from 'firebase/app';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
})
export class ContainerComponent implements OnInit {
  @Input() name: string;
  user: firebase.User;
  loading = false;
  constructor(
    public mainSidenavService: MainSidenavService,
    private auth: AuthService,
    public route: Router
  ) {}

  ngOnInit() {
    this.getUser();
  }

  logout() {
    this.auth.logOut();
    this.route.navigate(['/login']);
  }

  getUser() {
    return this.auth.getUser().subscribe((user: firebase.User) => {
      this.user = user;
    });
  }
}
