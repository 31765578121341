import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ContainerComponent } from "./container.component";
import { AppCommonModule } from "../../app-common/app-common.module";

@NgModule({
  declarations: [ContainerComponent],
  imports: [CommonModule, AppCommonModule],
  exports: [ContainerComponent],
})
export class ContainerModule {}
