import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CanjesService {
  baseUrl = environment.pointz_api.uri_exchanges;
  constructor(private http: HttpClient) {}

  getExchanges(doc, allie, pointSale, email, exchangeId) {
    let params = {};

    if (doc.length) {
      params = {
        ...params,
        accountId: doc,
      };
    }
    if (pointSale.length) {
      params = {
        ...params,
        "commerceInfo.locationName": pointSale,
      };
    }
    if (allie.length) {
      params = {
        ...params,
        "commerceInfo.commerceAllyId": allie,
      };
    }
    if (email.length) {
      params = {
        ...params,
        email,
      };
    }
    if (exchangeId.length) {
      params = {
        ...params,
        exchangeId,
      };
    }

    const url = this.baseUrl;
    console.log(params);
    return this.http.get(url, {
      params,
    });
  }
}
