/**
 * Gluky Users API - Test
 * Gluky\'s apps and programs definitions and of the users authorized to access them, including their roles and other authorization information.
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: sistemas@gluky.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent, HttpParameterCodec
} from '@angular/common/http';
import { CustomHttpParameterCodec } from '../encoder';
import { Observable } from 'rxjs';

import { CreatedAnswer } from '../model/createdAnswer';
import { ErrorAnswer } from '../model/errorAnswer';
import { ProgramBase } from '../model/programBase';
import { ProgramFull } from '../model/programFull';
import { ProgramsList } from '../model/programsList';
import { UpdatedAnswer } from '../model/updatedAnswer';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';


@Injectable({
    providedIn: 'root'
})
export class ProgramsService {

    protected basePath = 'https://users-174020.appspot.com/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    /**
     * Record new program
     * @param companyId
     * @param program Program object.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProgram(companyId: string, program: ProgramBase, observe?: 'body', reportProgress?: boolean): Observable<CreatedAnswer>;
    public createProgram(companyId: string, program: ProgramBase, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreatedAnswer>>;
    public createProgram(companyId: string, program: ProgramBase, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreatedAnswer>>;
    public createProgram(companyId: string, program: ProgramBase, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling createProgram.');
        }
        if (program === null || program === undefined) {
            throw new Error('Required parameter program was null or undefined when calling createProgram.');
        }

        let headers = this.defaultHeaders;

        // authentication (firebase) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (g2instances) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (pointz) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CreatedAnswer>(`${this.configuration.basePath}/companies/${encodeURIComponent(String(companyId))}/programs`,
            program,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    public findAppIdPrograms(
      appId: string,
      observe: any = 'body',
      reportProgress: boolean = false
    ): Observable<any> {
        if (appId === null || appId === undefined) {
            throw new Error('Required parameter appId was null or undefined when calling findCompanyPrograms.');
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        let headers = this.defaultHeaders;

        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get<ProgramsList>(`${this.configuration.basePath}/apps/${encodeURIComponent(String(appId))}/programs`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * List company\&#39;s programs
     * @param companyId
     * @param page_size Page size for pagination. Default: 10.
     * @param page_start_cursor Pagination cursor, generated automatically by this API, given in next and previuos page links.
     * @param page_end_cursor Pagination cursor, generated automatically by this API, given in next and previuos page links.
     * @param first_page_cursor Pagination cursor, generated automatically by this API, given in next and previuos page links.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public findCompanyPrograms(companyId: string, page_size?: string, page_start_cursor?: string, page_end_cursor?: string, first_page_cursor?: string, observe?: 'body', reportProgress?: boolean): Observable<ProgramsList>;
    public findCompanyPrograms(companyId: string, page_size?: string, page_start_cursor?: string, page_end_cursor?: string, first_page_cursor?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProgramsList>>;
    public findCompanyPrograms(companyId: string, page_size?: string, page_start_cursor?: string, page_end_cursor?: string, first_page_cursor?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProgramsList>>;
    public findCompanyPrograms(companyId: string, page_size?: string, page_start_cursor?: string, page_end_cursor?: string, first_page_cursor?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling findCompanyPrograms.');
        }

        let queryParameters = new HttpParams({ encoder: this.encoder });
        if (page_size !== undefined && page_size !== null) {
            queryParameters = queryParameters.set('page_size', <any>page_size);
        }
        if (page_start_cursor !== undefined && page_start_cursor !== null) {
            queryParameters = queryParameters.set('page_start_cursor', <any>page_start_cursor);
        }
        if (page_end_cursor !== undefined && page_end_cursor !== null) {
            queryParameters = queryParameters.set('page_end_cursor', <any>page_end_cursor);
        }
        if (first_page_cursor !== undefined && first_page_cursor !== null) {
            queryParameters = queryParameters.set('first_page_cursor', <any>first_page_cursor);
        }

        let headers = this.defaultHeaders;

        // authentication (firebase) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (g2instances) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (pointz) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get<ProgramsList>(`${this.configuration.basePath}/companies/${encodeURIComponent(String(companyId))}/programs`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieve a program.
     * @param companyId
     * @param programId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public retrieveProgram(companyId: string, programId: string, observe?: 'body', reportProgress?: boolean): Observable<ProgramFull>;
    public retrieveProgram(companyId: string, programId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProgramFull>>;
    public retrieveProgram(companyId: string, programId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProgramFull>>;
    public retrieveProgram(companyId: string, programId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling retrieveProgram.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling retrieveProgram.');
        }

        let headers = this.defaultHeaders;

        // authentication (firebase) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (g2instances) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (pointz) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        return this.httpClient.get<ProgramFull>(`${this.configuration.basePath}/companies/${encodeURIComponent(String(companyId))}/programs/${encodeURIComponent(String(programId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update a program.
     * @param companyId
     * @param programId
     * @param program Program object.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProgram(companyId: string, programId: string, program: ProgramBase, observe?: 'body', reportProgress?: boolean): Observable<UpdatedAnswer>;
    public updateProgram(companyId: string, programId: string, program: ProgramBase, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdatedAnswer>>;
    public updateProgram(companyId: string, programId: string, program: ProgramBase, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdatedAnswer>>;
    public updateProgram(companyId: string, programId: string, program: ProgramBase, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling updateProgram.');
        }
        if (programId === null || programId === undefined) {
            throw new Error('Required parameter programId was null or undefined when calling updateProgram.');
        }
        if (program === null || program === undefined) {
            throw new Error('Required parameter program was null or undefined when calling updateProgram.');
        }

        let headers = this.defaultHeaders;

        // authentication (firebase) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (g2instances) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (pointz) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<UpdatedAnswer>(`${this.configuration.basePath}/companies/${encodeURIComponent(String(companyId))}/programs/${encodeURIComponent(String(programId))}`,
            program,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
