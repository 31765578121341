import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { PointSale, PointsSaleRoot } from "../models/models";

@Injectable({
  providedIn: "root",
})
export class SalesPointsService {
  private uriConnection: string = environment.pointz_api.uri_sales_points;
  constructor(private http: HttpClient) {}

  getSalesPoints(): Observable<PointsSaleRoot> {
    return this.http.get<PointsSaleRoot>(this.uriConnection);
  }
  getPointSale(id: string) {
    const uri = `${this.uriConnection}/${id}`;
    return this.http.get(uri);
  }
  postPointSale(newPointSale: PointSale) {
    return this.http.post<PointSale>(`${this.uriConnection}`, newPointSale);
  }
  putPointSale(pointSale: PointSale) {
    return this.http.put<PointSale>(
      `${this.uriConnection}/${pointSale.id}`,
      pointSale
    );
  }
}
