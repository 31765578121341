import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-exhibitions-report',
  templateUrl: './exhibitions-report.component.html',
  styleUrls: ['./exhibitions-report.component.scss']
})
export class ExhibitionsReportComponent implements OnInit {
  width = (window.innerWidth - 30)
  constructor() { }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const width = event.target.innerWidth;
    this.width = (width - 30)
  }
  ngOnInit() {
  }
}
