import { Injectable } from '@angular/core';

@Injectable()
export class AutoIdService {

  constructor() { }

  buildAutoId(value: string) {
    return value
      .normalize('NFD').replace(/[\u0300-\u036f]/g, "")
      .replace(/[^\w]+/g, '_')
      .toLowerCase();
  }
}
