import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  transform(value: string | Date, format: string = 'yyyy-MM-dd hh:mm:ss a'): string | null {
    if (!value) return null;
    return formatDate(value, format, 'en-US');
  }
}
