import { ExhibitionsService } from './../../services/exhibitions.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { IFormExhibitions, field } from '../exhibition.model';
import { ITablesExhibitions } from '../../tables/tables-exhibitions.model';
import { TablesExhibitionsService } from '../../services/tables-exhibitions.service';
import Swal from 'sweetalert2'
import { HttpErrorResponse } from '@angular/common/http';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER, TAB } from '@angular/cdk/keycodes';

// fields options for select  
const OPTIONS_FORM_TYPES = {
  select_table: 'select_table',
  autocomplete: 'autocomplete',
  photo_checkbox: 'photo_checkbox',
  select: 'select',
  select_checkbox: 'select_checkbox',
  text: 'text',
  date: 'date',
  camera: 'camera',
  file: 'file',
  hidden: 'hidden',

}

//label for field type 
const LABEL_FIELD_TYPES = {
  table: 'Tabla',
  image_source: 'Source Image',
  source: 'Source',
  source_field: 'Source Field',
  source_col: 'Source Col',
  value_col: 'Valor Col',
  value_field:'Valor del campo',
  camera_config: 'Configuracion camara',
  file_config: 'Configuracion Archivo'

}

// types fields enable for relations ( select with others fields ).
const FIELD_TYPES = {
  table: 'table',
  image_source: 'image_source',
  source: 'source',
  source_field: 'source_field',
  source_col: 'source_col',
  value_col: 'value_col',
  min: 'min',
  max: 'max',
  value_field:'value_field',
  camera_config: 'camera_config',
  file_config: 'file_config',
  options:'options'
}

//relations de fields with other field required
const relations = {
  select_table: [FIELD_TYPES.table],
  autocomplete: [FIELD_TYPES.table],
  photo_checkbox: [FIELD_TYPES.table, FIELD_TYPES.source, FIELD_TYPES.source_col, FIELD_TYPES.source_field, FIELD_TYPES.value_col, FIELD_TYPES.image_source],
  select: [FIELD_TYPES.options,],
  select_checkbox: [FIELD_TYPES.table],
  text: [],
  date: [],
  hidden: [FIELD_TYPES.value_field],
  camera: [FIELD_TYPES.camera_config],
  file: [FIELD_TYPES.file_config],
}

const ERROR_CODES = {
  INVALID_FORM : 'Formulario Invalido'
}
@Component({
  selector: 'app-form-exhibitions',
  templateUrl: './form-exhibitions.component.html',
  styleUrl: './form-exhibitions.component.scss'
})
export class FormExhibitionsComponent implements OnInit {
  loading
  currentId: string
  mode: 'create' | 'edit' = 'create'
  appId: string;
  dataForm: IFormExhibitions
  OPTIONS_FORM_TYPES_CODES = Object.keys(OPTIONS_FORM_TYPES)
  OPTIONS_FORM_TYPES = OPTIONS_FORM_TYPES
  LABEL_FIELD_TYPES_CODES = LABEL_FIELD_TYPES
  fieldTypes = FIELD_TYPES
  tables: ITablesExhibitions[];
  uriSeparatorKeyCodes = [ENTER, COMMA, TAB];
  constructor(private route: ActivatedRoute, private exhibitionsService: ExhibitionsService, private router: Router, private tablesService: TablesExhibitionsService) { }

  ngOnInit(): void {
    this.appId = this.route.snapshot.paramMap.get('appId');
    const id = this.route.snapshot.paramMap.get('id');
    this.currentId = id;
    if (id && this.appId) {
      this.getForm(id);
    }
    this.getTables();
  }

  async getTables() {
    try {
      if (this.appId) {
        this.tables = (await this.tablesService.getTables(this.appId).toPromise()).data;
      }
    } catch (error) {
      console.error("getTables error: ", error)
    }
  }

  addField() { 
    const newField: field = {
      type: "text",
      name: "",
      value_field: "",
      label_field: "",
      validations: {
        required: false
      },
      file_config: {},
      camera_config: {}


    };
    this.dataForm?.fields?.push(newField);
  }

  validateOptionEnable(type, options) {
    return (relations[type] as Array<string>).includes(options)
  }

  removeField(index, field) {
    this.dataForm.fields.splice(index, 1)
  }

  async getForm(id) {
    try {
      const data = await this.exhibitionsService.getForm(this.appId, id).toPromise();
      const dataRefactor = { ...data }
      dataRefactor.fields = { ...data }.fields.map((element)=>{
        element.file_config = element.file_config || {};
        element.camera_config = element.camera_config || {};
        return element
      })
      this.dataForm = dataRefactor;

    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        if (error.error.code == 'NOT_FOUND') {
          this.dataForm = {
            fields: []
          }
        }

      }
      console.error("Error en consulta de Form: ", error);

    }
  }


  goToList() {
    this.router.navigate(['pages', 'exhibition', 'forms']);
  }

  async OKClicked() {
    const valid = await this.validateFields();
    if (!valid) {
      return
    };
    try {
      const created = await this.exhibitionsService.putForm(this.dataForm, this.appId).toPromise()
      if (created) {
        const text = this.mode == 'create' ? 'Creado correctamente' : 'Editado Correctamente'
        Swal.fire('', text, 'success');
        this.goToList();
      }
    } catch (error) {
      this.handlerError(error);
    }
  }

  handlerError(error:HttpErrorResponse){
    console.error("error: ", error.error)

    Swal.fire(ERROR_CODES[error.error.code] || '', error.error.message, 'error');
  }

 async validateFields():Promise<boolean> {
  return new Promise((resolve)=>{
    this.dataForm.fields.forEach((element,index)=>{
      const fieldTypeRelation = relations[element.type];
      fieldTypeRelation.forEach((fieldsRelation)=>{
        const fieldValue = element[fieldsRelation];
        
        if (fieldValue) {
          if((typeof fieldValue) == 'object' ){
            const objectKeys = Object.keys(fieldValue);
            if (!(objectKeys.length)) {
              Swal.fire('', `El campo ${index + 1 } en la propiedad '${this.LABEL_FIELD_TYPES_CODES[fieldsRelation]||fieldsRelation}' no tiene informacion y esta es requerida`, 'info');
              resolve(false) ;
            } 
            objectKeys.forEach((data,indexKeys)=>{
              if(!fieldValue[data]){
                Swal.fire('', `El campo ${index + 1 } en la propiedad '${this.LABEL_FIELD_TYPES_CODES[fieldsRelation]||fieldsRelation}' en el valor '${objectKeys[indexKeys]}' no tiene informacion y esta es requerida`, 'info');
                resolve(false) ; 
              }
            })
          } 
        }else {
          console.error('field without value: ',element);
          Swal.fire('', `El campo ${index +1 } en la propiedad '${this.LABEL_FIELD_TYPES_CODES[fieldsRelation]||fieldsRelation }' no tiene informacion y esta es requerida`, 'info');
          resolve(false) ;
        }
      })
    })
    return resolve(true)
  })
   
  }

 
  addFieldEnumItem(event: MatChipInputEvent, field) {
    const value = event.value.trim();
    const input = event.chipInput.inputElement;

    if (value && input) {
      if (!field.options) field.options = [];
      
      field.options.push(value);
      input.value = '';
    }
  }
}



