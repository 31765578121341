/**
 * Gluky Users API - Test
 * Gluky\'s apps and programs definitions and of the users authorized to access them, including their roles and other authorization information. 
 *
 * The version of the OpenAPI document: 1.2.0
 * Contact: sistemas@gluky.co
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { AuthMatchInput } from '../model/authMatchInput';
import { AuthMatchOutput } from '../model/authMatchOutput';
import { ErrorAnswer } from '../model/errorAnswer';
import { InlineObject } from '../model/inlineObject';
import { InlineObject1 } from '../model/inlineObject1';
import { OpenIDLogoutValidateOutput } from '../model/openIDLogoutValidateOutput';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

    protected basePath = 'https://users-174020.appspot.com/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {

        if (configuration) {
            this.configuration = configuration;
            this.configuration.basePath = configuration.basePath || basePath || this.basePath;

        } else {
            this.configuration.basePath = basePath || this.basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }



    /**
     * Match request token with app_user
     * Match request token (Authorization header) with an app_user. Request object must specify *app_id* and optionaly *username* and/or *password*. If no *username* is given an user will be found by verified email or phone in request token claims. If an app_user is found a new *id_token* is returned specifying application and username in its claims. If no username is requested and multiple users match verified email or phone then a list of users is returned. In which case client must specify which  username to use in a new request in order to obtain a JWT token for  that user.
     * @param body User information
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public authMatch(body: AuthMatchInput, observe?: 'body', reportProgress?: boolean): Observable<AuthMatchOutput>;
    public authMatch(body: AuthMatchInput, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AuthMatchOutput>>;
    public authMatch(body: AuthMatchInput, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AuthMatchOutput>>;
    public authMatch(body: AuthMatchInput, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling authMatch.');
        }

        let headers = this.defaultHeaders;

        // authentication (firebase) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (g2instances) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (pointz) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<AuthMatchOutput>(`${this.configuration.basePath}/auth/match`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Validate OpenID Connect Logout Request
     * Validate logout request and provide logout information for accounts app. Verifies logout_redirect_uri against URIs registered for app_id specified in Authorization token. 
     * @param OpenIDLogoutData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public openIDLogoutRequestValidate(OpenIDLogoutData: InlineObject1, observe?: 'body', reportProgress?: boolean): Observable<OpenIDLogoutValidateOutput>;
    public openIDLogoutRequestValidate(OpenIDLogoutData: InlineObject1, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OpenIDLogoutValidateOutput>>;
    public openIDLogoutRequestValidate(OpenIDLogoutData: InlineObject1, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OpenIDLogoutValidateOutput>>;
    public openIDLogoutRequestValidate(OpenIDLogoutData: InlineObject1, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (OpenIDLogoutData === null || OpenIDLogoutData === undefined) {
            throw new Error('Required parameter OpenIDLogoutData was null or undefined when calling openIDLogoutRequestValidate.');
        }

        let headers = this.defaultHeaders;

        // authentication (firebase) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (g2instances) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (pointz) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<OpenIDLogoutValidateOutput>(`${this.configuration.basePath}/auth/openid-logout-validate`,
            OpenIDLogoutData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Validate OpenID Connect Authentication Request
     * Used by accounts frontend to validate authentication requests made by other applications as specified in [OpenID Connect specification](https://openid.net/specs/openid-connect-core-1_0.html#ImplicitFlowAuth) 
     * @param openIDData 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public openIDRequestValidate(openIDData: InlineObject, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public openIDRequestValidate(openIDData: InlineObject, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public openIDRequestValidate(openIDData: InlineObject, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public openIDRequestValidate(openIDData: InlineObject, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (openIDData === null || openIDData === undefined) {
            throw new Error('Required parameter openIDData was null or undefined when calling openIDRequestValidate.');
        }

        let headers = this.defaultHeaders;

        // authentication (firebase) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (g2instances) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // authentication (pointz) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.configuration.basePath}/auth/openid-validate`,
            openIDData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
