import { PERMISSIONS } from './../../../../services/permissions/permissions.service';
import { Component, OnInit } from '@angular/core';
import { PointsService } from '../../../../services/points.service';
import {
  AccountOutputList,
  AccountOutput,
} from '../../../../models/points/models';
import {HttpErrorResponse} from '@angular/common/http';
import {DataSource} from '@angular/cdk/collections';
import {Observer, Observable} from 'rxjs';
import Swal from 'sweetalert2';
import {AppUserOutput} from '../../../../users-api';

export class AccountsDataSource extends DataSource<AccountOutput> {
  observer: Observer<AccountOutput[]>;

  refreshData(data: AccountOutput[]) {
    console.log('Refresh Data:  ', data);
    
    if (this.observer) {
      this.observer.next(data);
    }
  }

  connect(): Observable<AccountOutput[]> {
    return new Observable((obs: Observer<AccountOutput[]>) => {
      this.observer = obs;
    });
  }

  disconnect() {
    this.observer = null;
  }
}

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss'],
})
export class AccountsComponent implements OnInit {
  PERMISSIONS = PERMISSIONS;
  accounts: AccountOutput[] = [];
  loader = false;
  dataSource = new AccountsDataSource();
  displayedColumns: string[] = ['id', 'balance', 'type', 'owner', 'edit'];
  accountsFound = true;
  selectedUser: AppUserOutput;

  constructor(private pointsService: PointsService) {}

  ngOnInit() {
  }

  onUserSelected(user: AppUserOutput) {
    this.loader = true;
    this.accountsFound = true;
    this.selectedUser = user;
    const query = {
      'owner.id': user.id,
    };
    this.pointsService.listAccounts(query).subscribe(
      (ans: AccountOutputList) => {
        this.loader = false;
        this.accountsFound = ans.data && ans.data.length > 0;
        console.log('this.pointsService.listAccounts(query)', ans);
        
        this.dataSource.refreshData(ans.data);
      },
      (error: HttpErrorResponse) => {
        console.error('AccountsComponent: searching accounts', error);
        this.loader = false;
        Swal.fire({
          icon: 'error',
          title: 'Error buscando',
          text: 'Por favor intente de nuevo'
        });
      }
    );
  }

}
