import { PERMISSIONS } from './../../../../services/permissions/permissions.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import moment from 'moment';
import Swal from 'sweetalert2';
import { ProgramBase, ProgramFull } from '../../../../users-api';
import { Countries } from '../../../../countries';
import { AutoIdService } from '../../../../auto-id.service';
import { ProgramBaseLimitRedemption } from '../../../../../app/users-api/model/programBaseLimitRedemption';
import { ProgramsUtilService } from '../../../../../app/services/programs-util.service';

@Component({
  selector: 'app-programs-editor',
  templateUrl: './programs-editor.component.html',
  styleUrls: ['./programs-editor.component.scss'],
})
export class ProgramsEditorComponent implements OnInit, OnChanges {

  @ViewChild('minDateRedemptionLimits', { static: false }) minDateRedemptionLimits: ElementRef;
  @ViewChild('maxDateRedemptionLimits', { static: false }) maxDateRedemptionLimits: ElementRef;

  @Input() program: ProgramBase;
  @Input() mode = 'create';

  @Output() onOK = new EventEmitter<ProgramBase>();
  @Output() onCancel = new EventEmitter<void>();

  programForm: UntypedFormGroup;
  disableAutoID = false;
  autoIDValue = true;
  currencies = ['COP', 'MXN', 'CPL', 'USD'];
  countries = Countries.COUNTRIES;
  appId = '';
  //Limites

  data_redemption_limit: ProgramBaseLimitRedemption = {
    enabled: false,
    min_user_points_limit: 0,
    max_date: null,
    min_date: null
  };
  clon_data_redemption_limit: ProgramBaseLimitRedemption;
  today = moment().format('YYYY-MM-DDTHH:mm');
  PERMISSIONS = PERMISSIONS
  constructor(
    private fb: UntypedFormBuilder,
    private autoId: AutoIdService,
    private programsUtilService: ProgramsUtilService
  ) {
    this.programForm = this.fb.group({
      name: ['', Validators.required],
      program_id: [
        { value: '', disabled: true },
        [Validators.required, Validators.pattern(/^\w+$/)]
      ],
      auto_id: true,
      country: ['', [Validators.required, Validators.pattern(/^[A-Z]{2}$/)]],
      points_account_type: [
        '',
        [Validators.required, Validators.pattern(/^\w+$/)],
      ],
      budget: this.fb.group({
        enabled: false,
        value: this.fb.group({
          amount: [{ value: 0, disabled: true }, [Validators.pattern(/^\d+$/)]],
          currency_code: [
            { value: '', disabled: true },
            [Validators.pattern(/^[A-Z]{3}$/)],
          ],
        }),
        start_datetime: { value: '', disabled: true },
        start_date: { value: '', disabled: true },
        start_time: { value: '', disabled: true },
      }),
      erp: this.fb.group({
        program_code: ['', Validators.required],
        document_type: '',
        consecutive_type: '',
        wharehouse: '',
        currency: '',
        currency_invoice: '',
        company: '',
      }),
      centralorder: this.fb.group({
        program_offline: false,
        point_value_program: [{ value: 0, disabled: true }],
        program_gn2: false,
        days_dispatch: 0,
      }),
      user_type_id: [''],
      user_type_label: [''],
      opened_sign_in: [false],
      disable_exchanges: false,
      nequi: this.fb.group({
        enabled: false,
        factor: [{ value: 0, disabled: true }],
        transfer_cost_cents: [{ value: 0, disabled: true }, [Validators.pattern(/^\d+$/)]],
        transfer_tax_percent: [{ value: 0, disabled: true }, [Validators.pattern(/^\d+$/)]],
        min_transfer_cents: [{ value: 0, disabled: true }, [Validators.pattern(/^\d+$/)]],
        max_transfer_cents: [{ value: 0, disabled: true }, [Validators.pattern(/^\d+$/)]],
        max_n_transfers: [{ value: 0, disabled: true }, [Validators.pattern(/^\d+$/)]],
      }),
      redemption_limit: this.fb.group({
        enabled: false,
        min_user_points_limit: [0],
        min_date: [],
        max_date: []
      })
    });
    // Form behavior
    const name = this.programForm.get('name');
    const auto_id = this.programForm.get('auto_id');
    const program_id = this.programForm.get('program_id');
    name.valueChanges.subscribe((value: string) => {
      if (auto_id.value && this.mode === 'create') {
        program_id.setValue(this.autoId.buildAutoId(value));
      }
    });
    auto_id.valueChanges.subscribe((value: boolean) => {
      if (this.mode === 'create') {
        if (value) {
          program_id.setValue(this.autoId.buildAutoId(name.value));
          program_id.disable();
          return;
        }
        program_id.enable();
      }
    });
    this.programForm
      .get('budget.enabled')
      .valueChanges.subscribe((enabled: boolean) => {
        const amount = this.programForm.get('budget.value.amount');
        const currency_code = this.programForm.get(
          'budget.value.currency_code'
        );
        const start_date = this.programForm.get('budget.start_date');
        const start_time = this.programForm.get('budget.start_time');
        if (enabled) {
          amount.enable();
          currency_code.enable();
          start_date.enable();
          start_time.enable();
          return;
        }
        amount.disable();
        currency_code.disable();
        start_date.disable();
        start_time.disable();
      });

    this.programForm
      .get('nequi.enabled')
      .valueChanges.subscribe((enabled: boolean) => {
        const factor = this.programForm.get('nequi.factor');
        const transfer_cost_cents = this.programForm.get('nequi.transfer_cost_cents');
        const transfer_tax_percent = this.programForm.get('nequi.transfer_tax_percent');
        const min_transfer_cents = this.programForm.get('nequi.min_transfer_cents');
        const max_transfer_cents = this.programForm.get('nequi.max_transfer_cents');
        const max_n_transfers = this.programForm.get('nequi.max_n_transfers');
        if (enabled) {
          factor.enable();
          transfer_cost_cents.enable();
          transfer_tax_percent.enable();
          min_transfer_cents.enable();
          max_transfer_cents.enable();
          max_n_transfers.enable();
          return;
        }
        factor.disable();
        transfer_cost_cents.disable();
        transfer_tax_percent.disable();
        min_transfer_cents.disable();
        max_transfer_cents.disable();
        max_n_transfers.disable();
      });

    this.programForm
      .get('redemption_limit.enabled')
      .valueChanges.subscribe((enable: boolean) => {
        const min_user_points_limit = this.programForm.get('redemption_limit.min_user_points_limit');
        const min_date = this.programForm.get('redemption_limit.min_date');
        const max_date = this.programForm.get('redemption_limit.max_date');
        if (enable) {
          min_user_points_limit.enable();
          min_date.enable();
          max_date.enable();
          return;
        }
        min_user_points_limit.disable();
        min_date.disable();
        max_date.disable();
      });
    
    this.programForm
      .get('centralorder.program_offline')
      .valueChanges.subscribe((program_offline: boolean) => {
        const points = this.programForm.get('centralorder.point_value_program');
        if (program_offline) {
          points.enable()
          return;
        }
        points.disable();
      })
  }

  ngOnInit() { }

  ngOnChanges() {
    // modifica el objeto budget para separar la start_datetime en fecha y hora para que ingresen en inputs diferentes
    const values = { ...this.program } as any;
    const { start_datetime } = values.budget;
    values.budget = {
      ...values.budget,
      start_date: moment(start_datetime).format('YYYY-MM-DD'),
      start_time: moment(start_datetime).format('hh:mm a'),
    };
    this.programForm.patchValue(values);

    this.appId = this.program.app_id;

    const autoId = this.programForm.get('auto_id');
    autoId.setValue(true);
    this.programForm.get('program_id').disable();
    if (this.mode !== 'create') {
      autoId.disable();
    } else {
      autoId.enable();
    }
    if (this.mode != 'create') {
      //Redemption Limits
      this.data_redemption_limit = this.program.redemption_limit ? this.program.redemption_limit : this.data_redemption_limit;
      this.clon_data_redemption_limit = { ...this.data_redemption_limit };
      this.setDatesLimits();
    }
  }

  cancelClicked() {
    this.onCancel.emit();
    return false;
  }

  okClicked() {
    const valueCopy = { ...this.programForm.getRawValue() };
    delete valueCopy.auto_id;
    valueCopy.company_id = this.program.company_id;
    const { start_date, start_time, value } = valueCopy.budget;
    const { min_user_points_limit, enabled } = valueCopy.redemption_limit;
    valueCopy.redemption_limit.min_date = new Date(this.minDateRedemptionLimits.nativeElement.value);
    valueCopy.redemption_limit.max_date = new Date(this.maxDateRedemptionLimits.nativeElement.value);
    if (this.validateFormLimits()) {
      if (!this.minDateRedemptionLimits.nativeElement.value || this.minDateRedemptionLimits.nativeElement.value == "") {
        delete valueCopy.redemption_limit.min_date;
      }
      if (!this.maxDateRedemptionLimits.nativeElement.value || this.maxDateRedemptionLimits.nativeElement.value == "") {
        delete valueCopy.redemption_limit.max_date;
      }
      if (!min_user_points_limit) {
        delete valueCopy.redemption_limit.min_user_points_limit;
      }
    } else {
      return;
    }
    if (!value.amount) {
      delete valueCopy.budget.value;
    }
    if (start_date && start_time) {
      // si se realiza un cambio en la fecha con el picker de material es necesario volver a parsear esa fecha
      const parseDate = start_date.toDate
        ? moment(start_date.toDate()).format('YYYY-MM-DD')
        : start_date;
      valueCopy.budget.start_datetime = new Date(`${parseDate} ${start_time}`);
      delete valueCopy.budget.start_time;
      delete valueCopy.budget.start_date;
    } else {
      delete valueCopy.budget.start_datetime;
      delete valueCopy.budget.start_time;
      delete valueCopy.budget.start_date;
    }
    ['document_type', 'consecutive_type', 'wharehouse'].forEach(
      (key) => {
        if (!valueCopy.erp[key]) {
          delete valueCopy.erp[key];
        }
      }
    );
    valueCopy.app_id = this.appId;
    this.onOK.emit(valueCopy as ProgramBase);
  }
  onCheckboxChange(e) {
    if (e.checked) {
      this.setValidatorRequired('user_type_id', Validators.required);
      this.setValidatorRequired('user_type_label', Validators.required);
    } else {
      this.setValidatorRequired('user_type_id', '');
      this.setValidatorRequired('user_type_label', '');
      this.programForm['controls']['user_type_id'].reset();
      this.programForm['controls']['user_type_label'].reset();
    }
  }
  setValidatorRequired(field: string, validators: any) {
    this.programForm['controls'][field].setValidators(validators);
    this.programForm['controls'][field].updateValueAndValidity();
  }

  //Redemption Limits
  validateFormLimits() {
    let title = 'Valide todos los campos';
    let valid = true;

    if ((moment(this.minDateRedemptionLimits.nativeElement.value)).isAfter(this.maxDateRedemptionLimits.nativeElement.value)) {
      title = 'La fecha inicial no debe superar la fecha final';
      valid = false;
    }
    if ((this.minDateRedemptionLimits.nativeElement.value && !this.maxDateRedemptionLimits.nativeElement.value)
      || (this.maxDateRedemptionLimits.nativeElement.value && !this.minDateRedemptionLimits.nativeElement.value)) {
      title = 'Valida los campos de fecha';
      valid = false;
    }

    if (!valid) {
      Swal.fire({
        icon: 'error',
        title,
        confirmButtonColor: '#ff9800',
      });
    }
    return valid;
  }
  setDatesLimits() {
    if (this.data_redemption_limit) {
      if (this.data_redemption_limit.min_date && this.data_redemption_limit.max_date) {
        const min_date = moment(this.data_redemption_limit.min_date).format('YYYY-MM-DDTHH:mm:ss');
        const max_date = moment(this.data_redemption_limit.max_date).format('YYYY-MM-DDTHH:mm:ss');
        // this.data_redemption_limit.min_date = min_date;
        setTimeout(() => {
          this.minDateRedemptionLimits.nativeElement.value = min_date;
          this.maxDateRedemptionLimits.nativeElement.value = max_date;
        }, 250);
      }
    }

  }
}
