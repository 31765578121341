/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-unused-vars */
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
import { CompaniesUtilService } from '../../../services/companies-util.service';
import { CompanyOutput } from '../../../users-api';
import { Bag } from '../../../models/bags/bags-model';
import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { BagsService } from '../../../services/bags.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import {StorageService} from '../../../services/storage.service';
import { currenciesForSelect } from '../../../data/currency-codes';

@Component({
  selector: 'app-bags-form',
  templateUrl: './bags-form.component.html',
  styleUrls: ['./bags-form.component.scss']
})
export class BagsFormComponent implements OnInit {

  bagForm: FormGroup;
  mode = 'create';
  bagData: Bag;
  saved = false;
  isSending = false;
  uriSeparatorKeyCodes = [ENTER, SPACE];
  fieldSeparatorKeyCodes = [ENTER];
  loading = true;

  name = 'Nueva Bolsa';
  currencies = currenciesForSelect;

  data;

  constructor(
    private formBuilder: FormBuilder,
    private bagsService: BagsService,
    private router: Router,
    private route: ActivatedRoute,
    private storageService: StorageService,
  ) {
    this.initConstructor();
  }

  ngOnInit() {
    const id = this.route.snapshot.paramMap.get('id');
    if(id){
      this.mode = 'edit';
      this.name = 'Editar Bolsa';
      this.getBag(id);
    }
  }

  initConstructor() {
    this.bagForm = this.formBuilder.group({
      name: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
        ]),
      ],
      balance: [
        0,
        Validators.compose([
          Validators.required,
          Validators.min(1),
        ])
      ],
      currencyCode: [
        'COP',
        Validators.compose([
          Validators.required,
        ]),
      ],
      grantors: this.formBuilder.array([]),
      cutDate: [
        '',
        Validators.compose([
          Validators.required,
        ]),
      ],
    });
  }

  getBag(id){
    this.bagsService.getBag(id).subscribe((res: Bag) => {
      this.data = res;
      this.bagForm.controls.name.setValue(res.bag_name);
      this.bagForm.controls.status.setValue(res.bag_state);
      this.bagForm.controls.balance.setValue(res.bag_balance);
      this.bagForm.controls.name.disable();
    });
  }

  filtersAutoComplete(value: string, type: string) {
    return this[type].filter(
      (option: string) =>
        option && option.toLowerCase().indexOf(value.toLowerCase()) >= 0
    );
  }

  cancelClicked() {
    this.router.navigateByUrl('/pages/bags');
  }

  toDataURL(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function() {
        const reader = new FileReader();
        reader.onloadend = function() {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }

  addGrantorForm() {
    this.getGrantorsForms().push(this.newGrantor());
  }

  getGrantorsForms() {
    return this.bagForm.get('grantors') as FormArray;
  }

  newGrantor() {
    return this.formBuilder.group({
      taxId: ['', [Validators.required]],
    });
  }

  deleteGrantorForm(formIndex: number) {
    this.getGrantorsForms().removeAt(formIndex);
  }

  async OKClicked() {
    this.loading = true;
    const account = this.storageService.get('account');
    if(this.isSending) {
      return;
    }
    this.isSending = true;
    const grantors = this.bagForm.value.grantors.map(element => element.taxId);
    console.log(this.bagForm.controls.cutDate.value);
    if(grantors.length ===0){
      Swal.fire('','Por lo menos debe relacionar un nit para cruzar los componentes de pago', 'error');
      this.isSending = false;
      return;
    }
    const body = {
      'bag_name':  this.bagForm.controls.name.value,
      'customer_nit': grantors[0],
      'bag_balance':  this.bagForm.controls.balance.value,
      'user_process': account.username,
      'bag_currency': this.bagForm.controls.currencyCode.value,
      'bag_cutDate' : new Date(this.bagForm.controls.cutDate.value).toISOString(),
      'documents': grantors
    };
    console.log(body);

    if(this.mode === 'create'){
      this.bagsService.postBag(body).subscribe((res: any) => {
        console.log(res);
        if(res.code === 'INSERT_BAG'){
          this.isSending = false;
          Swal.fire({
            icon: 'success',
            title: res.message,
            confirmButtonColor: '#ff9800',
          }).then(() => {
            Swal.close();
            this.loading = true;
            this.router.navigateByUrl('/pages/bags');
          });
        } else {
          this.isSending = false;
          this.loading = false;
          console.error('Error in createBolsa', res);
          const data = res;
          Swal.fire({
            icon: 'warning',
            title: data.message,
            confirmButtonColor: '#ff9800',
          });
          return;
        }
      },
      (err) => {
        console.error(err.error);
        const data = err.error;
        this.isSending = false;
        if(data.code === 'BAG_EXIST'){
          this.deleteGrantorForm(this.bagForm.value.grantors);
        }
        Swal.fire({
          icon: 'warning',
          title: data.message,
          confirmButtonColor: '#ff9800',
        });
      });
    } else {
      body['bag_id'] = this.route.snapshot.paramMap.get('id');
      this.bagsService.putBag(body).subscribe((res: any) => {
        if(res.code === 'UPDATED_BAG'){
          this.isSending = false;
          Swal.fire({
            icon: 'success',
            title: res.message,
            confirmButtonColor: '#ff9800',
          }).then(() => {
            Swal.close();
            this.loading = true;
            this.router.navigateByUrl('/pages/bags');
          });
        }
      },
      (err) => {
        this.isSending = false;
        console.error(err);
        Swal.fire({
          icon: 'error',
          title: 'Error guardando',
          text: 'Por favor intente de nuevo'
        });
      });
    }

  }
}
