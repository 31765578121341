import { PERMISSIONS } from './../../../services/permissions/permissions.service';
import { permissionsGuard } from './../../../guards/permissions.guard';
//import { cloudinary } from './../../pages.module';
import { environment } from './../../../../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppCommonModule } from './../../../app-common/app-common.module';
import { AuthGuardService } from './../../../auth-guard.service';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CategoryListComponent } from './category-list/category-list.component';
import { CategoryEditComponent } from './category-edit/category-edit.component';
import { ContainerModule } from '../../container/container.module';
import { CloudinaryModule } from '@cloudinary/ng';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
export const RoutesCategoriesAllies: Routes = [
  {
    path: 'category',
    canActivate: [AuthGuardService,permissionsGuard],
     data: {
      permissionValidate: [PERMISSIONS.POINTS_CATEGORY_VIEW,PERMISSIONS.POINTS_CATEGORY_UPDATE, PERMISSIONS.POINTS_CATEGORY_CREATE]},
    children: [

      {
        path: 'list',
        component: CategoryListComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.POINTS_CATEGORY_VIEW]}
      },
      {
        path: 'edit/:id',
        component: CategoryEditComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.POINTS_CATEGORY_UPDATE]
        }
      },
      {
        path: 'create',
        component: CategoryEditComponent,
        canActivate: [permissionsGuard], data: {
          permissionValidate: [PERMISSIONS.POINTS_CATEGORY_CREATE]
        }
      },
      {
        path: '**',
        redirectTo: 'home',
      }
    ]
  },
];

@NgModule({
  imports: [
    CommonModule
    , AppCommonModule
    , FormsModule
    , ReactiveFormsModule
    , ContainerModule
    , RouterModule.forChild(RoutesCategoriesAllies)
    , CloudinaryModule //.forRoot(cloudinary, environment.cloudinary_config)
    , CKEditorModule
  ],
  exports: [RouterModule
  , CategoryListComponent
  , CategoryEditComponent],
  declarations: [
    CategoryListComponent
  , CategoryEditComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CategoriesRoutingModule { }
