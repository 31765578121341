import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AppsUtilService } from '../../../services/apps-util.service';
import { AppOutput, AppsService, AppUserProfileInput, CompaniesService, ProfileFieldDefinitionOutput, ProfileFieldsOutput, ProgramFull, ProgramsService } from '../../../users-api';
import { RecognitionType } from '../../../models/recognitions/recognitions-model';
import { ENTER, I, SPACE } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { map, startWith } from 'rxjs/operators';
import { isArray } from 'lodash';
import { ProgramsUtilService } from '../../../services/programs-util.service';
import { AppUsersEditorFieldData } from '../../app-users/app-users-editor/app-users-editor.component';
import { RecognitionsService } from '../../../services/recognitions.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-recognitions-form',
  templateUrl: './recognitions-form.component.html',
  styleUrls: ['./recognitions-form.component.scss']
})
export class RecognitionsFormComponent implements OnInit {

  recognitionForm: UntypedFormGroup;
  mode = 'create';
  recognitionData: RecognitionType;
  saved = false;
  isSending = false;
  uriSeparatorKeyCodes = [ENTER, SPACE];
  fieldSeparatorKeyCodes = [ENTER];

  applicationsOptions: string[] = [];
  filteredAppsOptions: string[];
  objectsAppsData: AppOutput[];
  appsNameMap: Map<string, AppOutput> = new Map();
  appsIdMap: Map<string, AppOutput> = new Map();
  appId = "";
  name = "Nuevo Reconocimiento";
  appNameInputControl = new UntypedFormControl();
  programsOptions = [];
  filteredProgramsOptions: Observable<string[]>;
  programs = [];

  appUserProfileFields: AppUserProfileInput = {
    data: {},
  };
  appProfileDefinition: ProfileFieldsOutput;
  appProfileFields: Array<AppUsersEditorFieldData> = [];
  loadingAppProfileDefinition = false;

  grantor;
  grantee;
  data;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private appsUtilService: AppsUtilService,
    private programsUtilService: ProgramsUtilService,
    private appsApi: AppsService,
    private recognitionsService: RecognitionsService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.initConstructor();
  }

  ngOnInit() {
    this.initApps();
    // Filter programs
    this.filteredProgramsOptions = this.recognitionForm.controls.programInputControl.valueChanges
      .pipe(startWith(null))
      .pipe(
        map((val: any) =>
          val
            ? this.filtersAutoComplete(val, 'programsOptions')
            : this.programsOptions.slice()
        )
      );
    // Filter app names
    this.appNameInputControl.valueChanges
      .pipe(startWith(null))
      .subscribe((val: string) => {
        if (val) {
          const nameParts = val.split('|').map((part: string) => part.trim());
          let app =
            nameParts.length > 1
              ? this.appsIdMap.get(nameParts[1])
              : this.appsIdMap.get(nameParts[0].toLowerCase());
          app = app ? app : this.appsNameMap.get(nameParts[0].toLowerCase());

          const value = val.split(' | ').length > 1 ? val.split(' | ')[1] : val;
          this.appId = value;
          const findObs = this.programsUtilService.getPrograms(value);
          findObs.then((programsList: ProgramFull[]) => {
            console.log('programsList', programsList)
            if (!programsList) {
              return;
            }
            const ids = programsList.map((program) => program.id);
            this.programsOptions.push(...ids);
            this.recognitionForm.controls.programInputControl.setValue('', { emitEvent: true });

            this.loadingAppProfileDefinition = true;
            this.appsApi
              .retrieveAppProfileFields(value)
              .subscribe(
                (profile) => {
                  this.appProfileDefinition = profile;
                  this.decodeAppProfileDefinition(profile);
                  this.loadingAppProfileDefinition = false;
                },
                (error) => {
                  this.loadingAppProfileDefinition = false;
                  console.error(error);
                }
              );
          });
        }

        this.filteredAppsOptions = val
          ? this.filtersAutoComplete(val, 'applicationsOptions')
          : this.applicationsOptions.slice();
      });

    const id = this.route.snapshot.paramMap.get('id');
    if(id){
      this.mode = 'edit';
      this.name = "Editar Reconocimiento";
      this.getRecognition(id);
    }
  }

  initConstructor() {
    this.recognitionForm = this.formBuilder.group({
      name: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
        ]),
      ],
      status: [false],
      grantee: [""],
      grantor: [""],
      image: ["", Validators.required],
      programInputControl: [
        "",
      ]
    });
  }

  getRecognition(id){
    this.recognitionsService.getRecognitionType(id).subscribe((res: RecognitionType) => {
      this.data = res;
      this.recognitionForm.controls.name.setValue(res.translations[0].texts[0].name);
      this.recognitionForm.controls.image.setValue(res.icon['url']);
      this.recognitionForm.controls.status.setValue(res.status == 'active' ? true : false);
      this.appNameInputControl.setValue(res.appId);
      this.recognitionForm.controls.programInputControl.setValue(res.programs);
      this.appNameInputControl.disable();
      this.recognitionForm.controls.name.disable();
      res.programs.map(program => (this.programs.push(program)));
    });
  }

  setApps(apps: AppOutput[]) {
    this.objectsAppsData = apps;
    this.applicationsOptions = apps.map(
      (app: AppOutput) => `${app.name} | ${app.id}`
    );
    this.appsNameMap = apps.reduce(
      (appsMap: Map<string, AppOutput>, app: AppOutput) => {
        appsMap.set(app.name.toLowerCase(), app);
        return appsMap;
      },
      new Map()
    );
    this.appsIdMap = apps.reduce(
      (appsMap: Map<string, AppOutput>, app: AppOutput) => {
        appsMap.set(app.id, app);
        return appsMap;
      },
      new Map()
    );
  }

  async initApps() {
    try {
      const apps = await this.appsUtilService.getApps();
      this.setApps(apps);
    } catch (error) {
      console.error('Error getting apps', error);
    }
  }

  isProfileDefinitionLoaded(): boolean {
    if (
      this.appProfileDefinition &&
      this.appProfileDefinition.id.startsWith(this.appId)
    ) {
      return true;
    }
    return false;
  }

  filtersAutoComplete(value: string, type: string) {
    return this[type].filter(
      (option: string) =>
        option && option.toLowerCase().indexOf(value.toLowerCase()) >= 0
    );
  }


  addProgram(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value.trim();

    if (value && input) {
      // Check input program is known
      if (this.programsOptions.indexOf(value) < 0) {
        return;
      }
      // Init property if non existent
      if (!this.programs) {
        this.programs = [];
      }
      // Check value is not repeated
      if (this.programs.indexOf(value) >= 0) {
        return;
      }
      // Add value
      this.programs.push(value);
      input.value = '';
    }
  }

  removeProgram(program: string) {
    const index = this.programs.indexOf(program);
    if (index >= 0) {
      this.programs.splice(index, 1);
    }
  }


  decodeAppProfileDefinition(profile: ProfileFieldsOutput) {
    this.appProfileFields = Object.entries(profile.fields)
      .map(([id, definition]) => ({ id, definition }))
      .sort((a, b) => (a.id > b.id ? 1 : -1));

      if(this.data){
        if(this.data.grantor){
          const fieldGrantor = this.appProfileFields.filter(x => x.id == this.data.grantor.attribute_id)[0]
          this.recognitionForm.get('grantor').setValue(fieldGrantor);
          this.data.grantor.attribute_values.map(field => this.addProfileFieldValue(this.data.grantor.attribute_id, field));
          this.selectAtrGrantor(fieldGrantor);
        }
        if(this.data.grantee){
          const fieldGrantee = this.appProfileFields.filter(x => x.id == this.data.grantee.attribute_id)[0];
          this.recognitionForm.get('grantee').setValue(fieldGrantee.id);
        }
      }
  }

  getProfileFieldValues(fieldId: string) {
    const typeField = fieldId;
    if (
      this.appUserProfileFields &&
      typeField in this.appUserProfileFields.data
    ) {
      return this.appUserProfileFields.data[fieldId];
    }
    return [];
  }

  removeProfileFieldValue(fieldId: string, value: string) {
    const index = this.appUserProfileFields.data[fieldId].indexOf(value);
    if (index >= 0) {
      this.appUserProfileFields.data[fieldId].splice(index, 1);
    }
  }

  isProfileFieldEnum(field: AppUsersEditorFieldData) {
    if ('enum' in field.definition && field.definition.enum.length) {
      return true;
    }
    return false;
  }

  ensureProfileField(fieldId: string) {
    if (!(fieldId in this.appUserProfileFields.data)) {
      this.appUserProfileFields.data[fieldId] = [];
    }
  }

  addProfileFieldValue(fieldId: string, value: string) {
    const trimmedValue = value.trim();
    if (trimmedValue) {
      this.ensureProfileField(fieldId);
      const index = this.appUserProfileFields.data[fieldId].indexOf(
        trimmedValue
      );
      // Only add if not found
      if (index < 0) {
        this.appUserProfileFields.data[fieldId].push(trimmedValue);
      }
    }
  }

  addProfileFieldChipInputValue(fieldId: string, event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value.trim();

    if (input && value) {
      this.addProfileFieldValue(fieldId, value);
      input.value = '';
    }
  }

  getFieldType(definition: ProfileFieldDefinitionOutput) {
    if (definition.format && definition.format.length) {
      return 'format ' + definition.format;
    }
    if (definition.enum && definition.enum.length) {
      return 'enum';
    }
    if (definition.pattern && definition.pattern.length) {
      return 'pattern /' + definition.pattern + '/';
    }
    return 'free';
  }

  cancelClicked() {
    this.router.navigateByUrl('/pages/recognitions');
  }

  async onFileSelected() {
    const inputNode: any = document.querySelector('#file')['files'];
    const base64 = await this.convertBase64(inputNode[0]);
    this.recognitionForm.controls.image.setValue(base64);
  }

  toDataURL(url) {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.onload = function() {
        var reader = new FileReader();
        reader.onloadend = function() {
          resolve(reader.result);
        }
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    })
  }

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = (e: any) => {
            var img = new Image();
            img.src = e.target.result;
            img.onload = function() { // image is loaded; sizes are available
              if(img.width > 256 || img.height > 256){
                Swal.fire({
                  icon: 'error',
                  title: 'La imagen no puede tener unas dimensiones mayor a 256px*256px',
                  confirmButtonColor: '#ff9800',
                })
                return;
              } else {
                resolve(fileReader.result);
              }
            };
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
  };

  selectAtrGrantor(id){
    this.grantor = id.value ? id.value : id;
  }

  async OKClicked() {
    if(this.isSending) return;
    this.isSending = true;
    if(!this.recognitionForm.controls.image.value){
      Swal.fire({
        icon: 'error',
        title: 'Selecciona una imagen',
        confirmButtonColor: '#ff9800',
      })
      return;
    }
    const grantorSelected = this.getProfileFieldValues(this.recognitionForm.controls.grantor.value.id);
    const body = {
      "appId": this.appId,
      "translations": [
        {
          "lang": "es",
          "texts": [
            {
              "region": "es_CO",
              "name": this.recognitionForm.controls.name.value
            }
          ]
        }
      ],
      "icon": this.data ? this.data.icon : this.recognitionForm.controls.image.value,
      "grantor": {
        "attribute_values": grantorSelected,
        "attribute_id": this.recognitionForm.controls.grantor.value.id
      },
      "grantee": {
        "attribute_id": this.recognitionForm.controls.grantee.value
      },
      "status": this.recognitionForm.controls.status.value ? "active" : "inactive",
      "programs": this.programs
    }
    if(!this.recognitionForm.controls.grantee.value){
      delete body["grantee"];
    }
    if(grantorSelected.length <= 0){
      delete body["grantor"];
    }
    if(this.mode == 'create'){
      this.recognitionsService.postRecognitionType(body).subscribe((res: any) => {
        if(res.created){
          this.isSending = false;
          Swal.fire({
            icon: 'success',
            title: 'Reconocimiento agregado correctamente',
            confirmButtonColor: '#ff9800',
          }).then(() => {
            this.router.navigateByUrl('/pages/recognitions');
          });
        }
      })
    } else {
      if(this.recognitionForm.controls.image.value != this.data.icon.url){
        body["new_icon"] = this.recognitionForm.controls.image.value;
      }
      body["id"] = this.route.snapshot.paramMap.get('id');
      this.recognitionsService.putRecognitionType(body).subscribe((res: any) => {
        if(res.updated){
          this.isSending = false;
          Swal.fire({
            icon: 'success',
            title: 'Reconocimiento editado correctamente',
            confirmButtonColor: '#ff9800',
          }).then(() => {
            this.router.navigateByUrl('/pages/recognitions');
          });
        }
      })
    }

  }
}
