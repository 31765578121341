// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sale-point-container form {
  display: flex;
  flex-direction: column;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
}

/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card {
  margin-top: 2%;
}

.brand-form-input {
  width: 40%;
  margin-bottom: 2%;
}

agm-map {
  width: 100%;
  height: 500px;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/pointz/sales-points-edit/sales-points-edit.component.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,sBAAA;AAAJ;;AAIA;EACE,aAAA;EACA,yBAAA;AADF;;AAIA,2HAAA;AACA;EACE,cAAA;AADF;;AAIA;EACE,UAAA;EACA,iBAAA;AADF;;AAIA;EACE,WAAA;EACA,aAAA;AADF","sourcesContent":[".sale-point-container {\n  form {\n    display: flex;\n    flex-direction: column;\n  }\n}\n\n.btn-container {\n  display: flex;\n  justify-content: flex-end;\n}\n\n/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */\nmat-card {\n  margin-top: 2%;\n}\n\n.brand-form-input {\n  width: 40%;\n  margin-bottom: 2%;\n}\n\nagm-map {\n  width: 100%;\n  height: 500px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
