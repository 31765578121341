import { Component, OnInit } from "@angular/core";
import { SalesPointsService } from "../../../services/sales-points.service";
import { PointsSaleRoot, PointSale } from "../../../models/models";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
@Component({
  selector: "app-sales-points-list",
  templateUrl: "./sales-points-list.component.html",
  styleUrls: ["./sales-points-list.component.scss"],
})
export class SalesPointsListComponent implements OnInit {
  loader = false;
  dataSource;
  salesPoints: PointSale[] = [];
  displayedColumns: string[] = ["name", "code", "country", "edit"];
  constructor(
    private salesPointsService: SalesPointsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loader = true;
    this.getSalesPoints();
  }

  getSalesPoints() {
    this.salesPointsService
      .getSalesPoints()
      .subscribe((res: PointsSaleRoot) => {
        this.loader = false;
        this.salesPoints = res.data;
        this.dataSource = new MatTableDataSource(this.salesPoints);
        this.loader = false;
      });
  }

  searchSalePoint(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  redirect() {
    this.router.navigate(["pages/sales-point/create"]);
  }
}
