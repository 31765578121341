import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {RedemptionRule } from '../models/models';
@Injectable({
  providedIn: 'root',
})
export class LockRedemptionService {
  private uriConnection: string = environment.redemption_rules_api.base_url;
  constructor(private http: HttpClient) {}

  getRedemptionRules(app_id: string): Observable<RedemptionRule> {
    if (!app_id) {
      console.error('GetRedemptionRules no has APP_ID');
    }
    return this.http.get<RedemptionRule>(
      this.uriConnection + `/admin/apps/${app_id}/redemption-rules`
    );
  }

  setRedemptionRules(app_id: string, data: RedemptionRule):
  Observable<RedemptionRule> {
    if (!app_id) {
      console.error('putRedemptionRules no has APP_ID');
    }
    const reqBody = {
      rules: data.rules,
      enabled: data.enabled,
    };
    reqBody.rules.forEach(rule => {
      delete rule.attribute_values;
      delete rule.parameter_values;
    });
    return this.http.put<RedemptionRule>(
      this.uriConnection + `/admin/apps/${app_id}/redemption-rules`,
      reqBody
    );
  }
}
