
import {switchMap} from 'rxjs/operators';

import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

import { AppUsersEditorOutput } from '../app-users-editor/app-users-editor.component';
import { MainSidenavService } from '../../../main-sidenav.service';
import {
  AppUsersService,
  AppUserInput,
  AppUserOutput,
  AppUserProfileInput,
} from '../../../users-api';

@Component({
  selector: 'app-app-users-viewer',
  templateUrl: './app-users-viewer.component.html',
  styleUrls: ['./app-users-viewer.component.scss'],
  providers: [
    AppUsersService,
  ],
})
export class AppUsersViewerComponent implements OnInit {
  userEditData: AppUserInput;
  originalUserData: AppUserOutput;
  userProfile: AppUserProfileInput = {
    data: {},
  };
  loading = true;

  constructor(
    private usersApi: AppUsersService,
    private snackBar: MatSnackBar,
    public mainSidenavService: MainSidenavService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.loading = true;
    this.route.paramMap.pipe(switchMap((params: ParamMap) =>
      this.usersApi.retrieveAppUser(params.get('id'))
    )).subscribe(
      (user: AppUserOutput) => {
        user.personal_data = user.personal_data || {};
        this.userEditData = user;
        this.originalUserData = { ...user };
        this.userProfile = {
          data: user.profile || {},
        };
      },
      error => {
        this.handleEditError(error, 'Cargando usuario');
      }
    );
  }

  onEditorOK(editorData: AppUsersEditorOutput) {
    this.loading = true;
    delete editorData.user.auth_ids;
    delete editorData.user.last_auth_id;
    if (editorData.profile
        && editorData.profile.data
        && Object.keys(editorData.profile.data).length) {
      editorData.user.profile = editorData.profile.data;
    }
    this.usersApi.patchAppUser(this.originalUserData.id, editorData.user).subscribe(
      () => {
        this.loading = false;
        this.router.navigateByUrl('/pages/app-users');
      },
      error => {
        this.handleEditError(error, 'Actualizando usuario');
      }
    );
  }

  onEditorCancel() {
    this.router.navigateByUrl('/pages/app-users');
  }

  handleEditError(error: any, logmsg: string) {
    console.error(logmsg, error);
    this.loading = false;
    try {
      const data = error.error;
      this.snackBar.open(data.message, 'OK');
    } catch (ex) {
      this.snackBar.open(logmsg + ': ' + error, 'OK');
    }
  }
}
