import { ProgramsUtilService } from './../../services/programs-util.service';
import { ProgramBaseLimitRedemption } from './../../users-api/model/programBaseLimitRedemption';
import { ProgramFull } from './../../users-api/model/programFull';
import { Router } from '@angular/router';
import { StorageService } from './../../services/storage.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import moment, {  } from 'moment'
import Swal from 'sweetalert2';
@Component({
  selector: 'app-limits-redemption',
  templateUrl: './limits-redemption.component.html',
  styleUrls: ['./limits-redemption.component.scss']
})
export class LimitsRedemptionComponent implements OnInit {
  @ViewChild('minDate',{static:false})minDate: ElementRef;
  @ViewChild('maxDate',{static:false})maxDate: ElementRef;
  stateLimit: HTMLInputElement;
  loading = false;
  allowedAppIds: string[];
  allowAllAppIds = false;
  currentAppId: string;
  currentProgramId: string;
  program: ProgramFull;
  data_redemption_limit: ProgramBaseLimitRedemption;
  clon_data_redemption_limit: ProgramBaseLimitRedemption;
  modeView: 'view'|'edit'|'new' ='view';
  today = moment().format('YYYY-MM-DDTHH:mm');
  constructor(    private storageService: StorageService,
    private router: Router, private programsUtilService: ProgramsUtilService ) { }

  async ngOnInit() {
    await this.initAllowedIds();
    const data =  moment().format();
    console.log(new Date(data));
  }
  onAppSelected(appId: string) {
    // this.appIdFilter$.next(appId);
    this.currentAppId = appId;
    this.currentProgramId = null;
    this.modeView = 'view';
  }

  onProgramSelectedFull(program: ProgramFull) {
    this.currentProgramId = program ? program.id : '';
    this.program = program;
    if(program && program.redemption_limit) {
      if(program.redemption_limit){
        if (this.modeView === 'new') {
          this.modeView = 'edit';
        }
        this.data_redemption_limit = program.redemption_limit;
        this.clon_data_redemption_limit = {...this.data_redemption_limit};
        this.setDates();
      }
    }
    console.log(program);
  }
  setDates() {
    if (this.data_redemption_limit.min_date && this.data_redemption_limit.max_date) {
      const min_date = moment(this.data_redemption_limit.min_date).format('YYYY-MM-DDTHH:mm:ss');
      const max_date = moment(this.data_redemption_limit.max_date).format('YYYY-MM-DDTHH:mm:ss');
      // this.data_redemption_limit.min_date = min_date;
      setTimeout(() => {
        this.minDate.nativeElement.value = min_date;
        this.maxDate.nativeElement.value = max_date;
      }, 250);
    }

  }

  onCreate(){
    this.data_redemption_limit = {
      enabled:false,
      min_user_points_limit: 0,
      min_date: null,
      max_date: null,
    };
    this.modeView = 'new';
  }

  onEdit(){
    this.clon_data_redemption_limit = {...this.data_redemption_limit};
    this.modeView = 'edit';
    console.log(this.clon_data_redemption_limit);
  }

  resetProgram() {
    this.currentProgramId = null;
  }

  resetApp() {
    this.currentAppId = null;
  }

  initAllowedIds() {
    // Do not allow any app by default
    this.allowedAppIds = [];
    this.allowAllAppIds = false;
    const account = this.storageService.get('account');
    if (!account) {
      return;
    }
    if (account.roles.includes('super_admin')) {
      // Allow all ids
      this.allowedAppIds = null;
      this.allowAllAppIds = true;
      return;
    }
    const profile = this.storageService.get('profile');
    if (!profile) {
      return;
    }
    this.allowedAppIds = profile.authorized_apps || [];
  }

  changeStatus($event){
    console.log($event);
    console.log(this.stateLimit);
  }

  cancelClicked(){
    if(this.modeView === 'edit'){
      this.data_redemption_limit = {...this.clon_data_redemption_limit};
      this.setDates();
      this.modeView = 'view';
    }
    if(this.modeView === 'new'){
      this.resetApp();
    }
  }

  validateForm() {
    let title = 'Valide todos los campos';
    let valid = true;

    if (this.modeView === 'new') {
      if (!this.currentAppId || !this.currentProgramId) {
        valid = false;
      }
    }

    if ((moment(this.minDate.nativeElement.value)).isAfter(this.maxDate.nativeElement.value)) {
      title = 'La fecha inicial no debe superar la fecha final';
      valid = false;
    }
    if((this.minDate.nativeElement.value && !this.maxDate.nativeElement.value)
    || (this.maxDate.nativeElement.value && !this.minDate.nativeElement.value) ){
      title = 'Valida los campos de fecha';
      valid = false;
    }

    if (!valid) {
      Swal.fire({
        icon: 'error',
        title,
        confirmButtonColor: '#ff9800',
      });
    }
    return valid;
  }

  okClicked() {
    if (this.validateForm()) {
      const redemption_limit = this.data_redemption_limit;
      redemption_limit.min_date = new Date(this.minDate.nativeElement.value);
      redemption_limit.max_date = new Date(this.maxDate.nativeElement.value);

      if (!this.minDate.nativeElement.value) {
        delete redemption_limit.min_date;
      }
      if (!this.maxDate.nativeElement.value) {
        delete redemption_limit.max_date;
      }
      if (!redemption_limit.min_user_points_limit) {
        delete redemption_limit.min_user_points_limit;
      }
      const data = {
        ...this.program,
        redemption_limit
      };
      /* this.programsUtilService.getProgram(this.currentAppId, this.program.program_id).subscribe((data) => {
        console.log('program', data);
      }); */
      this.programsUtilService.putProgram(this.program.company_id, this.program.program_id, data).subscribe((res) => {
        console.log(res);
        if (res.updated) {
          const title = this.modeView === 'edit' ? 'Limite editado correctamente' : 'Limite creado correctamente';
          Swal.fire({
            icon: 'success',
            title,
            confirmButtonColor: '#ff9800',
          }).then(() => {
            this.resetApp();
          });
        }
      });
    }

  }
}
