import { Observer } from 'rxjs';
import { DataSource } from '@angular/cdk/collections';
import { UntypedFormControl } from '@angular/forms';
import { StorageService } from './../../../../services/storage.service';
import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { indicator } from '../indicator.model';
import { IndicatorsService } from '../../indicators.service';


export class IndicatorDataSource extends DataSource<indicator> {
  observer: Observer<indicator[]>;

  refreshData(data: indicator[]) {
    if (this.observer) {
      this.observer.next(data);
    }
  }

  connect(): Observable<indicator[]> {
    return new Observable((observer: Observer<indicator[]>) => {
      this.observer = observer;
    });
  }

  disconnect() {
    this.observer = null;
  }
}
@Component({
  selector: 'app-indicator-list',
  templateUrl: './indicator-list.component.html',
  styleUrls: ['./indicator-list.component.scss']
})
export class IndicatorListComponent implements OnInit {
  loading = false;
  pageSize = 100;
  dataSource = new IndicatorDataSource();
  appIdFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  nameCategory = new UntypedFormControl('');
  currentAppId: string;
  allowedAppIds: string[];
  allowAllAppIds = false;
  requiredFields = true;
  data: indicator[];
  resultsFound = true;
  displayedColumns = ['id'];
  hoveredRow: any;
  dataSearch: indicator[];
  dataFilter: indicator[];
  constructor(
    private router: Router,
    private storageService: StorageService,
    private indicatorsService: IndicatorsService,
    private authService: AuthService,
  ) {

  }

  async ngOnInit() {
    await this.initAllowedIds();
   this.getIndicator();
  }

  initAllowedIds() {
    // Do not allow any app by default
    this.allowedAppIds = [];
    this.allowAllAppIds = false;
    const account = this.storageService.get('account');
    if (!account) {
      return;
    }
    if (account.roles.includes('super_admin')) {
      // Allow all ids
      this.allowedAppIds = null;
      this.allowAllAppIds = true;
      return;
    }
    const profile = this.storageService.get('profile');
    if (!profile) {
      return;
    }
    this.allowedAppIds = profile.authorized_apps || [];



  }

  getIndicator(appId?: string, name?: string) {
    if (!appId) {
      this.requiredFields = false;
      this.data = [];
      this.dataSource.refreshData(this.data);
      this.resultsFound = false;
      return;
    }
  
    this.requiredFields = true;
    this.loading = true;
  
    this.indicatorsService.getIndicators(appId, name).subscribe(
      (data) => {
        // Filtrar por `app_id`
        this.data = data.data.filter(x => x.app_id === appId);
  
        // Ordenar por fecha
        this.data = this.data.sort((a, b) => {
          return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf();
        });
  
        // Actualizar la fuente de datos
        this.dataSource.refreshData(this.data);
  
        // Establecer los estados
        this.loading = false;
        this.resultsFound = this.data.length > 0;
  
        // Actualizar datos de búsqueda y filtro si no se pasa `name`
        if (!name) {
          this.dataSearch = this.data;
          this.dataFilter = this.data;
        }
      },
      (error) => {
        // Manejar errores específicos de "KPI_NOT_FOUND"
        if (error.error?.code === 'KPI_NOT_FOUND') {
          this.data = [];
          this.dataSource.refreshData(this.data);
          this.resultsFound = false;
        }
  
        // Registro de otros errores
        this.loading = false;
        console.error('Error fetching indicators:', error);
      }
    );
  }
  
  

  /* onUserSelected(user: AppUserOutput) {} */

  onAppSelected(appId: string) {
    this.appIdFilter$.next(appId);
    this.currentAppId = appId;
    this.getIndicator(this.currentAppId);
  }



  resetApp() {
    this.currentAppId = null;
  }

  onEdit(item: indicator) {
    this.router.navigate(['pages', 'indicators', 'edit',item.id]);
  }
  onCreate(){
    this.router.navigate(['pages', 'indicators', 'add']);
  }

  getRowBackgroundColor(row) {
    return row === this.hoveredRow ? '#F5F5F5' : 'transparent';
  }


  clearData(value: any) {
    const val = String(value.target.value).trim();  
    if (val.length === 0) {
      this.getIndicator(this.currentAppId);
    }
  }

  searchByName(value: string) {
    const val = value.trim();
    if (val.length === 0) {
      return;
    }
    this.getIndicator(this.currentAppId, val);
  }

  onIdOptionSelected(option){
    const name = option.option.value;
    this.dataFilter = name ? this.data.filter(x => x.name == name) : this.data;
    this.dataSource.refreshData(this.dataFilter);
  }

  goToIcon(url: string){
    window.open(url, '_blank');
    return false;
  }

}
