import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { indicator } from './indicators/indicator.model';

@Injectable({
  providedIn: 'root'
})
export class IndicatorsService {
  private uriConnection: string = environment.kpi_api.base_url + "/apps/gnx_mvp_improved_stg/kpis";
  private apiKey = environment.kpi_api.apiKey;
  constructor(private http: HttpClient) {}

  getIndicators(appid, name?:string){
    this.uriConnection = `${environment.kpi_api.base_url}/apps/${appid}/kpis`;
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    const uri = name ?  `${this.uriConnection}?name=${name}*&key=${this.apiKey}` : `${this.uriConnection}?key=${this.apiKey}`
    return this.http.get<{data:indicator[]}>(uri, { headers });
  }
  
  getIndicator(id: string) {
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    const uri = `${this.uriConnection}/${id}?key=${this.apiKey}`;
    return this.http.get(uri,{ headers });
  }
  postIndicator(newIndicator: indicator) {
    this.uriConnection = `${environment.kpi_api.base_url}/apps/${newIndicator.app_id}/kpis`;
    let headers = new HttpHeaders({ 'Content-Type':'application/json'});
    return this.http.post<indicator>(`${this.uriConnection}/${newIndicator.id}?key=${this.apiKey}`, newIndicator, { headers });
  }
  putIndicator(indicator: indicator) {
    this.uriConnection = `${environment.kpi_api.base_url}/apps/${indicator.app_id}/kpis`;
    return this.http.put<indicator>(
      `${this.uriConnection}/${indicator.id}?key=${this.apiKey}`,
      indicator
    );
  }
}