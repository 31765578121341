import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { ProgramsUtilService } from '../../services/programs-util.service';
import { StorageService } from '../../services/storage.service';
import { ProgramFull } from '../../users-api';

interface ProgramCompanyOption {
  programId: string;
  programLabel: string;
}

@Component({
  selector: 'app-program-company-selector',
  templateUrl: './program-company-selector.component.html',
  styleUrls: ['./program-company-selector.component.scss']
})
export class ProgramCompanySelectorComponent implements OnInit {

  @Output() onProgramCompanySelected = new EventEmitter<string>();
  @Input()
  set currentProgramId(programId: string) {
    this.programCompanySelectControl.setValue(programId);
  }
  @Input() 
  set companyId(companyIdProgram: string) {
    this.loadProgramsCompanyOptions(companyIdProgram);
  }

  @Input()getFullInfoProgram = false;
  @Input()disabled = false;
  @Input()isRequired = false;
  @Output() onProgramCompanySelectedFull = new EventEmitter<ProgramFull>();

  programCompanySelectControl = new UntypedFormControl();
  programsCompanyOptions: ProgramCompanyOption[];

  constructor(
    private storageService: StorageService,
    private programsUtilService: ProgramsUtilService,
  ) { }

  async setSelectedProgramCompany(programId: string) {
    this.storageService.set('ProgramCompanySelectorComponent:lastSelectedProgram', programId);
    this.onProgramCompanySelected.emit(programId);
    if(this.getFullInfoProgram){
      const list = await this.programsUtilService.getProgramsCompany(this.companyId,true);
      this.onProgramCompanySelectedFull.emit(list.filter((program) => program.id === programId )[0]);
    }
  }

  async loadProgramsCompanyOptions(companyId?) {
    const appIdSearch = companyId ? companyId : this.companyId;
    try {
      const programs = await this.programsUtilService.getProgramsCompany(appIdSearch);
      this.programsCompanyOptions = programs.map((program: ProgramFull) => ({
        programId: program.id,
        programLabel: `${program.name} (${program.id})`
      }));
    } catch (error) {
      console.error('ProgramCompanySelectorComponent: getting programs list', error);
    }
    const lastSelected = this.storageService.get('ProgramCompanySelectorComponent:lastSelectedProgram');
    if (lastSelected) {
      this.programCompanySelectControl.setValue(lastSelected);
      this.onProgramCompanySelected.emit(lastSelected);
    }
  }

  ngOnInit() {
    if(this.companyId){
      this.loadProgramsCompanyOptions();
    }
    this.programCompanySelectControl.markAllAsTouched();
    this.programCompanySelectControl.valueChanges.subscribe(
      (programId: string) => this.setSelectedProgramCompany(programId)
    );
  }

}
