import {NgZone} from '@angular/core';

import {AngularFireAuth} from '@angular/fire/auth';
import {AngularFireFunctions} from '@angular/fire/functions';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';

import {environment} from '../environments/environment';

function getProjectKey(projectKey: string) {
  return projectKey === 'users' ? '[DEFAULT]' : projectKey;
}

export function angularFireAuthFactory(
  projectKey: string,
  platformId: Object,
  zone: NgZone,
) {
  return new AngularFireAuth(
    environment.firebase[projectKey],
    getProjectKey(projectKey),
    platformId,
    zone
  );
}

export function angularFireFunctionsFactory(
  projectKey: string,
  platformId: Object,
  zone: NgZone,
  origin: string | null
) {
  return new AngularFireFunctions(
    environment.firebase[projectKey],
    getProjectKey(projectKey),
    platformId,
    zone,
    null,
    origin
  );
}

export function angularFirestoreFactory(
  projectKey: string,
  platformId: Object,
  zone: NgZone,
) {
  return new AngularFirestore(
    environment.firebase[projectKey],
    getProjectKey(projectKey),
    false,
    null,
    platformId,
    zone,
    null
  );
}

export function angularFireStorageFactory(
  projectKey: string,
  platformId: Object,
  zone: NgZone
) {
  return new AngularFireStorage(
    environment.firebase[projectKey],
    getProjectKey(projectKey),
    null,
    platformId,
    zone
  );
}
