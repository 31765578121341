import { Component, OnInit } from '@angular/core';
import { DrupalTransferToken, DrupalUserTransferService } from '../../services/drupal-user-transfer.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-cms-drupal',
  templateUrl: './cms-drupal.component.html',
  styleUrl: './cms-drupal.component.scss'
})
export class CmsDrupalComponent implements OnInit {
  tokenResult: string;

  constructor(
    public drupalUserTransfer: DrupalUserTransferService
  ) {}

  ngOnInit() {
    this.drupalUserTransfer.getDrupalTransferToken().subscribe({
      next: result => this.receiveTransferToken(result),
      error: error => this.handleError(error),
    });
  }

  receiveTransferToken(result: DrupalTransferToken) {
    if (!result?.token) {
      this.tokenResult = `FALLÓ ${result?.code}`;
    }
    const url = new URL(
      '/gluky-login/user-transfer',
      environment.drupal_cms.base_url
    );

    url.searchParams.append('token', result.token);
    window.location.href = url.href;
  }

  handleError(error: unknown) {
    console.error('Error leyendo Drupal transfer token', error);
    this.tokenResult = 'FALLÓ';
  }
}
