import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Category, CategoryRoot } from "../models/models";
@Injectable({
  providedIn: "root",
})
export class CategoryService {
  private uriConnection: string = environment.pointz_api.base_url_new + '/allies/categories';
  constructor(private http: HttpClient) {}

  getCategories(): Observable<CategoryRoot> {
    return this.http.get<CategoryRoot>(`${this.uriConnection}`);
  }

  getCategory(idCategory: string) {
    return this.http.get<Category>(`${this.uriConnection}/${idCategory}`);
  }
  postCategory(newCategory: Category) {
    return this.http.post<any>(`${this.uriConnection}`, newCategory);
  }
  putCategory(category: Category) {
    return this.http.put<any>(`${this.uriConnection}/${category.id}`, {
      ...category,
    });
  }
}
