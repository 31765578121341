import { Injectable } from '@angular/core';
type Order = { [key: string]: any };

@Injectable({
    providedIn: 'root'
})
export class OrderTransformService {

    translationDict: { [key: string]: string } = {
        "product": "Producto",
        "productName": "Nombre del producto",
        "orderId": "ID de orden",
        "workerState": "Estado",
        "createdAt": "Fecha de creación",
        "state": "Estado",
        "sku": "SKU",
        "updatedAt": "Fecha de actualización",
        "deliveryGlobalId": "ID global de entrega",
        "pricePoint": "Precio en puntos",
        "docId": "ID de documento",
        "docType": "Tipo de documento",
        "email": "Correo electrónico",
        "courier": "Guía",
        "uid": "UID",
        "programName": "Nombre del programa",
        "appId": "ID de la aplicación",
        "descriptionProduct": "Descripción del producto",
        "brand": "Marca",
        "images": "Imágenes",
        "typeProduct": "Tipo de producto",
        "categoryProduct": "Categoría del producto",
        "nameProduct": "Nombre del producto",
        "quantity": "Cantidad",
        "publicServices": "Servicios públicos",
        "companyId": "ID de la empresa",
        "createdBy": "Creado por",
        "totalPoints": "Puntos totales",
        "user": "Usuario",
        "programId": "ID del programa",
        "id": "ID",
        "deliveries": "Entregas",
        "accountIDDoc": "ID de cuenta (documento)",
        "accountNumber": "Número de cuenta",
        "address": "Dirección",
        "addressLine1": "Dirección línea 1",
        "addressLine2": "Dirección línea 2",
        "adminArea1": "Área administrativa 1",
        "adminArea2": "Área administrativa 2",
        "amount": "Monto",
        "birthdate": "Fecha de nacimiento",
        "body": "Cuerpo",
        "cancelMessage": "Mensaje de cancelación",
        "cancelledMessage": "Mensaje de cancelación",
        "city_id": "ID de la ciudad",
        "city_name": "Nombre de la ciudad",
        "code": "Código",
        "contact": "Contacto",
        "country": "País",
        "footer": "Pie de página",
        "header": "Encabezado",
        "infoExtra": "Información extra",
        "lastname": "Apellido",
        "locality": "Localidad",
        "logo": "Logo",
        "name": "Nombre",
        "operator": "Operador",
        "paymentDate": "Fecha de pago",
        "paymentReference": "Referencia de pago",
        "phone": "Teléfono",
        "phone2": "Teléfono 2",
        "phoneNumber": "Número de teléfono",
        "phoneRecharge": "Recarga de teléfono",
        "postalCode": "Código postal",
        "puntoredRecaudo": "Punto red de recaudo",
        "quantum": "Quantum",
        "reloadableCard": "Tarjeta recargable",
        "reloadableCardNoIdDoc": "Tarjeta recargable sin ID de documento",
        "result": "Resultado",
        "site_id": "ID del sitio",
        "site_name": "Nombre del sitio",
        "successpuntoredRecaudoMessage": "Mensaje de éxito de punto red de recaudo",
        "ticked": "Marcado",
        "title": "Título",
        "trackingId": "ID de seguimiento",
        "transactionId": "ID de transacción",
        "username": "Nombre de usuario",
        "voucherCode": "Código de vale",
        "voucherUrl": "URL del vale"
    };

    addDisplayFields(orders: Array<object>): Array<object> {
        const cleanedOrders = this.removeProperties(orders.map(order => this.removeEmptyProperties(order)));
        
        cleanedOrders.sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return dateB.getTime() - dateA.getTime();
        });
    
        const ordersWithDisplayFields = this.addTranslationProperty(cleanedOrders, this.translationDict);
        const ordersWithStrategies = this.applyStrategies(ordersWithDisplayFields);
        
        return ordersWithStrategies;
    }
    

    private transformationStrategies: { [key: string]: (order: Order) => Order } = {
        publicServices: this.applyPublicServicesTransformation,
        quantum: this.applyQuantumTransformation,
        puntoredRecaudo: this.applyPuntoredRecaudoTransformation,
        phoneRecharge: this.applyPhoneRechargeTransformation,
    };

    private applyStrategies(orders: Array<Order>): Array<Order> {
        return orders.map(order => {
            const keys = Object.keys(order);
            for (const key of keys) {
                if (this.transformationStrategies[key]) {
                    return this.transformationStrategies[key].call(this, order);
                }
            }
            return order;
        });
    }


    // Esta función aplica una serie de transformaciones a cada objeto `order` en el array
    // y expande el campo `order.displayFields`, que es el responsable de contener los datos 
    // que se van a mostrar en el front-end.

    // Cada transformación debe estar orientada a preparar los campos del objeto para ser 
    // presentados de manera legible y clara al usuario.

    // El campo `displayFields` debe incluir únicamente aquellos campos que son relevantes 
    // para la presentación, con nombres de key que sean fácilmente comprensibles.

    // Cada campo que se adicione a `displayFields` debe seguir una convención de legibilidad 
    // en la clave, por ejemplo, en lugar de usar el nombre técnico del campo, se debería 
    // optar por uno que sea más descriptivo. Ejemplo: 
    // { "Dirección": "Carrera 32" } 

    // Este formato de clave asegura que el front-end presente información clara y precisa 
    // para el usuario, evitando términos técnicos o confusos.
    private applyPublicServicesTransformation(order: Order): Order {
        //Aplicar las transformaciones necesarias para ordenes publicServices
        return order;
    }

    private applyQuantumTransformation(order: Order): Order {
        //Aplicar las transformaciones necesarias para ordenes quantum
        return order;
    }

    private applyPuntoredRecaudoTransformation(order: Order): Order {
        //Aplicar las transformaciones necesarias para ordenes puntoredRecaudo
        return order;
    }

    private applyPhoneRechargeTransformation(order: Order): Order {
        //Aplicar las transformaciones necesarias para ordenes phoneRecharge
        return order;
    }

    removeEmptyProperties(obj: any): any {
        if (Array.isArray(obj)) {
            return obj.map(this.removeEmptyProperties.bind(this))
                .filter(item => item !== null && item !== undefined && Object.keys(item).length > 0);
        }

        if (obj === null || typeof obj !== 'object') {
            return obj !== '' && obj !== null && obj !== undefined ? obj : null;
        }

        const cleanedObject = Object.entries(obj).reduce((acc, [key, value]) => {
            const cleanedValue = this.removeEmptyProperties(value);

            if (cleanedValue !== null && cleanedValue !== undefined && cleanedValue !== '') {
                acc[key] = cleanedValue;
            }
            return acc;
        }, {});

        return Object.keys(cleanedObject).length > 0 ? cleanedObject : null;
    }

    removeProperties(orders: any[]): any[] {
        return orders.map(item => {
            const {
                voucherCode,
                voucherUrl,
                product,
                deliveries,
                uid,
                deliveryGlobalId,
                companyId,
                id,
                createdBy,
                ...rest
            } = item;

            const productRest = product ? {
                ...product
            } : {};

            delete productRest.descriptionProduct;
            delete productRest.images;

            return {
                ...rest,
                product: this.removeEmptyProperties(productRest)
            };
        });
    }

    translateKeys(obj: any, translationDict: { [key: string]: string }): any {
        if (Array.isArray(obj)) {
            return obj.map(item => this.translateKeys(item, translationDict));
        } else if (obj !== null && typeof obj === 'object') {
            return Object.keys(obj).reduce((acc, key) => {
                const translatedKey = translationDict[key] || key;
                acc[translatedKey] = this.translateKeys(obj[key], translationDict);
                return acc;
            }, {} as any);
        } else {
            return obj;
        }
    }

    addTranslationProperty(objects: any[], translationDict: { [key: string]: string }): any[] {
        return objects.map(obj => {
            const displayFields = this.translateKeys(obj, translationDict);
            return {
                ...obj,
                displayFields: displayFields
            };
        });
    }

}