import { CategoryService } from './../../../services/categories.service';
import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, Validators, UntypedFormGroup } from "@angular/forms";
import { environment } from "../../../../environments/environment";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { BrandService } from "../../../services/brand.service";
import { Brand, Category, CategoryRoot } from "../../../models/models";
import { CloudinaryService, PLUGINS_CLD } from "../../../services/cloudinary.service";
import Swal from "sweetalert2";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

declare var cloudinary: any;

@Component({
  selector: "app-brands-edit",
  templateUrl: "./brands-edit.component.html",
  styleUrls: ["./brands-edit.component.scss"]
})
export class BrandsEditComponent implements OnInit {
  typePage: string;
  loading = true;
  titlePage: string;
  brand: Brand;
  messageAlert: string;
  newImage: string;
  imagenDefault 
  publicId = environment.public_id_cloudinary;
  brandForm: UntypedFormGroup;
  widget;
  showHints: string;
  imageVersion: string;
  
  categories: Category[] = [];
  hasError
  dataCKDitor = ''
  plugins = PLUGINS_CLD
  public Editor = ClassicEditor;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private brandServices: BrandService,
    private categoryServices: CategoryService,
    private cloudinaryService: CloudinaryService,
    private router: Router
  ) {
    this.brandForm = this.formBuilder.group({
      name: ["", [Validators.required]],
      id: ["", [Validators.required]],
      categoryId: ["", [Validators.required]],
      description: [""],
      imageId: ["", [Validators.required]],
      imageVersion: ["", [Validators.required]],
    });
  }
  async ngOnInit() {
    this.loading = true;
    await this.checkTypePage();
    this.widget = cloudinary.createUploadWidget(
      this.selectConfigurationWidgetCloudinary(),
      this.handlerWidget
    );
    this.getCategories();
  }

  openWidgetCloudinary() {
    this.widget.open();
  }
  checkTypePage() {
    return new Promise<void>((resolve) => {
      this.route.params.subscribe(async (params: Params) => {
        if (params.id) {
          await this.loadBrand(params.id);
          this.loading = false;
          this.typePage = "edit";
          return resolve();
        }
        this.loading = false;
        this.typePage = "create";
        return resolve();
      });
    });
  }

  loadBrand(BrandId) {
    return new Promise<void>((resolve) => {
      this.brandServices.getBrand(BrandId).subscribe((brand) => {
        if (brand) {
          this.brand = { ...brand };
          this.brand.img = this.cloudinaryService.getCloudinaryImage(this.publicId,brand.imageId,brand.imageVersion)
          this.brandForm.controls.name.setValue(this.brand.name);
          this.brandForm.controls.id.setValue(this.brand.id);
          this.brandForm.controls.imageId.setValue(this.brand.imageId);
          this.brandForm.controls.imageVersion.setValue(this.brand.imageVersion);
          this.imageVersion = this.brand.imageVersion;

          if (this.brand.categoryId) this.brandForm.controls.categoryId.setValue(this.brand.categoryId);
          if (this.brand.description) this.brandForm.controls.description.setValue(this.brand.description);
          
        }

        resolve();
      });
    });
  }

  // this function  use was arrow function to get main this
  handlerWidget = (error, result) => {
    if (error) {
      return Swal.fire({
        icon: "error",
        title: "Parece que hay un error",
      }).then(() => Swal.close());
    }
    if (!error && result && result.event === "success") {
      this.newImage = result.info.public_id;
      this.imageVersion = result.info.version;
      this.brandForm.controls.imageId.setValue(this.getOnlyId());
      this.brandForm.controls.imageVersion.setValue(
        result.info.version.toString()
      );
      if (this.brand) {
        this.brand.img = this.cloudinaryService.getCloudinaryImage(this.publicId,this.getOnlyId(),result.info.version.toString());
      } else {
        this.imagenDefault = this.cloudinaryService.getCloudinaryImage(this.publicId,this.getOnlyId(),result.info.version.toString())
      }
    }
  };

  getOnlyId() {
    const pathImage: any = this.newImage.split("/");
    return pathImage[2];
  }

  save() {
    // submit button
    if (this.brandForm.status === "INVALID") {
      this.showHints = "Verifica Los campos";
      return;
    }
    // verify if make a post or put
    const methodToApi = this.typePage === "create" ? "postBrand" : "putBrand";
    // send
    this.brandServices[methodToApi]({ ...this.brandForm.value }).subscribe(
      (res) => {
        const title =
          this.typePage === "create"
            ? res.id + " se creo correctamente"
            : "Operacion realizada correctamente";
        if (res.id || res.updated) {
          return Swal.fire({
            icon: "success",
            title,
            confirmButtonColor: "#ff9800",
          }).then(() => this.goHome());
        }
        return Swal.fire({
          icon: "error",
          title: "Parece que hay un error",
          confirmButtonColor: "#DD6B55",
        }).then(() => Swal.close());
      }
    ),
      (err) => {
        return Swal.fire({
          icon: "error",
          title: "Parece que hay un error",
          confirmButtonColor: "#DD6B55",
        }).then(() => Swal.close());
      };
  }
  goHome() {
    // window.location.href = 'private/brands';
    this.router.navigate(["pages/brands/list"]);
  }

  selectConfigurationWidgetCloudinary() {
    if (this.typePage === "edit") {
      return {
        ...environment.widget_cloudinary_config,
        uploadSignature: this.cloudinaryService.generateSignature,
        publicId: this.brand.imageId,
        invalidate: true,
      };
    }
    return {
      ...environment.widget_cloudinary_config,
      uploadSignature: this.cloudinaryService.generateSignature,
    };
  }

  generateId(event: string) {
    if (this.typePage === "create") {
      let newId: string = event.replace(/[^a-zA-Z0-9/]+/g, "_");
      this.brandForm.controls.id.setValue(newId.toLowerCase());
    }
  }

  getCategories() {
   // this.loader = true;
    this.categoryServices.getCategories().subscribe((res: CategoryRoot) => {
      this.categories = res.data
    //  this.loader = false;
    },err => {
     // this.loader = false;
      this.hasError = true;
      console.error('getCategories error', err)
    });
  }

}
