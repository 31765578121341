import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { UtilsService } from "../../services/utils.service";
import { StorageService } from '../../services/storage.service';
import { Router } from "@angular/router";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  user;
  options;
  loader;
  account: any;
  profile: any;

  constructor(
    private authService: AuthService,
    private utilService: UtilsService,
    private storageService: StorageService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loader = true;
    this.getUserInfo();
    this.getRoutesOptions();
    this.account = this.storageService.get('account');
    this.profile = this.storageService.get('profile');
  }
  async getUserInfo() {
    this.authService.getUser().subscribe((res) => {
      this.user = res;
    });
  }
  async getRoutesOptions() {
    this.options = await this.utilService.getRoutesApplication();
    this.reOrderOptions();
  }
  reOrderOptions() {
    this.options.shift();
    this.loader = false;
  }
  redirect(children) {
    this.router.navigate([`${children.path}`]);
  }
}
