import { PERMISSIONS, PermissionsService } from './../../services/permissions/permissions.service';
import { PointsService } from './../../services/points.service';
import { AccountsDataSource } from './../../pages/points/components/accounts/accounts.component';
import { Component, OnInit, Output, Input, EventEmitter, OnDestroy } from '@angular/core';
import {
  AppUserOutput,
  AppUsersService,
  AppUsersList
} from '../../users-api';
import {UntypedFormControl} from '@angular/forms';
import {Observable, Subscription, of} from 'rxjs';
import {debounceTime, filter, map, distinctUntilChanged, switchMap, catchError} from 'rxjs/operators';

@Component({
  selector: 'app-app-user-selector',
  templateUrl: './app-user-selector.component.html',
  styleUrls: ['./app-user-selector.component.scss']
})
export class AppUserSelectorComponent implements OnInit, OnDestroy {
  @Output() userSelected = new EventEmitter<AppUserOutput>();
  @Output() appSelected$ = new EventEmitter<string>();


  @Input()
  set currentUser(user: AppUserOutput) {
    this.selectedUser = user;
    this.showUser = true;
  }

  @Input() showAppSelector = true;
  @Input() ViewSelectButton = true;

  @Input() currentAppId = '';
  @Input() ShowAccounts :boolean = false;

  selectedUser: AppUserOutput;
  searchBy = 'username';
  searchValue = new UntypedFormControl();
  searchResults: Observable<AppUserOutput[]>;
  searchResults$ : Subscription
  searching = false;
  showUser = false;

  dataSource = new AccountsDataSource();
  displayedColumns: string[] = ['id', 'balance', 'type','match', 'edit'];
  PERMISSIONS = PERMISSIONS
  constructor(
    private usersService: AppUsersService,private pointsService : PointsService, private permissions : PermissionsService
  ) {}

  ngOnInit() {
    this.showUser = this.selectedUser ? true : false;
    this.searchResults = this.searchValue.valueChanges.pipe(
      debounceTime(1000),
      map((input: string) => input.trim()),
      filter((input: string) => input !== ''),
      distinctUntilChanged(),
      switchMap((value: string) => {
        this.searching = true;
        let condition = `${this.searchBy} sw "${value}"`;
        if (this.currentAppId && this.currentAppId !== '') {
          condition = `${condition} and app_id eq "${this.currentAppId}"`;
        }
        return this.usersService.findAppUsers('5', null, null, null, condition);
      }),
      map((ans: AppUsersList) => {
        this.searching = false;
        
        return ans.data;
      })
      
      ,
      catchError((error: any) => {
        this.searching = false;
        console.error('AppUserSelectorComponent: finding users', error);
        return of(<AppUserOutput[]>[]);
      })
      
    );
    
    this.findAccounts();
  }

  onAppSelected(appId: string) {
    this.currentAppId = appId;
    this.appSelected$.next(appId)
  }

  async findAccounts() {
    if (this.ShowAccounts) {
      const hasPermission = await this.permissions.hasPermission(PERMISSIONS.VIEW_ACCOUNT);
      if (!hasPermission) return;
      try {

        this.searchResults$ = this.searchResults.subscribe(async (data) => {
          const uids = data.map((data: AppUserOutput) => data.id);
          const body = {
            appId: this.currentAppId,
            userList: uids
          }
          const accounts = await this.pointsService.listAccountsByUIDs(body).toPromise();
          data.forEach((user: AppUserOutput) => {
            const account = accounts[user.id];
            user.accounts = account
          }

          );
          this.searchResults = of(data);
        })

      } catch (error) {
        console.error('app-user-selector: searching accounts', error);
      }

    }
  }

  onUserSelected(user: AppUserOutput) {
    this.selectedUser = user;
    this.showUser = true;
    this.userSelected.emit(user);
  }

  switchToSearch() {
    this.showUser = false;
  }

  ngOnDestroy() {
    if(this.searchResults$) this.searchResults$.unsubscribe();
  }
}
