// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input.mat-mdc-chip-input {
  width: 100%;
}

.recognition-image {
  max-width: 192px;
}

.icon-help {
  font-size: 18px;
  margin: 0 0.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/recognitions/recognitions-form/recognitions-form.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;AACJ","sourcesContent":["input.mat-mdc-chip-input {\n    width: 100%;\n}\n\n.recognition-image{\n    max-width: 192px;\n}\n\n.icon-help{\n    font-size: 18px;\n    margin: 0 0.5rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
