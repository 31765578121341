// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#programs-tab-content mat-form-field {
  width: 100%;
}
#programs-tab-content mat-form-field input {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/apps/apps-editor/apps-editor.component.scss"],"names":[],"mappings":"AACE;EACE,WAAA;AAAJ;AAEI;EACE,WAAA;AAAN","sourcesContent":["#programs-tab-content {\n  mat-form-field {\n    width: 100%;\n\n    input {\n      width: 100%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
