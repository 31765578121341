import { AppsEditorProfileField } from './apps-editor-profile-field';
import { 
  App as AppInterface,
  ProfileFieldsObject,
} from '../../../users-api';

export class AppsEditorOutput {
  constructor(
    public appData: AppInterface,
    public profileFieldsData: Array<AppsEditorProfileField>,
  ) {}
}
