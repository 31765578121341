import { Observer } from 'rxjs';
import { category_indicator } from './../categories.model';
import { DataSource } from '@angular/cdk/collections';
import { CategoriesService } from './../../categories.service';
import { UntypedFormControl } from '@angular/forms';
import { StorageService } from './../../../../services/storage.service';
import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';


export class CategoriesDataSource extends DataSource<category_indicator> {
  observer: Observer<category_indicator[]>;

  refreshData(data: category_indicator[]) {
    if (this.observer) {
      this.observer.next(data);
    }
  }

  connect(): Observable<category_indicator[]> {
    return new Observable((observer: Observer<category_indicator[]>) => {
      this.observer = observer;
    });
  }

  disconnect() {
    this.observer = null;
  }
}
// import {formatDate} from '@angular/common';
@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {
  loading = false;
  pageSize = 100;
  dataSource = new CategoriesDataSource();
  appIdFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  nameCategory = new UntypedFormControl('');
  currentAppId: string;
  allowedAppIds: string[];
  allowAllAppIds = false;
  requiredFields: boolean = true;
  data: category_indicator[];
  resultsFound = true;
  displayedColumns = ['id'];
  hoveredRow: any;
  dataSearch:category_indicator[];
  dataFilter:category_indicator[];
  constructor(
    private router: Router,
    private storageService: StorageService,
    private categoriesService: CategoriesService
  ) {
    
  }

  async ngOnInit() {
    await this.initAllowedIds();
    this.getCategories();
  }

  initAllowedIds() {
    // Do not allow any app by default
    this.allowedAppIds = [];
    this.allowAllAppIds = false;
    const account = this.storageService.get('account');
    if (!account) {
      return;
    }
    if (account.roles.includes('super_admin')) {
      // Allow all ids
      this.allowedAppIds = null;
      this.allowAllAppIds = true;
      return;
    }
    const profile = this.storageService.get('profile');
    if (!profile) {
      return;
    }
    this.allowedAppIds = profile.authorized_apps || [];
  

 
  }

  getCategories(appId?,name?) {
    if(appId){
      this.requiredFields = true;
    } else {
      this.requiredFields = false;
      this.data = [];
      this.dataSource.refreshData(this.data);
      return;
    }
    this.loading = true;
    
    this.categoriesService.getCategories(appId).subscribe((data)=>{
      this.data = data.data;
      this.data = appId ? this.data.filter(x => x.app_id == appId) : this.data;
      this.data = name ? this.data.filter(x => x.name == name) : this.data;
      this.data = this.data.sort(function(a,b){
        return new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()})
        
      this.dataSource.refreshData(this.data);
      this.loading= false
      this.resultsFound = this.data.length > 0 ? true : false;

      if(!name){
        this.dataSearch = this.data;
        this.dataFilter = this.data;
      }
    })
  }

  /* onUserSelected(user: AppUserOutput) {} */

  onAppSelected(appId: string) {
    this.appIdFilter$.next(appId);
    this.currentAppId = appId;
    this.getCategories(this.currentAppId)
  }

 

  resetApp() {
    this.currentAppId = null;
  }

  onEdit(item:category_indicator) {
    this.router.navigate(['pages', 'categories', 'edit',item.id, item.app_id]);
  }
  onCreate(){
    this.router.navigate(['pages', 'categories', 'add']);
  }

  getRowBackgroundColor(row) {
     return row === this.hoveredRow ? '#F5F5F5' : 'transparent'; 
  }


  clearData(value){
    const val = String(value.target.value).toLowerCase();
    if(!val){
      this.getCategories(this.currentAppId);
    } else {
      this.dataFilter = this.dataSearch.filter(x => String(x.name).toLowerCase().includes(val))
    }
  }

  onIdOptionSelected(option){
    const name = option.option.value;
     this.dataFilter = name ? this.data.filter(x => x.name == name) : this.data;
    this.dataSource.refreshData(this.dataFilter); 
  }

  goToIcon(url){
    window.open(url, '_blank');
    return false;
  }

}
