
export interface preinvoiceSettings {
    data: preinvoiceSetting[];
}
export interface preinvoiceSetting {
    settings_preinvoice_id?: string;
    program_id: string;
    customer_id: string;
    type_preinvoice: [];
    type_product: [];
    estimate?: number;
    vat?: number;
    cutoff_date: string;
    execution_date: string;
    frecuency: number;
    active: boolean;
    internals_emails: string;
    copy_emails: string;
    error?: string;
    observations?: string,
    budget: number;
    ivas: [];
}

export const stateOptions = {
    'cancelled': 'Anulado',
    'erp_imported': 'Cargado al importador del ERP',
    'closed': 'Cerrado',
    'erp_created': 'Creado en el ERP',
    'delivered': 'Entregado',
    'delivered_gluky': 'Entregado Gluky',
    'delivered_to_gluky': 'Entregado a Gluky',
    'delivered_by_customer': 'Entregado por Cliente',
    'delivery_failed': 'Fallo Entrega',
    'pack_ready': 'Listo para envío',
    'new': 'Nuevo',
    'delivery_third': 'Pedido Tercero',
    'delivery_third_reported': 'Pedido de Tercero Reportado',
    'to_send': 'Por Despachar',
    'collected': 'Recogido',
    'dispatch': 'Recogido, sin WS Rastreo',
    'registered': 'Registrado',
    'suspended': 'Suspendido',
    
};

export const statesForSelect = Object.entries(stateOptions).map(([k, v]) => ({
    label: v+' | '+k,
    value: k,
  }));

export const typeProductOptions = {
    'bonos' : 'Bonos fisicos',
    'bonos_exito' : 'Bonos Éxito',
    'voucher' : 'Bono Quantum',
    'reloadable_card' : 'Daviplata',
    'nequi' : 'Nequi',
    'puntored_recaudo' : 'PuntoRed Recaudo',
    'product' : 'Producto',
    'custom_shirt' : 'Producto Personalizable Nutresa',
    'customer_property' : 'Propiedad Cliente',
    'cellphone_credit' : 'Recarga a celular',
    'public_services' : 'Servicios Publicos',
    'reloadable_card_no_iddoc' : 'Tarjeta Recargable',
    'total_pass' : 'Total Pass',
}

export const typeProductForSelect = Object.entries(typeProductOptions).map(([k, v]) => ({
    label: v+' | '+k,
    value: k,
  }));

export const ivaOptions = {
    0: '0%',
    700: '7%',
    1200: '12%',
    1300: '13%',
    1400: '14%',
    1500: '15%',
    1600: '16%',
    1800: '18%',
    1900: '19%',
};

export const ivaForSelect = Object.entries(ivaOptions).map(([k, v]) => ({
    label: v,
    value: k,
  }));