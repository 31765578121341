import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';

@Injectable()
export class AuthGuardService  {
  constructor(
    private router: Router,
    private authService: AuthService
  ) {}

  redirectToLogin() {
    this.router.navigate(['login']);
    return false;
  }

  async canActivate() {
    try {
      const idToken = await this.authService.getIdToken();
      if (idToken) {
        const gnToken = await this.authService.getGNAccessToken();
        if (gnToken && typeof gnToken === 'string') {
          return true;
        }
      }
      return this.redirectToLogin();
    } catch(e)  {
      console.warn(`AuthGuardService: canActivate: ${e}`);
      return this.redirectToLogin();
    }
  }
}
