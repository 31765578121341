import { Injectable } from "@angular/core";

@Injectable()
export class MainSidenavService {
  constructor() {}

  isLargeScreen() {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    let large: boolean;
    large = width > 1024 ? true : false;
    return large;
  }
}
