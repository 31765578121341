import { Component, OnInit } from "@angular/core";
import { BrandService } from "../../../services/brand.service";
import { BrandRoot, Brand } from "../../../models/models";
import { environment } from "../../../../environments/environment";
import { Router } from "@angular/router";
import { Cloudinary, CloudinaryImage } from "@cloudinary/url-gen";
import { CloudinaryService, PLUGINS_CLD } from "../../../services/cloudinary.service";

@Component({
  selector: "app-brands-list",
  templateUrl: "./brands-list.component.html",
  styleUrls: ["./brands-list.component.scss"],
})
export class BrandsListComponent implements OnInit {
  loader = false;
  brands: Brand[] = [];
  brands_filtered: Brand[] = [];
  publicId: string = environment.public_id_cloudinary;
  img!: CloudinaryImage
  plugins = PLUGINS_CLD
  constructor(
    private brandServices: BrandService,
    private router: Router,
    private cldService: CloudinaryService,
  ) {}
  
  
  ngOnInit() {
    this.loader = true;
    this.getBrands();
   
  }

  cldImg(id, versionImg?){
    const cld = new Cloudinary({
      cloud: environment.widget_cloudinary_config
    })
   // const img: CloudinaryImage = cld.image(`${this.publicId}${id}.png`) || undefined;
   const  img = this.cldService.getCloudinaryImage(this.publicId,id,versionImg)
  return img
  } 


  getBrands() {
    this.loader = true;
    this.brandServices.getBrands().subscribe((res: BrandRoot) => {
      this.brands.push(...res.data);
      
      this.brands_filtered = res.data.map((element) => {
        element.img = this.cldService.getCloudinaryImage(this.publicId,element.imageId,element.imageVersion)
        return element
      })
      console.log('this.brands_filtered = ', this.brands_filtered);
      
      this.loader = false;
    });
  }
  searchBrand(filterValue: string) {
    // buscar marca
    if (filterValue) {
      this.brands_filtered = this.brands.filter((element) => {
        return element.name.toLowerCase().startsWith(filterValue.toLowerCase());
      })

    } else {
      /* this.brands_filtered = JSON.parse(JSON.stringify(this.brands)) */
      this.brands_filtered  = JSON.parse(JSON.stringify(this.brands)).map((element) => {
        element.img = this.cldService.getCloudinaryImage(this.publicId,element.imageId,element.imageVersion)
        return element
      })
    }
  }
  redirect() {
    this.router.navigate(["pages/brands/create"]);
  }
}
