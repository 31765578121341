import { PERMISSIONS } from './../../../services/permissions/permissions.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core";
import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";

import { Company } from "../../../users-api";
import { Countries } from "../../../countries";
import { AutoIdService } from "../../../auto-id.service";

@Component({
  selector: "app-companies-editor",
  templateUrl: "./companies-editor.component.html",
  styleUrls: ["./companies-editor.component.scss"],
})
export class CompaniesEditorComponent implements OnInit, OnChanges {
  companyForm: UntypedFormGroup;
  countries = Countries.COUNTRIES;

  @Input() company: Company;
  @Input() mode: string = "create";

  @Output() onOK = new EventEmitter<Company>();
  @Output() onCancel = new EventEmitter<void>();
  PERMISSIONS = PERMISSIONS
  constructor(
    private fb: UntypedFormBuilder,
    private snackBar: MatSnackBar,
    private autoId: AutoIdService
  ) {
    this.createForm();
  }

  createForm() {
    this.companyForm = this.fb.group({
      id: [
        { value: "", disabled: true },
        [Validators.required, Validators.pattern(/^\w+$/)],
      ],
      name: ["", [Validators.required]],
      id_doc: ["", [Validators.required]],
      country: ["", [Validators.required]],
      auto_id: this.mode == "create" ? true : { value: false, disabled: true },
    });
    const name = this.companyForm.get("name");
    const id = this.companyForm.get("id");
    const auto_id = this.companyForm.get("auto_id");
    name.valueChanges.subscribe((value: string) => {
      if (auto_id.value && this.mode == "create") {
        id.setValue(this.autoId.buildAutoId(value));
      }
    });
    auto_id.valueChanges.subscribe((value: boolean) => {
      if (this.mode == "create") {
        if (value) {
          id.setValue(this.autoId.buildAutoId(name.value));
          id.disable();
        } else {
          id.enable();
        }
      }
    });
  }

  ngOnInit() {}

  ngOnChanges() {
    this.companyForm.patchValue(this.company);
    this.companyForm.patchValue({ auto_id: true });
    this.companyForm.get("id").disable();
    if (this.mode != "create") {
      this.companyForm.get("auto_id").disable();
    } else {
      this.companyForm.get("auto_id").enable();
    }
  }

  cancelClicked() {
    this.onCancel.emit();
    return false;
  }

  OKClicked() {
    let valueCopy = Object.assign({}, this.companyForm.getRawValue());
    delete valueCopy.auto_id;
    let company: Company = valueCopy as Company;
    this.onOK.emit(company);
    return false;
  }
}
