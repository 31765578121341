import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PointsService } from "../../../../services/points.service";
import {
  PermissionsService,
  PERMISSIONS,
} from "../../../../services/permissions/permissions.service";
import { StorageService } from "../../../../services/storage.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-update-expiry-date-points",
  templateUrl: "./update-expiry-date-points.component.html",
  styleUrls: ["./update-expiry-date-points.component.scss"],
})
export class UpdateExpiryDatePointsComponent implements OnInit {
  PERMISSIONS = PERMISSIONS;
  expiryDateForm: FormGroup;
  programs: string[] = [];
  apps: string[] = [];
  minDate: Date;
  displayedColumns = ["Id", "name", "pointName", "edit"];
  timeZones: { value: string; offset: string }[] = [
    { value: "America/Bogota", offset: "-05:00" }, // Colombia
    { value: "America/Mexico_City", offset: "-06:00" }, // México
    { value: "America/Guayaquil", offset: "-05:00" }, // Ecuador
    { value: "America/Lima", offset: "-05:00" }, // Perú
    { value: "America/Santiago", offset: "-03:00" }, // Chile
    { value: "America/Costa_Rica", offset: "-06:00" }, // Costa Rica
    { value: "America/Guatemala", offset: "-06:00" }, // Guatemala
    { value: "America/Panama", offset: "-05:00" }, // Panamá
    { value: "America/New_York", offset: "-05:00" }, // Estados Unidos
    { value: "America/Caracas", offset: "-04:00" }, // Venezuela
  ];
  loader = false;
  jobs = [];
  isLoadingJobs = false;

  constructor(
    private fb: FormBuilder,
    private pointsService: PointsService,
    private storageService: StorageService,
    private permissionsService: PermissionsService
  ) {}

  ngOnInit() {
    this.isLoadingJobs = true;
    this.loader = false;
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.expiryDateForm = this.fb.group({
      appId: [null, Validators.required],
      timeZone: [null, Validators.required],
      newExpiryDate: [null, Validators.required],
      newExpiryTime: [null, Validators.required],
      creditExpiryFrom: [null],
      creditExpiryTo: [null],
      includeNullDateValues: [false],
      description: [null],
      programInput: [null],
    });
    this.apps = this.storageService.get("profile").authorized_apps;
    this.pointsService.getExpiryDateJobs().subscribe(
      (response) => {
        this.jobs = Array.isArray(response) ? response : [response];
        this.isLoadingJobs = false;
      },
      (err) => {
        console.error("Error del endpoint:", err);
        Swal.fire({
          icon: "error",
          title: "Error al obtener los trabajos",
          text: "Ha ocurrido un error al intentar obtener los trabajos creados.",
        });
      }
    );
  }

  addProgram() {
    const programInput = this.expiryDateForm.get("programInput")?.value;

    if (!programInput) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "El campo de programa no puede estar vacío.",
      });
      return;
    }

    const validProgramPattern = /^[a-z_]+$/;
    if (!validProgramPattern.test(programInput)) {
      Swal.fire({
        icon: "error",
        title: "Formato inválido",
        text: "El programa debe contener solo letras minúsculas y el carácter '_'.",
      });
      return;
    }

    if (this.programs.includes(programInput)) {
      Swal.fire({
        icon: "error",
        title: "Duplicado",
        text: "El programa ya está agregado.",
      });
      return;
    }

    this.programs.push(programInput.trim());
    this.expiryDateForm.get("programInput")?.reset();
  }

  removeProgram(program: string) {
    this.programs = this.programs.filter((p) => p !== program);
  }

  onSubmit() {
    if (this.expiryDateForm.invalid) {
      Swal.fire({
        icon: "error",
        title: "Formulario inválido",
        text: "Por favor, completa todos los campos obligatorios.",
      });
      return;
    }

    const formValues = this.expiryDateForm.value;

    // Validar fechas "Desde" y "Hasta"
    if (formValues.creditExpiryFrom && formValues.creditExpiryTo) {
      const creditExpiryFrom = this.formatDateToYYYYMMDD(
        formValues.creditExpiryFrom
      );
      const creditExpiryTo = this.formatDateToYYYYMMDD(
        formValues.creditExpiryTo
      );

      if (
        !creditExpiryFrom ||
        !creditExpiryTo ||
        creditExpiryFrom > creditExpiryTo
      ) {
        Swal.fire({
          icon: "error",
          title: "Error en fechas",
          text: 'La fecha "Desde" debe ser menor o igual que "Hasta".',
        });
        return;
      }

      const selectedTimeZone = this.timeZones.find(
        (tz) => tz.value === formValues.timeZone
      )?.offset;
      if(creditExpiryFrom){

        formValues.creditExpiryFrom = `${creditExpiryFrom}T00:00:00${selectedTimeZone}`;
      }
      if(creditExpiryTo){
        formValues.creditExpiryTo = `${creditExpiryTo}T23:59:59${selectedTimeZone}`;
      }
    }

    // Validar nueva fecha de expiración
    const today = this.formatDateToYYYYMMDD(new Date());
    if (formValues.newExpiryDate < today) {
      Swal.fire({
        icon: "error",
        title: "Fecha inválida",
        text: "La nueva fecha de vencimiento debe ser igual o mayor que hoy.",
      });
      return;
    }

    // Construir la fecha y hora con zona horaria
    const newExpiryDate = this.formatDateTime(
      formValues.newExpiryDate,
      formValues.newExpiryTime,
      formValues.timeZone
    );

    if (!newExpiryDate) {
      Swal.fire({
        icon: "error",
        title: "Error en fecha de expiración",
        text: "La fecha y hora de expiración no son válidas.",
      });
      return;
    }

    const payload = {
      appId: formValues.appId,
      timeZone: formValues.timeZone,
      newExpiryDate,
      creditExpiryFrom: formValues.creditExpiryFrom,
      creditExpiryTo: formValues.creditExpiryTo,
      includeNullDateValues: formValues.includeNullDateValues,
      description: formValues.description,
      programMembers: this.programs,
    };

    const cleanedPayload = Object.keys(payload).reduce((acc, key) => {
      const value = payload[key as keyof typeof payload];
      if (value !== null && value !== undefined) {
        acc[key] = value;
      }
      return acc;
    }, {} as Record<string, any>);

    this.isLoadingJobs = true;
    this.pointsService.updateExpiryDatePoints(cleanedPayload).subscribe(
      () => {
        this.pointsService.getExpiryDateJobs().subscribe(
          (response) => {
            this.jobs = Array.isArray(response) ? response : [response];
            this.isLoadingJobs = false;
          },
          (err) => {
            this.isLoadingJobs = false;
            console.error("Error del endpoint:", err);
            Swal.fire({
              icon: "error",
              title: "Error al obtener los trabajos",
              text: "Ha ocurrido un error al intentar obtener los trabajos creados.",
            });
          }
        );
        Swal.fire({
          icon: "success",
          title: "Trabajo creado con éxito",
          text: "EL trabajo para actualizar fecha de vencimiento de puntos ha sido creado de manera exitosa.",
        });
        this.expiryDateForm.reset();
        this.programs = [];
      },
      (err) => {
        this.isLoadingJobs = false;
        console.error(err);
        Swal.fire({
          icon: "error",
          title: "Error al crear el trabajo",
          text: err.error.message,
        });
      }
    );
  }

  refreshData() {
    this.isLoadingJobs = true;
    this.pointsService.getExpiryDateJobs().subscribe(
      (response) => {
        this.jobs = Array.isArray(response) ? response : [response];
        this.isLoadingJobs = false;
      },
      (err) => {
        console.error("Error del endpoint:", err);
        Swal.fire({
          icon: "error",
          title: "Error al obtener los trabajos",
          text: "Ha ocurrido un error al intentar obtener los trabajos creados.",
        });
      }
    );
  }

  formatDateToYYYYMMDD(date: Date | string): string {
    const parsedDate = new Date(date);
    const year = parsedDate.getFullYear();
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
    const day = parsedDate.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  formatDateTime(date: string, time: string, timeZone: string): string | null {
    const dateValid = this.formatDateToYYYYMMDD(date);
    const timeMatch = time?.match(/^(\d{1,2}):(\d{2})\s?(AM|PM)$/);

    if (!dateValid || !timeMatch) {
      Swal.fire({
        icon: "error",
        title: "Hora inválida",
        text: "Por favor, introduce una hora válida en formato AM/PM.",
      });
      return null;
    }

    let hours = parseInt(timeMatch[1], 10);
    const minutes = timeMatch[2];
    const meridian = timeMatch[3];

    if (meridian === "PM" && hours < 12) hours += 12;
    if (meridian === "AM" && hours === 12) hours = 0;

    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes}:59`;
    const selectedTimeZone = this.timeZones.find(
      (tz) => tz.value === timeZone
    )?.offset;

    if (!selectedTimeZone) {
      Swal.fire({
        icon: "error",
        title: "Zona horaria inválida",
        text: "Selecciona una zona horaria válida.",
      });
      return null;
    }

    return `${dateValid}T${formattedTime}${selectedTimeZone}`;
  }
}
