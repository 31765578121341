import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';

import { AuthService } from '../services/auth.service';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ServerHttpInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
  ) {}

  defineAuthReq(req: HttpRequest<any>, token: string) {
    return req.clone({
      setHeaders: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        Authorization: `Bearer ${token}`,
      },
      setParams: {
        key: environment.apikey,
      },
    });
  }

  call(next: HttpHandler, req: HttpRequest<any>, token: string) {
    const authReq = this.defineAuthReq(req, token);
    return next.handle(authReq);
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.headers.get('X-Skip-Interceptor') === '') {
      return next.handle(req);
    }
    return from(this.authService.getGNAccessToken()).pipe(switchMap(
      (gnJwt: string) => this.call(next, req, gnJwt)
    ));
  }
}
