import { PERMISSIONS } from './../../services/permissions/permissions.service';
/* eslint-disable max-len */
import { DataSource } from '@angular/cdk/collections';
import { Component, OnInit } from '@angular/core';
import { Observable, Observer, BehaviorSubject } from 'rxjs';
import { preinvoiceSettings, preinvoiceSetting, typeProductOptions, stateOptions, ivaOptions } from '../../models/preinvoice/preinvoice-settings-model';
import { OrdersCentralService } from '../../services/orders-central.service';
import { Router } from '@angular/router';
import { StorageService } from '../../services/storage.service';
import { UntypedFormControl } from '@angular/forms';

export class PreinvoiceSettingsDataSource extends DataSource<preinvoiceSetting> {
  observer: Observer<preinvoiceSetting[]>;

  refreshData(data: preinvoiceSetting[]) {
    if (this.observer) {
      this.observer.next(data);
    }
  }

  connect(): Observable<preinvoiceSetting[]> {
    return Observable.create((observer: Observer<preinvoiceSetting[]>) => {
      this.observer = observer;
    });
  }

  disconnect() {
    this.observer = null;
  }
}

@Component({
  selector: 'app-preinvoice-settings',
  templateUrl: './preinvoice-settings.component.html',
  styleUrls: ['./preinvoice-settings.component.scss']
})
export class PreinvoiceSettingsComponent implements OnInit {
  PERMISSIONS = PERMISSIONS
  dataSource = new PreinvoiceSettingsDataSource();
  displayedColumns = ['id'];
  pageSize = 100;
  loading = false;
  hoveredRow: any;
  pagerSteps = [100, 200, 500, 1000];
  prevCursor: string;
  nextCursor: string;
  firstCursor: string;
  requiredFields: boolean = false;
  resultsFound = true;
  allowedCompanyIds: string[];
  allowAllCompanyIds = false;
  foundNumber: number;

  companyIdFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  programIdFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  idFilter$: BehaviorSubject<string | null> = new BehaviorSubject(null);
  idOptions: Observable<string[]>;
  currentCompanyId: string;
  currentProgramId: string;
  resultsNumberLimit = 100;
  data;
  
  
  constructor(
    private ordersCentralService: OrdersCentralService,
    private storageService: StorageService,
    private router: Router,
  ) { }

  async ngOnInit() {
    await this.initAllowedIds();
  }

  async getSettingsPreinvoice(companyId?, programId?){
    if(companyId){
      this.requiredFields = true;
    } else {
      this.requiredFields = false;
      this.data = [];
      this.dataSource.refreshData(this.data);
      return;
    }
    let fullProgram: string;
    let divProgram: string[];
    if(programId) {
      console.log(programId);
      fullProgram = programId;
      divProgram = fullProgram.split('/')
      console.log(divProgram)
    }
    const query = {
      'companyId': companyId ? companyId : null,
      'programId': programId ? divProgram[3] : null
    };
    await this.ordersCentralService.listSettingsPreinvoice(query).subscribe((res: preinvoiceSettings) => {
      this.data = res.data;
      this.dataSource.refreshData(this.data);
      this.resultsFound = this.data.length > 0 ? true : false;
    });
  }

  onCompanySelected(companyId: string) {
    this.companyIdFilter$.next(companyId);
    this.currentCompanyId = companyId;
    this.currentProgramId = null;
    this.getSettingsPreinvoice(companyId, this.currentProgramId);
  }

  onProgramSelected(programId: string) {
    this.programIdFilter$.next(programId);
    this.currentProgramId = programId;
    this.getSettingsPreinvoice(this.currentCompanyId, programId);
  }

  onCreate(){
    this.router.navigate(['pages', 'preinvoiceSettings', 'create']);
  }

  onEdit(item){
    this.router.navigate(['pages', 'preinvoiceSettings', 'edit', item.settings_preinvoice_id]);
  }

  resetCompany() {
    this.currentCompanyId = null;
    this.getSettingsPreinvoice(this.currentCompanyId, this.currentProgramId);
  }

  resetProgram() {
    this.currentProgramId = null;
  }

  getRowBackgroundColor(row) {
    return row === this.hoveredRow ? '#F5F5F5' : 'transparent';
  }

  initAllowedIds() {
    // Do not allow any app by default
    this.allowedCompanyIds = [];
    this.allowAllCompanyIds = false;
    const account = this.storageService.get('account');
    if (!account) {
      return;
    }
    if (account.roles.includes('super_admin')) {
      // Allow all ids
      this.allowedCompanyIds = [];
      this.allowAllCompanyIds = true;
      return;
    }
  }

  typeProductoVisible(items){
  const product = items.map(item =>
    typeProductOptions[item]);
  const listproduct = product.join(', ');
  return listproduct;
  }

  stateDeliveryVisible(items){
    const state = items.map(item =>
      stateOptions[item]);
    const liststate = state.join(', ');
    return liststate;
  }

  ivasVisible(items){
    const ivas = items.map(item =>
      ivaOptions[item]);
    const listivas = ivas.join(', ');
    return listivas;
  }

}

