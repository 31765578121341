/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-unused-vars */
import { Component, OnInit, booleanAttribute } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray, UntypedFormControl } from '@angular/forms';
import { CompaniesUtilService } from '../../../services/companies-util.service';
import { CompanyOutput, ProgramFull, CompaniesService } from '../../../users-api';
import { ivaForSelect, preinvoiceSetting, statesForSelect, typeProductForSelect } from '../../../models/preinvoice/preinvoice-settings-model';
import { ENTER, SPACE } from '@angular/cdk/keycodes';
import { OrdersCentralService } from '../../../services/orders-central.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import {StorageService} from '../../../services/storage.service';
import { ProgramsUtilService } from '../../../services/programs-util.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatChipInputEvent } from '@angular/material/chips';
import moment from 'moment';

@Component({
  selector: 'app-preinvoice-settings-form',
  templateUrl: './preinvoice-settings-form.component.html',
  styleUrls: ['./preinvoice-settings-form.component.scss']
})
export class PreinvoiceSettingsFormComponent implements OnInit {

  preinvoiceSettingForm: FormGroup;
  mode = 'create';
  settingsData: preinvoiceSetting;
  saved = false;
  isSending = false;
  uriSeparatorKeyCodes = [ENTER, SPACE];
  fieldSeparatorKeyCodes = [ENTER];

  name = 'Nueva Parametrización Prefactura';
  statesDelivery = statesForSelect;
  typeProduct = typeProductForSelect;
  ivasOptions = ivaForSelect;

  data;
  //Companies
  companiesOptions: string[] = [];
  filteredCompaniesOptions: string[];
  objectsCompaniesData: CompanyOutput[];
  companiesNameMap: Map<string, CompanyOutput> = new Map();
  companiesIdMap: Map<string, CompanyOutput> = new Map();
  companyId = "";
  companyNameInputControl = new UntypedFormControl();

  //Programs
  programsOptions = [];
  filteredProgramsOptions: Observable<string[]>;
  programs = [];

  constructor(
    private formBuilder: FormBuilder,
    private ordersCentralService: OrdersCentralService,
    private router: Router,
    private route: ActivatedRoute,
    private companiesUtilService: CompaniesUtilService,
    private programsUtilService: ProgramsUtilService,
  ) {
    this.initConstructor();
  }

  ngOnInit() {
    this.initCompanies();

    // Filter programs
    this.filteredProgramsOptions = this.preinvoiceSettingForm.controls.programInputControl.valueChanges
      .pipe(startWith(null))
      .pipe(
        map((val: any) =>
          val
          ? this.filtersAutoComplete(val, 'programsOptions')
          : this.programsOptions.slice()
        )
      );
    // Filter app names
    this.companyNameInputControl.valueChanges
      .pipe(startWith(null))
      .subscribe((val: string) => {
        if (val) {
          const nameParts = val.split('|').map((part: string) => part.trim());
          let company =
            nameParts.length > 1
              ? this.companiesIdMap.get(nameParts[1])
              : this.companiesIdMap.get(nameParts[0].toLowerCase());
          company = company ? company : this.companiesNameMap.get(nameParts[0].toLowerCase());
    
          const value = val.split(' | ').length > 1 ? val.split(' | ')[1] : val;
          this.companyId = value;
          const findObs = this.programsUtilService.getProgramsCompany(value);
          findObs.then((programsList: ProgramFull[]) => {
              if (!programsList) {
                return;
              }
              const ids = programsList.map((program) => program.id);
              this.programsOptions.push(...ids);
              this.preinvoiceSettingForm.controls.programInputControl.setValue('', { emitEvent: true });
            });
          }
    
          this.filteredCompaniesOptions = val
            ? this.filtersAutoComplete(val, 'companiesOptions')
            : this.companiesOptions.slice();
      });
    
    const id = this.route.snapshot.paramMap.get('id');
    if(id){
      this.mode = 'edit';
      this.name = 'Editar Parametrización Prefactura';
      this.getPreinvoiceSetting(id);
    }
  }

  initConstructor() {
    this.preinvoiceSettingForm = this.formBuilder.group({
      budget: [
        0,
        Validators.compose([
          Validators.min(0),
        ])
      ],
      frecuency: [
        1,
        Validators.compose([
          Validators.required,
          Validators.min(1),
        ])
      ],
      ivas: [
        ivaForSelect,
        Validators.compose([
          Validators.required,
        ]),
      ],
      stateDeliveryOptions: [
        statesForSelect,
        Validators.compose([
          Validators.required,
        ]),
      ],
      typeProductOptions: [
        typeProductForSelect,
        Validators.compose([
          Validators.required,
        ]),
      ],
      cutoffDate: [
        '',
        Validators.compose([
          Validators.required,
        ]),
      ],
      executionDate: [
        '',
        Validators.compose([
          Validators.required,
        ]),
      ],
      internalsEmail: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
        ]),
      ],
      copyEmail: [
        '',
        Validators.compose([
          Validators.minLength(3),
        ]),
      ],
      active: [
        booleanAttribute(false),
        Validators.compose([
          Validators.required,
        ]),
      ],
      programInputControl: [
        "",
      ]
    });
  }

  getPreinvoiceSetting(id){
    this.ordersCentralService.getSettingsPreinvoice(id).subscribe((res: preinvoiceSetting) => {
      this.data = res;
      this.companyNameInputControl.setValue(res.customer_id);
      this.companyNameInputControl.disable();
      const programFull = ['companies/'+res.customer_id+'/programs/'+res.program_id];
      this.preinvoiceSettingForm.controls.programInputControl
      this.preinvoiceSettingForm.controls.programInputControl.setValue(programFull);
      this.preinvoiceSettingForm.controls.programInputControl.disable();
      this.preinvoiceSettingForm.controls.active.setValue(res.active);
      this.preinvoiceSettingForm.controls.budget.setValue(res.budget/100);
      this.preinvoiceSettingForm.controls.frecuency.setValue(res.frecuency);
      this.preinvoiceSettingForm.controls.ivas.setValue(res.ivas.map(String));
      this.preinvoiceSettingForm.controls.stateDeliveryOptions.setValue(res.type_preinvoice);
      this.preinvoiceSettingForm.controls.typeProductOptions.setValue(res.type_product);
      this.preinvoiceSettingForm.controls.cutoffDate.setValue(moment(res.cutoff_date).format('yyyy-MM-DDThh:mm'));
      this.preinvoiceSettingForm.controls.executionDate.setValue(moment(res.execution_date).format('yyyy-MM-DDThh:mm'));
      this.preinvoiceSettingForm.controls.internalsEmail.setValue(res.internals_emails);
      this.preinvoiceSettingForm.controls.copyEmail.setValue(res.copy_emails);
      programFull.map(program => (this.programs.push(program)));
    });
  }

  filtersAutoComplete(value: string, type: string) {
    return this[type].filter(
      (option: string) =>
        option && option.toLowerCase().indexOf(value.toLowerCase()) >= 0
    );
  }
  

  cancelClicked() {
    this.router.navigateByUrl('/pages/preinvoiceSettings');
  }

  toDataURL(url) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function() {
        const reader = new FileReader();
        reader.onloadend = function() {
          resolve(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    });
  }


  

  setCompanies(companies: CompanyOutput[]) {
    this.objectsCompaniesData = companies;
    this.companiesOptions = companies.map(
      (company: CompanyOutput) => `${company.name} | ${company.id}`
    );    
    this.companiesNameMap = companies.reduce(
      (companiesMap: Map<string, CompanyOutput>, company: CompanyOutput) => {
        companiesMap.set(company.name.toLowerCase(), company);
        return companiesMap;
      },
      new Map()
    );    
    this.companiesIdMap = companies.reduce(
      (companiesMap: Map<string, CompanyOutput>, company: CompanyOutput) => {
        companiesMap.set(company.id, company);
        return companiesMap;
      },
      new Map()
    );
  }
  
  async initCompanies() {
    try {
      const companies = await this.companiesUtilService.getCompanies();
      this.setCompanies(companies);
    } catch (error) {
      console.error('Error getting apps', error);
    }
  }

  addProgram(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value.trim();
      if (value && input) {
        // Check input program is known
        if (this.programsOptions.indexOf(value) < 0) {
          return;
        }
        // Init property if non existent
        if (!this.programs) {
          this.programs = [];
        }
        // Check value is not repeated
        if (this.programs.indexOf(value) >= 0) {
          return;
        }
        // Add value
        this.programs.push(value);
        input.value = '';
      }
    }
  
    removeProgram(program: string) {
      const index = this.programs.indexOf(program);
      if (index >= 0) {
        this.programs.splice(index, 1);
      }
    }

    async OKClicked() {
      if(this.isSending) {
        return;
      }
      this.isSending = true;
  
      const programFull = this.programs[0] ? this.programs[0] : this.preinvoiceSettingForm.controls.programInputControl.value;     
      const partProgram = programFull.split('/');
      const ivasString = this.preinvoiceSettingForm.controls.ivas.value;
      const ivasNumeros = ivasString.map(Number);
      const body = {
        'program_id':  partProgram[3],
        'customer_id': this.companyId,
        'type_preinvoice': this.preinvoiceSettingForm.controls.stateDeliveryOptions.value,
        'type_product': this.preinvoiceSettingForm.controls.typeProductOptions.value,
        'budget': this.preinvoiceSettingForm.controls.budget.value,
        'ivas': ivasNumeros,
        'cutoff_date': new Date(this.preinvoiceSettingForm.controls.cutoffDate.value).toISOString(),
        'execution_date': new Date(this.preinvoiceSettingForm.controls.executionDate.value).toISOString(),
        'frecuency': this.preinvoiceSettingForm.controls.frecuency.value,
        'active': this.preinvoiceSettingForm.controls.active.value,
        'internals_emails': this.preinvoiceSettingForm.controls.internalsEmail.value,
        'copy_emails': this.preinvoiceSettingForm.controls.copyEmail.value,
      }
  
      if(this.mode === 'create'){
        this.ordersCentralService.postSettingsPreinvoice(body).subscribe((res: any) => {
          if(res.code === 'INSERT_SETTINGS_PREINVOICE'){
            this.isSending = false;
            Swal.fire({
              icon: 'success',
              title: res.message,
              confirmButtonColor: '#ff9800',
            }).then(() => {
              Swal.close();
              this.router.navigateByUrl('/pages/preinvoiceSettings');
            });
          } else {
            this.isSending = false;
            console.error('Error in createSettigsPreinvoice', res);
            const data = res;
            Swal.fire({
              icon: 'warning',
              title: data.message,
              confirmButtonColor: '#ff9800',
            });
            return;
          }
        },
        (err) => {
          console.error(err.error);
          const data = err.error;
          this.isSending = false;
          if(data.code === 'SETTINGS_PREINVOICE_EXIST'){
          }
          Swal.fire({
            icon: 'warning',
            title: data.message,
            confirmButtonColor: '#ff9800',
          });
        });
      } else {
        body['settings_preinvoice_id'] = this.route.snapshot.paramMap.get('id');
        this.ordersCentralService.putSettingsPreinvoice(body).subscribe((res: any) => {
          if(res.code === 'UPDATE_SETTINGS_PREINVOICE'){
            this.isSending = false;
            Swal.fire({
              icon: 'success',
              title: res.message,
              confirmButtonColor: '#ff9800',
            }).then(() => {
              Swal.close();
              this.router.navigateByUrl('/pages/preinvoiceSettings');
            });
          }
        },
        (err) => {
          this.isSending = false;
          console.error(err);
          Swal.fire({
            icon: 'error',
            title: 'Error guardando',
            text: 'Por favor intente de nuevo'
          });
        });
      }
  
    }
}

