import { category_indicator } from './../categories.model';
import { isArray } from 'lodash';
import { startWith } from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppsUtilService } from './../../../../services/apps-util.service';
import { ProgramsUtilService } from './../../../../services/programs-util.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CategoriesService } from '../../categories.service';
import { SectionsService } from '../../sections.service';
import { AppsService, AppOutput } from '../../../../users-api';
import { sections } from '../../sections/sections.model';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-category-add',
  templateUrl: './category-add.component.html',
  styleUrls: ['./category-add.component.scss']
})
export class CategoryAddComponent implements OnInit {
  mode = 'create'
  name = "Nueva Categoria"

  categoryForm: UntypedFormGroup;

  applicationsOptions: string[] = [];
  filteredAppsOptions: string[];
  objectsAppsData: AppOutput[];
  appsNameMap: Map<string, AppOutput> = new Map();
  appsIdMap: Map<string, AppOutput> = new Map();
  appId = "";
  dataCKDitor = "";
  data:category_indicator;
  dataSection:sections;
  editedIcon = false;
  isSending = false;
  loading = false;
  dataListSection: any = [];

  constructor(

    private formBuilder: UntypedFormBuilder,
    private appsUtilService: AppsUtilService,
    private categoriesService: CategoriesService,
    private router: Router,
    private route: ActivatedRoute,
    private sectionsService: SectionsService,
  ) {
    this.initConstructor();
  }

  ngOnInit() {
    this.initApps();
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.mode = 'edit';
      this.name = "Editar Categoria";
      this.appId = this.route.snapshot.paramMap.get('appId');
      this.getCategory(id);
      this.categoryForm.controls.appNameInputControl.disable();
    }
    this.categoryForm.controls.appNameInputControl.valueChanges
      .pipe(startWith(null))
      .subscribe((val: string) => {
        if (val) {
          const nameParts = val.split('|').map((part: string) => part.trim());
          let app =
            nameParts.length > 1
              ? this.appsIdMap.get(nameParts[1])
              : this.appsIdMap.get(nameParts[0].toLowerCase());
          app = app ? app : this.appsNameMap.get(nameParts[0].toLowerCase());
          console.log(app);


        }

        this.filteredAppsOptions = val
          ? this.filtersAutoComplete(val, 'applicationsOptions')
          : this.applicationsOptions.slice();
        /*  if (val) {
           const value = val.split(' | ').length > 1 ? val.split(' | ')[1] : val;
           this.appId = value;
           const findObs = this.programsUtilService.getPrograms(value);
           findObs.then((programsList: ProgramFull[]) => {
             console.log('programsList', programsList)
             if (!programsList) {
               return;
             }
             const ids = programsList.map((program) => program.id);
             this.programsOptions.push(...ids);
             this.recognitionForm.controls.programInputControl.setValue('', { emitEvent: true });
   
             this.loadingAppProfileDefinition = true;
             this.appsApi
               .retrieveAppProfileFields(value)
               .subscribe(
                 (profile) => {
                   this.appProfileDefinition = profile;
                   this.decodeAppProfileDefinition(profile);
                   this.loadingAppProfileDefinition = false;
                 },
                 (error) => {
                   this.loadingAppProfileDefinition = false;
                   console.error(error);
                 }
               );
           });
         } */
      });

  }
  getCategory(id) {
    console.log(id);
    this.categoriesService.getCategory(id, this.appId).subscribe((data:category_indicator)=>{
      console.log(data);
      this.data = data;
      this.mode = 'edit';
      this.categoryForm.controls.name.setValue(data.name);
      this.categoryForm.controls.image.setValue(data.icon.url);
      this.categoryForm.controls.appNameInputControl.setValue(data.app_id);
      this.categoryForm.controls.appNameInputEditor.setValue(data.description);
      this.getSection(data.app_id);
    });
  }

  getSection(appId?) {
    if(appId){
      this.loading = true;
      this.sectionsService.getSections(appId).subscribe((data)=>{
      this.dataListSection = data.data
        if (this.data) {
            const lastSeletedfind = this.dataListSection.find((element)=>{
            return element.id == this.data.section_id
          });
          this.categoryForm.controls["sectionInputControl"].setValue(lastSeletedfind);
          this.categoryForm.controls["sectionInputControl"].updateValueAndValidity();
        }
    },(error: any) =>{
       console.error('error get sections = ', error);
    })}
  }
  

  async initApps() {
    try {
      const apps = await this.appsUtilService.getApps();
      this.setApps(apps);
    } catch (error) {
      console.error('Error getting apps', error);
    }
  }

  setApps(apps: AppOutput[]) {
    this.objectsAppsData = apps;
    this.applicationsOptions = apps.map(
      (app: AppOutput) => `${app.name} | ${app.id}`
    );
    this.appsNameMap = apps.reduce(
      (appsMap: Map<string, AppOutput>, app: AppOutput) => {
        appsMap.set(app.name.toLowerCase(), app);
        return appsMap;
      },
      new Map()
    );
    this.appsIdMap = apps.reduce(
      (appsMap: Map<string, AppOutput>, app: AppOutput) => {
        appsMap.set(app.id, app);
        return appsMap;
      },
      new Map()
    );
  }
  filtersAutoComplete(value: string, type: string) {
    return this[type].filter(
      (option: string) =>
        option && option.toLowerCase().indexOf(value.toLowerCase()) >= 0
    );
  }

  async onFileSelected() {
    const inputNode: any = document.querySelector('#file')['files'];
    const base64 = await this.convertBase64(inputNode[0]);
    this.categoryForm.controls.image.setValue(base64);
    if(this.mode == 'edit'){
      this.editedIcon = true
    }
  }

  toDataURL(url) {
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          resolve(reader.result);
        }
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    })
  }

  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = (e: any) => {
        var img = new Image();
        img.src = e.target.result;
        img.onload = function () { // image is loaded; sizes are available
          if (img.width != 1080 || img.height != 630) {
            Swal.fire({
              icon: 'error',
              title: 'La imagen no puede tener unas dimensiones mayor a 1080px x 630px',
              confirmButtonColor: '#ff9800',
            })
            return;
          }else {
            resolve(fileReader.result);
          }
        };
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  initConstructor() {
    this.categoryForm = this.formBuilder.group({
      name: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
        ]),
      ],
      image: ["", Validators.required],
      appNameInputControl: [
        "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(140)
        ]),
      ],
      sectionInputControl: [
        "",
        Validators.compose([
          Validators.required,
        ]),
      ],
      appNameInputEditor: [""]
    });
  }

  click(filteredAppsOptions) {
    //console.log(filteredAppsOptions[0]);
    var filtrado = filteredAppsOptions[0]
    var partes = filtrado.split("|");
    var partFiltrada = partes[1]
    this.getSection(partFiltrada.trim());
  }

  OKClicked() {
    if(!this.isSending){
      this.isSending = true;
      let val =  this.categoryForm.get('appNameInputControl').value
      const value = val.split(' | ').length > 1 ? val.split(' | ')[1] : val;
             this.appId = value;
      const data: category_indicator = {
        app_id: value,
        name: this.categoryForm.controls.name.value,
        icon: this.categoryForm.controls.image.value ,
        description:this.categoryForm.controls.appNameInputEditor.value, 
        section_id:this.categoryForm.controls.sectionInputControl.value.id, 
        section_name: this.categoryForm.controls.sectionInputControl.value.name, 
      }
      if (this.mode == 'create') {
        this.categoriesService.postCategory(data).subscribe((res: any) => {
          if(res.created){
            Swal.fire({
              icon: 'success',
              title: 'Categoria Agregada correctamente',
              confirmButtonColor: '#ff9800',
            }).then(() => {
              this.isSending = false;
              this.router.navigateByUrl('/pages/categories/list');
            });
          }
        })
      } else {
        data.id = this.data.id
        data.icon = this.data.icon
        if(this.editedIcon){
          data.new_icon = this.categoryForm.controls.image.value
        }
        this.categoriesService.putCategory(data).subscribe((res: any) => {
          if(res.updated){
            Swal.fire({
              icon: 'success',
              title: 'Categoria Editada correctamente',
              confirmButtonColor: '#ff9800',
            }).then(() => {
              this.isSending = false;
              this.router.navigateByUrl('/pages/categories/list');
            });
          }
        })
      }
    }
  }
  cancelClicked() {
    this.router.navigateByUrl('/pages/categories/list');
  }
}
