/// <reference types="@types/googlemaps" />
import {
  Component,
  OnInit,
  NgZone,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { PointSale, AllieRoot } from "../../../models/models";
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { SalesPointsService } from "../../../services/sales-points.service";
import { AlliesService } from "../../../services/allies.service";
import Swal from "sweetalert2";
import { BrandService } from "../../../services/brand.service";
// import { MapsAPILoader } from "@agm/core";
@Component({
  selector: "app-sales-points-edit",
  templateUrl: "./sales-points-edit.component.html",
  styleUrls: ["./sales-points-edit.component.scss"],
})
export class SalesPointsEditComponent implements OnInit {
  loading = false;
  typePage: string;
  pointSale: PointSale;
  messageAlert: string;
  pointSaleForm: UntypedFormGroup;
  allies: any = [];
  showHints: String;
  lat;
  lng;
  loadMap = false;
  zoom = 9;
  geoCoder;
  address;
  pointSaleName;
  @ViewChild("search", { static: false }) public searchElementRef: ElementRef;

  brands = [];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private salepoints: SalesPointsService,
    private router: Router,
    private alliesService: AlliesService,
    private brandService: BrandService,
    // private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone
  ) {
    this.pointSaleForm = this.formBuilder.group({
      code: ["", [Validators.required]],
      commerceId: ["", [Validators.required]],
      country: ["CO", Validators.required],
      name: ["", [Validators.required]],
      id: ["", [Validators.required]],
      brandId: ["", Validators.required],
      is_ecom: [false, Validators.required],
      url: [""],
      has_coupons: [false],
      has_payment: [false],
      latitude: [""],
      longitude: [""],
      geoCodeId: [""],
      address: [""],
    });
  }

  async ngOnInit() {
    this.initApiMaps();
    this.loading = true;
    this.getAllies();
    this.getBrands();

    await this.checkTypePage();
  }

  checkTypePage() {
    return new Promise<void>((resolve) => {
      this.route.params.subscribe(async (params: Params) => {
        if (params.id) {
          await this.loadPointSale(params.id);
          this.loading = false;
          this.typePage = "edit";
          return resolve();
        }
        this.loading = false;
        this.loadMap = true;
        this.typePage = "create";
        return resolve();
      });
    });
  }

  loadPointSale(pointSaleId) {
    return new Promise((resolve) => {
      this.salepoints
        .getPointSale(pointSaleId)
        .subscribe((pointSale: PointSale) => {
          this.pointSaleForm.patchValue(pointSale);
          this.pointSaleName = pointSale.name;
          this.setAdress();
          this.loadMap = true;
        });
    });
  }

  getAllies() {
    this.alliesService.getAllies().subscribe((res: AllieRoot) => {
      const { data } = res;
      data.map(({ id, name }) => {
        this.allies.push({
          id,
          name,
        });
      });
    });
  }

  getBrands() {
    this.loading = true;
    this.brandService.getBrands().subscribe((res) => {
      this.brands.push(...res.data);
      this.loading = false;
    });
  }
  goHome() {
    this.router.navigate(["/pages/sales-point/list"]);
  }
  save() {
    const pointSale = this.pointSaleForm.value;
    const methodApi =
      this.typePage === "create" ? "postPointSale" : "putPointSale";
    this.salepoints[methodApi]({ ...pointSale }).subscribe(
      (res: any) => {
        const title =
          this.typePage === "create"
            ? res.id + " se creo correctamente"
            : "Operacion realizada correctamente";
        if (res.id || res.updated) {
          return Swal.fire({
            icon: "success",
            title,
            confirmButtonColor: "#ff9800",
          }).then(() => this.goHome());
        }
        return Swal.fire({
          icon: "error",
          title: "Parece que hay un error",
          confirmButtonColor: "#DD6B55",
        }).then(() => Swal.close());
      },
      (err) => {
        if (err.error.code == "COMMERCE_BRAND_MISMATCH") {
          return Swal.fire({
            icon: "error",
            title: "La marca seleccionada no corresponde al aliado ",
            confirmButtonColor: "#DD6B55",
          }).then(() => Swal.close());
        }
        return Swal.fire({
          icon: "error",
          title: "Parece que hay un error",
          confirmButtonColor: "#DD6B55",
        }).then(() => Swal.close());
      }
    );
  }

  generateId(event: string) {
    if (this.typePage === "create") {
      let newId: string = event.replace(/[^a-zA-Z0-9/]+/g, "_");
      this.pointSaleForm.controls.id.setValue(newId.toLowerCase());
    }
  }
  initApiMaps() {
    // load Places Autocomplete
    // this.mapsAPILoader.load().then(() => {
    //   this.geoCoder = new google.maps.Geocoder();
    //   if (this.typePage == "create") {
    //     this.setCurrentLocation();
    //   }

    //   const element = this.searchElementRef.nativeElement;
    //   let autocomplete = new google.maps.places.Autocomplete(element);
    //   autocomplete.addListener("place_changed", () => {
    //     this.ngZone.run(() => {
    //       let place: google.maps.places.PlaceResult = autocomplete.getPlace();
    //       if (place.geometry === undefined || place.geometry === null) {
    //         return;
    //       }
    //       this.pointSaleForm.controls.latitude.setValue(
    //         place.geometry.location.lat()
    //       );
    //       this.pointSaleForm.controls.longitude.setValue(
    //         place.geometry.location.lng()
    //       );
    //       this.pointSaleForm.controls.geoCodeId.setValue(place.place_id);
    //       this.zoom = 12;
    //     });
    //   });
    // });
  }
  private setCurrentLocation() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.pointSaleForm.controls.latitude.setValue(position.coords.latitude);
      this.pointSaleForm.controls.longitude.setValue(position.coords.longitude);
      this.zoom = 8;
      this.setAdress();
    });
  }
  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 12;
            this.pointSaleForm.controls.address.setValue(
              results[0].formatted_address
            );
          }
        }
      }
    );
  }
  setAdress() {
    const { latitude, longitude } = this.pointSaleForm.value;
    this.getAddress(latitude, longitude);
  }
  dragMarket(event: google.maps.MouseEvent) {
    this.pointSaleForm.controls.latitude.setValue(event.latLng.lat());
    this.pointSaleForm.controls.longitude.setValue(event.latLng.lng());
    this.setAdress();
  }
}
